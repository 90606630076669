import BookingExtentionModal from "../../../../components/Modals/BookingExtentionModal";
import React, { useEffect, useState } from "react";
import viewIcn from "../../../../assets/img/icons/customIcons/view.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Tables from "views/examples/Tables";
// import { useSelector, useDispatch } from "react-redux";
import SOWModal from "components/Modals/SOWModal";
import { axiosAccountInstance } from "api/Request";
import { getApprovedRequestsInfo } from "redux/actions/admin/ApprovedRequestAction";
import toast from "../../../../components/Toastify";
import { fetchUserData } from "helpers/storageData";
// import { setCompanyBookingExtentionInfo } from "redux/actions/admin/BookingExtentionRequestAction";
import deleteIcn from "../../../../assets/img/icons/customIcons/delete.svg";
import { setCompanyBookingExtentionInfo } from "redux/actions/admin/BookingExtentionRequestAction";
function AllExtentionCard({ loader }) {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [bookingData, setBookingData] = useState({});
  const [bookingModal, setBookingModal] = useState(false);
  const [sowModal, setSOWModal] = useState(false);
  const [sowData, setSOWData] = useState({});
  const [deleteId, setDeleteId] = useState(null);
  const [rejectRequestReason, setRejectRequestReason] = useState("");
  const [sowErrors, setSOWErrors] = useState(null);
  const [errors, setErrors] = useState(null);
  const [signedSOWdoc, setSignedSOWdoc] = useState();
  const [pendingData, setPendingData] = useState([]);
  const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(true);
  const [employeeID, setEmployeeID] = useState("");
  const [sowHistory, setSOWHistory] = useState([]);
  const [isExt,setIsExt]=useState(true)
  const [isClickDisabled, setClickDisabled] = useState(false);
  const socketInstance = useSelector((state) => state.socketReducer.result);
  const dispatch = useDispatch();
  const responseData = useSelector(
    (state) => state.AllBookingExtentionRequestReducer.result
  );

  useEffect(() => {
    setData(responseData?.booking || []);
    // let pendingId=responseData?.booking?.filter((obj) => obj.extentionStatus.includes("pending"))
    // console.log("data.....ojo",pendingId?.map((item)=>(
    //     item?._id)))
    // setPendingData(
    //   pendingId?.map((item)=>(
    //   item?._id
    // ))
    // )
    
    
  }, [responseData]);
  const close = () => {
    setBookingModal(false);
    setClickDisabled(false)
    setModal(false)
    setSOWModal(false);
    setSOWData({});
  };
  const deleteHandler = (bookingId) => {
    setDeleteId(bookingId);
    setModal(!modal);
  };

  const viewHandler = (dataObj) => {
    setBookingData(dataObj);
    setBookingModal(true);
  };
  const sowRequestHandler = (id, data) => {
    console.log("sow data  ", data);
    setSOWModal(!sowModal);
    setSOWData(data);
    setEmployeeID(id);
    setSOWHistory(data?.activity?.filter((obj) => obj.action.includes("SOW")));
  };
  const handleOnSOWRejectChange = (e) => {
    setRejectRequestReason(e.target.value);
    setSOWErrors({ ...sowErrors, rejectRequestReason: null });
  };
  const imageSelect = (image) => {
    // if (image) {
    //   setIsRejectButtonDisabled(false);
    // }

    const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB maximum size limit
    if (image.size > MAX_IMAGE_SIZE) {
      setErrors({
        ...errors,
        sowDocument: `File size exceeds the maximum limit of ${
          MAX_IMAGE_SIZE / (1024 * 1024)
        }MB`,
      });
      setSignedSOWdoc(null);
      setIsApproveButtonDisabled(true);
    } else {
      setErrors({ ...errors, sowDocument: null });

      setSignedSOWdoc(image);
      setIsApproveButtonDisabled(false);
    }
    // setErrors({ ...errors, sowDocument: null });
    // setApproveFormData({
    //   ...approveFormData,
    //   sowDocument: image,
    // });
    // setIsApproveButtonDisabled(false);
  };
  const onApproveSOW = async () => {
    setIsApproveButtonDisabled(true);

    const data = new FormData();
    data.append("_id", employeeID);
    data.append("sow_status", "approved");
    data.append("sowSignedDocument", signedSOWdoc);

    submitRequest(data, "approved");
  };
  const onReject = () => {
    // setIsRejectButtonDisabled(true);
    const finalData = {
      _id: employeeID,
      sow_status: "rejected",
      rejectReason: rejectRequestReason,
    };
    submitRequest(finalData, "rejected");
  };
  const validationSOW = () => {
    const errorsFound = {};

    if (!rejectRequestReason || rejectRequestReason === "") {
      errorsFound["rejectRequestReason"] = "Reject Request Reason is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setSOWErrors(errorsFound);
      return false;
    }
    return true;
  };
  const submitRequest = (data, type) => {
    if (data.sow_status === "rejected") {
      const isValid = validationSOW(data);
      setCompanyApproval(isValid, data, type);
    } else {
      setCompanyApproval(true, data, type);
    }
  };


  const submit = () => {
    if (isClickDisabled) {
      console.log("if...");
      return;
    }
    // Disable multiple request click
    setClickDisabled(true);
    axiosAccountInstance
      .delete(`/booking/extention/delete/${deleteId}`)
      .then((res) => {
        if (res.data.statusCode === 200) {
          toast.success(res.data.body.message);
          socketInstance.emit("message", {
            type: "UPDATE_DELETE_BOOKING_EXTENTION",
            key: deleteId,
            extraInfo: fetchUserData().companyName,
          });

          dispatch(setCompanyBookingExtentionInfo("1", "1", "", null));         
         console.log("deleteddd")
        }
        
      })
      .catch((err) => {
        toast.error(err.response.data.body.message);
      });
      
      setModal(false);
  };

  const setCompanyApproval = (isValid = true, data, requesttype) => {
    if (isValid) {
      axiosAccountInstance
        .post("/booking/final/sow_request/approve", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            if (requesttype === "approved") {
              toast.success(res.data.body.message);
            } else {
              socketInstance.emit("message", {
                type: "UPDATE_FINAL_SOW_REQUEST",
                subType: "UPDATE_FINAL_SOW_REJECTED_REQUEST_LIST",
                key: sowData?.bookingId,
                extraInfo: fetchUserData()?.companyName,
              });
              toast.success(res.data.body.message);
            }

            dispatch(getApprovedRequestsInfo("1", "1", "approved"));
            setSOWModal(false);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setRejectRequestReason("");
    }
  };
  const colums = [
    { columnName: "Employee ID", columnTag: "empId", type: "data" },

    // { columnName: "Company", columnTag: "parentCompany", type: "data" },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Date Range", columnTag: "duration", type: "objectData" },
    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },
    // { columnName: "Currency", columnTag: "currencyCode", type: "data" },
    {
      columnName: "SOW",
      columnTag: "sow_final_status",
      type: "data",
      actionSOWApprove: sowRequestHandler,
    },
    { columnName: "Status", columnTag: "extentionStatus", type: "data" },
    { columnName: "", columnTag: "", type: " " },
  ];
  const actionbuttons = [
    {
      columnName: "",
      btnTitle: "View",
      btnTooltip: "View",
      type: "actionButton",
      btn: "ViewBooking",
      btnType: "primary",
      btnIcon: viewIcn,
      actionFunction: viewHandler,
    },
    
    {
      columnName: "",
      btnTitle: "Delete",
      btnTooltip: "Delete",
      type: "actionButton",
      btn: "BookingExtentionDelete",
      icon: <i className="fa fa-trash" aria-hidden="true"></i>,
      tooltip: "Delete",
      btnType: "danger",
      btnIcon: deleteIcn,
      actionFunction: deleteHandler,
    }
  ];
console.log("pendi  ../.",pendingData)

const toggle = () => {
  setClickDisabled(false);
  close();
};
  return (
    <div>
      {data && (
        <Tables
          coloums={colums}
          data={responseData?.booking}
          actionButtons={actionbuttons}
          loader={loader}
          isExt={isExt}
        />
      )}
      <Modal toggle={toggle} size="sm" centered isOpen={modal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              {" "}
              Delete Request
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <label className="font-weight-normal">
            Do you want to delete booking?
          </label>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={submit} color="danger">
            Delete
          </Button>{" "}
          {/* <Button size="sm" onClick={close}>
                  Cancel
                </Button> */}
        </ModalFooter>
      </Modal>
      <SOWModal
        isOpen={sowModal}
        closeModal={close}
        sowData={sowData}
        onApproveSOW={onApproveSOW}
        onReject={onReject}
        sowHistory={sowHistory}
        handleOnSOWRejectChange={handleOnSOWRejectChange}
        rejectRequestReason={rejectRequestReason}
        isApproveButtonDisabled={isApproveButtonDisabled}
        sowErrors={sowErrors}
        imageSelect={imageSelect}
      />
      <BookingExtentionModal
        modal={bookingModal}
        close={close}
        data={bookingData}
      />
    </div>
  );
}

export default AllExtentionCard;
