import React, { useEffect, useState } from "react";
import AddBasicDetails from "./AddBasicDetails";
import AddSkills from "./AddSkills";
import { Button, Container, Row, Col, CardBody, Card } from "reactstrap";
import AddProjects from "./AddProjects";
import { axiosAccountInstance } from "api/Request";
import history from "helpers/History";
import toast from "../../../../../components/Toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import DashboardHeader from "components/Headers/DashboardHeader";
import { useLocation } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "helpers/storageData";

function Container1({ match, props }) {
  const location = useLocation();
  const socketInstance = useSelector((state) => state.socketReducer.result);
  const dispatch = useDispatch();
  const employeeId = match.params.employeeId;
  const submitLoader = useSelector(
    (state) => state.submitRequestReducer.loader
  );

  const [isFalse, setIsFalse] = useState(false);

  const [formData, setFormData] = useState({
    companyName: null,
    empId: `EMP_${new Date().getTime()}${Math.floor(Math.random() * 100)}`,
    // name: null,
    firstName: null,
    lastName: null,
    profilePhoto: "",
    designation: null,
    currencyCode: null,
    hourlyPayment: null,
    experienceNumberOfYears: null,
    experienceNumberOfMonths: null,
    skillSets: [],
    projects: [],
    email: null,
    mobile: null,
    resume: null,
    _id: "",
  });
  const [isEdit, setEdit] = useState(false);
  const [bulkData, setBulkData] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);
  // const [isImport, setImport] = useState(false)

  // const [resume, setResume] = useState(null);

  const [isInvalidPhone, setIsInvalidPhone] = useState(null);
  // const getSkill = (skills) => {
  //   const skillData = skills.map((skill) => {
  //     return {
  //       domain: skill.domain,
  //       numberOfYears: `${skill.numberOfYears}.${skill.numberOfMonths}`,
  //       numberOfMonths: skill.numberOfMonths,
  //       rating: skill.rating,
  //       _id:skill._id
  //     };
  //   });

  //   return skillData;
  // };

  const getValue = (year, month) => {
    return year + "." + month;
  };
  // const getFN = (name) => {
  //   let fullName = name?.split(" ");
  //   return fullName[0];
  // };
  // const getLN = (name) => {
  //   let fullName = name?.split(" ");
  //   return fullName[1];
  // };
  // storing the data in case of edit-page
  useEffect(() => {
    if (employeeId) {
      setEdit(true);
      setSaveLoader(true);
      axiosAccountInstance.get(`/employee/${employeeId}`).then((res) => {
        const requestData = res.data.body.data;

        setFormData({
          // companyName: null,
          // name:requestData?.name,
          // firstName: getFN(requestData?.name),
          // lastName: getLN(requestData?.name),
          firstName: requestData?.firstName,
          lastName: requestData?.lastName,
          empId: requestData?.empId,
          // profilePhoto: '',
          designation: requestData?.designation,
          currencyCode: requestData?.currencyCode,
          hourlyPayment: requestData?.hourlyPayment,
          experienceNumberOfYears: getValue(
            requestData?.experience?.numberOfYears,
            requestData?.experience?.numberOfMonths
          ),
          experienceNumberOfMonths: requestData?.experience?.numberOfMonths,

          skillSets: [...requestData?.skillSets],
          // skillSets:getSkill([...requestData?.skillSets]),
          projects: [...requestData?.projects],
          email: requestData?.email,
          mobile: requestData?.mobile,
          resume: requestData.resume,
          // resume:null,
          // resumeFile:requestData?.resume,//for getting  resumename in edit
          _id: requestData?._id,
        });
        setSaveLoader(false);
      });
    } else {
      const employeeIdVal = `EMP_${new Date().getTime()}${Math.floor(
        Math.random() * 100
      )}`;

      setFormData({ ...formData, empId: employeeIdVal });
      console.log(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId]);

  // storing errors
  const [errors, setErrors] = useState({
    projectError: [],
    skillSetsError: [],
  });
  // const [projErrors, setprojErrors] = useState(null);

  // const [inputList, setInputList] = useState([]);
  // const [showResumeForm, setShowResumeForm] = useState(false);

  const phoneNumberInputChnage = (e) => {
    setFormData({
      ...formData,
      mobile: e,
    });
    setErrors({ ...errors, mobile: null });
    mobileNumbervalidation(e);
  };

  // const imageSelect = (image) => {
  //   setFormData({
  //     ...formData,
  //     // profilePhoto: image,

  //   });
  // }; // end of imageSelect

  const bulkUpload = (csvFile) => {
    // setImport(true)
    setBulkData(csvFile);
  };

  const handleOnChange = (e) => {
    if (e.target.name === "profilePhoto") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      setErrors({ ...errors, [e.target.name]: null });
    }
  };

  const handleResumeUpload = (file) => {
    const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB maximum size limit
    if (file.size > MAX_FILE_SIZE) {
      setErrors({
        ...errors,
        resume: `Selected file size exceeds the maximum limit of ${
          MAX_FILE_SIZE / (1024 * 1024)
        }MB`,
      });
      setFormData({ ...formData, resume: file });
    } else {
      setErrors({ ...errors, resume: null });
      setFormData({ ...formData, resume: file });
    }
    // setFormData({ ...formData, resume: file });
  };

  const skillsAddHandler = () => {
    setFormData({
      ...formData,
      skillSets: [
        ...formData.skillSets,
        {
          domain: "",
          experience: { numberOfYears: "", numberOfMonths: "" },
          rating: "",
        },
      ],
    });
    if (!errors.skillSetsError) {
      setErrors({
        ...errors,
        skillSets: null,
        skillSetsError: [
          {
            domain: "",
            experience: { numberOfYears: "", numberOfMonths: "" },
            rating: "",
          },
        ],
      });
    } else {
      setErrors({
        ...errors,
        skillSets: null,
        skillSetsError: [
          ...errors.skillSetsError,
          {
            domain: "",
            experience: { numberOfYears: "", numberOfMonths: "" },
            rating: "",
          },
        ],
      });
    }
  };

  const projectsAddHandler = async () => {
    setFormData({
      ...formData,
      projects: [
        ...formData.projects,
        { name: "", role: "", description: "", duration: "" },
      ],
    });
    if (!errors.projectError) {
      setErrors({
        ...errors,
        projects: null,
        projectError: [{ name: "", role: "", description: "", duration: "" }],
      });
    } else {
      setErrors({
        ...errors,
        projects: null,
        projectError: [
          ...errors.projectError,
          { name: "", role: "", description: "", duration: "" },
        ],
      });
    }
  };

  const skillSetHandler = (e, keyIndex, name) => {
    if (name === "rating") {
      formData.skillSets[keyIndex][name] = e;
      setFormData({
        ...formData,
      });
    } else {
      formData.skillSets[keyIndex][e.target.name] = e.target.value;
      setFormData({
        ...formData,
      });
    }
    // console.log(formData);

    let newArr = errors && errors.skillSetsError && [...errors?.skillSetsError];
    if (newArr) {
      newArr[keyIndex][name] = null;
      setErrors({
        ...errors,
        skillSetsError: newArr,
      });
    }
  };

  const projectsHandler = (e, keyIndex, name) => {
    formData.projects[keyIndex][e.target.name] = e.target.value;
    setFormData({
      ...formData,
    });

    let newArr = errors && errors.projectError && [...errors?.projectError];
    if (newArr) {
      newArr[keyIndex][name] = null;
      setErrors({
        ...errors,
        projectError: newArr,
      });
    }
  };

  const mobileNumbervalidation = (data) => {
    const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    if (!data) {
      setErrors({ ...errors, mobile: "" });
      return true; // No mobile number provided, so validation passes
    } else if (data && (regex.test(data) === false || data.length < 6)) {
      setErrors({
        ...errors,
        mobile: "Mobile number is invalid",
      });
      return false;
    } else {
      setErrors({ ...errors, mobile: "" });
      return true;
    }
  };

  const handleMobileValidation = (values, countrys) => {
    console.log(countrys);
    if (values.length == countrys.countryCode.length || values.length == 0) {
      console.log("isedit.....");
      setIsInvalidPhone(false);
      return true;
    } else if (countrys.format.match(/\./g).length === values.length) {
      setIsInvalidPhone(false);
      return true;
    } else {
      console.log("invalid");
      setIsInvalidPhone(true);
      return false;
    }
  };

  const validatingProjects = () => {
    const projErr = [];

    if (formData.projects.length > 0) {
      formData.projects.forEach((item, index) => {
        let err = {};
        if (!item.name) err["projectName"] = "Name is required";
        if (!item.role) err["proectRole"] = "Role is required";
        if (!item.description)
          err["projectDescription"] = "Description is required";
        if (!item.duration)
          err["projectDuration"] = "Please enter proper duration";

        if (Object.keys(err).length !== 0) {
          projErr[index] = err;
        }
      });

      return projErr;
    }
  };

  const validatingSkillSets = () => {
    const projErr = [];

    if (formData.skillSets.length > 0) {
      formData.skillSets.forEach((item, index) => {
        let err = {};
        if (!item.domain) err["domain"] = "Skill is required";
        if (!item.numberOfYears)
          err["numberOfYears"] = "Number of years is required";
        if (!item.rating) err["rating"] = "Rating is required";
        if (item.rating < 1 || item.rating > 5) {
          err["rating"] = "Rate out of 5";
        }
        if (Object.keys(err).length !== 0) {
          projErr[index] = err;
        }
      });

      return projErr;
    }
  };

  const validation = async () => {
    const {
      // name,
      firstName,
      lastName,
      designation,
      currencyCode,
      hourlyPayment,
      experienceNumberOfYears,
      // experienceNumberOfMonths,

      skillSets,
      projects,
      empId,
      email,
      mobile,
    } = formData;

    const errorsFound = {};

    if (errors.resume)
      errorsFound["resume"] =
        "Selected file size exceeds the maximum limit of 2MB ";
    // console.log(experienceNumberOfMonths);

    // if(!companyName)  errorsFound["companyName"]="Oops! please add company name"
    // if (!name) errorsFound["name"] = "Name is required";
    if (!firstName) errorsFound["firstName"] = "First Name is required";
    const nameRegex = /^[A-Za-z ]{1,50}$/;
    if (!nameRegex.test(firstName)) {
      errorsFound["firstName"] =
        "Allows only alphabetical characters (1-50 characters).";
    }
    if (!nameRegex.test(lastName)) {
      errorsFound["lastName"] =
        "Allows only alphabetical characters (1-50 characters).";
    }
    if (!lastName) errorsFound["lastName"] = "Last Name is required";

    if (!empId) errorsFound["empId"] = "Employee Id is required";

    if (!designation) errorsFound["designation"] = "Designation is required";
    if (!currencyCode)
      errorsFound["currencyCode"] = "Currency code is required";
    if (!hourlyPayment) {
      errorsFound["hourlyPayment"] = "Payment is required";
    } else {
      const paymentRegex = /^\d{1,4}$/;
      if (!paymentRegex.test(hourlyPayment)) {
        errorsFound["hourlyPayment"] =
          "Hourly payment must be a whole number (no decimals) with up to 4 digits.";
      }
    }
    if (!experienceNumberOfYears)
      errorsFound["experienceNumberOfYears"] =
        "Total number of experience in years is required";

    // if (experienceNumberOfYears % 1 !== 0) {
    //   errorsFound["experienceNumberOfYears"] =
    //     "Total number of experience in years cannot be in decimal";
    // }

    if (experienceNumberOfYears > 100) {
      errorsFound["experienceNumberOfYears"] =
        "Total number of experience in years cannot exceed 100";
    }

    // if (
    //   experienceNumberOfMonths === null ||
    //   experienceNumberOfMonths === undefined
    // )
    //   errorsFound["experienceNumberOfMonths"] =
    //     "Total number of experience in months is required";
    // if (experienceNumberOfMonths < 0 || experienceNumberOfMonths > 12) {
    //   errorsFound["experienceNumberOfMonths"] =
    //     "Total number of experience in months should be between 0 and 12";
    // }

    // if (experienceNumberOfMonths % 1 !== 0) {
    //   errorsFound["experienceNumberOfMonths"] =
    //     "Total number of experience in months cannot be in decimal";
    // }
    if (experienceNumberOfYears === "") {
      errorsFound["experienceNumberOfYears"] =
        "Invalid total number of experience in years ";
    }

    // if (experienceNumberOfMonths === "") {
    //   errorsFound["experienceNumberOfMonths"] =
    //     "Invalid total number of experience in months ";
    // }

    // if (!mobile) errorsFound["mobile"] = "Mobile number is required";
    if (!email) errorsFound["email"] = "Email is required";
    // if (!mobile || mobile === "")
    //   errorsFound["mobile"] = "Mobile number is required";
    if (mobile && (errors.mobile || isInvalidPhone))
      errorsFound["mobile"] = "Mobile number is invalid";
    // if (!domain) errorsFound["domain"] = "Domain is required";
    // if (!numberOfYears) errorsFound["numberOfYears"] = "Number of years in domain is required";
    // if (!numberOfMonths) errorsFound["numberOfMonths"] = "Number of months in domain is required";
    // if (!role) errorsFound["role"] = "Role in project is required";
    // if (!duration) errorsFound["duration"] = "Duration of project is required";
    // if (!description) errorsFound["description"] = "Description of project is required";
    // if (!projectName) errorsFound["projectName"] = "Project name in project is required";
    if (skillSets.length === 0) errorsFound["skillSets"] = "Skills is required";
    if (projects.length === 0) errorsFound["projects"] = "Projects is required";

    const projectErrors = await validatingProjects();
    const skillSetsErrors = await validatingSkillSets();

    if (projectErrors && projectErrors.length > 0)
      errorsFound["projectError"] = projectErrors;

    if (skillSetsErrors && skillSetsErrors.length > 0)
      errorsFound["skillSetsError"] = skillSetsErrors;

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };

  const submitBulkUpload = async (e) => {
    setSaveLoader(true);
    e.preventDefault();
    const data = new FormData();
    data.append("bulkUploadCSV", bulkData);

    axiosAccountInstance
      .post("/bulkemployeeupload", data)
      .then((res) => {
        toast.success(res.data.body.message);
        history.push("/admin/list-employees");
        setSaveLoader(false);
      })
      .catch((err) => {
        setIsFalse(true);
        toast.error(err.response.data.body.message);
        setSaveLoader(false);
      });
  };

  const saveHandler = async (e) => {
    // console.log("formdatata  ",formData)
    const isValid = await validation();

    e.preventDefault();

    if (isValid) {
      setSaveLoader(true);
      dispatch({
        type: "REQUEST_BEGINS",
        loader: true,
        result: null,
      });
      const data = new FormData();
      data.append("firstName", formData?.firstName);
      data.append("lastName", formData?.lastName);

      data.append("empId", formData.empId);
      data.append("designation", formData.designation);
      data.append("currencyCode", formData.currencyCode);
      data.append("hourlyPayment", formData.hourlyPayment);
      data.append("experienceNumberOfYears", formData.experienceNumberOfYears);
      data.append(
        "experienceNumberOfMonths",
        formData.experienceNumberOfMonths
      );
      data.append("skillSets", JSON.stringify(formData.skillSets));
      data.append("projects", JSON.stringify(formData.projects));
      data.append("email", formData.email);
      data.append("mobile", formData.mobile);
      if (formData?.resume) data.append("resume", formData.resume);

      data.append("profilePhoto", formData.profilePhoto);

      if (employeeId) data.append("_id", formData._id);

      if (employeeId) {
        axiosAccountInstance
          .post("/employeeupdate", data)
          .then((res) => {
            toast.success(res.data.body.message);
            if (res.data.body.data?.resume) {
              socketInstance.emit("message", {
                type: "RESUME UPLOADED",
                key: res.data.body.data?.empId,
                user: fetchUserData()?.companyId,
                extraInfo: fetchUserData()?.companyName,
              });
            }

            dispatch({
              type: "REQUEST_SUCCESS",
              loader: false,
              result: res,
            });
            history.push("/admin/list-employees");
            setSaveLoader(false);
          })
          .catch((err) => {
            toast.error(err.response.data.body.message);
            dispatch({
              type: "REQUEST_FAILURE",
              loader: false,
              result: null,
            });
            setSaveLoader(false);
          });
      } else {
        axiosAccountInstance
          .post("/employeecreate", data)

          .then((res) => {
            if (res.status === 201) {
              toast.success(res.data.body.message);
              dispatch({
                type: "REQUEST_SUCCESS",
                loader: false,
                result: res,
              });
            }
            history.push("/admin/list-employees");
            setSaveLoader(false);
          })
          .catch((err) => {
            console.log(err);

            toast.error(err.response.data.body.message);
            dispatch({
              type: "REQUEST_FAILURE",
              loader: false,
              result: null,
            });
            setSaveLoader(false);
          });
      }
    } else {
      console.log("errors");
    }
  };

  // for removing resume file in drag and drop
  const close = () => {
    setFormData({ ...formData, resume: null });
    setErrors({ ...errors, resume: null });
  };

  const deleteSkillSet = (e, i) => {
    e.preventDefault();
    let skillData = [...formData.skillSets];
    skillData.splice(i, 1);

    setFormData({
      ...formData,
      skillSets: skillData,
    });
  };

  const deleteProject = (e, i) => {
    e.preventDefault();
    let projectsData = [...formData.projects];
    projectsData.splice(i, 1);

    setFormData({
      ...formData,
      projects: projectsData,
    });
  };
  //for initial rendering skill, project
  useEffect(() => {
    setFormData({
      ...formData,
      skillSets: [
        {
          domain: "",
          experience: { numberOfYears: "", numberOfMonths: "" },
          rating: "",
        },
      ],
      projects: [{ name: "", role: "", description: "", duration: "" }],
    });

    setErrors({
      ...errors,
      skillSetsError: [
        {
          domain: "",
          experience: { numberOfYears: "", numberOfMonths: "" },
          rating: "",
        },
      ],
      projectError: [{ name: "", role: "", description: "", duration: "" }],
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {/* <ToastContainer autoClose={5000} /> */}
      <DashboardHeader />
      <Container className="mt-4 main-contentarea container-fluid" fluid>
        {/* {!showResumeForm ? ( */}
        <Card className="card bg-white shadow border-0 my-4 mt-13">
          {/* <CardHeader className="bg-transparent">
            <div className="text-muted text-center">
                <small>Add Employee</small>
              </div>
              </CardHeader> */}
          {saveLoader ? (
            <div className="table-loader">
              <img
                alt="..."
                src={
                  require("../../../../../assets/img/icons/customIcons/loader.gif")
                    .default
                }
              />
            </div>
          ) : (
            <CardBody>
              <AddBasicDetails
                // fileName={resume}
                // isImport={isImport}
                isFalse={isFalse}
                isEdit={isEdit}
                close={close}
                saveLoader={saveLoader}
                employeeId={employeeId}
                formData={formData}
                handleOnChange={handleOnChange}
                // handleResumeChange={handleResumeChange}
                handleResumeUpload={handleResumeUpload}
                errors={errors}
                phoneNumberInputChnage={phoneNumberInputChnage}
                handleMobileValidation={handleMobileValidation}
                // imageSelect={(image) => imageSelect(image)}
                bulkUpload={(csvFile) => bulkUpload(csvFile)}
                submitBulkUpload={submitBulkUpload}
                type={location?.state?.type}
              />
              {location?.state?.type === "single_upload" && (
                <>
                  <Row className="justify-content-center border-top py-3">
                    <Col md="12">
                      <Row className="align-items-center">
                        <div className="col">
                          <h3 className="my-1">Skills</h3>
                        </div>
                      </Row>
                    </Col>
                  </Row>

                  {/* {formData.skillSets.map((item, index) => ( */}
                  <AddSkills
                    isEdit={isEdit}
                    errorsData={errors}
                    skillSets={formData?.skillSets}
                    // key={index}
                    // formData={item}
                    handleOnChange={skillSetHandler}
                    // keyIndex={index}
                    deleteSkillSet={deleteSkillSet}
                    addSkillSet={skillsAddHandler}
                    // errors={errors}
                  />
                  {/* ))} */}
                  {errors && errors.skillSets && (
                    <span style={{ color: "red", fontSize: "80%" }}>
                      {errors && errors.skillSets}
                    </span>
                  )}

                  {/* <div className="text-right py-3">
                  
                  <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={skillsAddHandler}
                  >
                    Add
                  </Button>
                </div> */}

                  <Row className="justify-content-center border-top pt-3">
                    <Col md="12">
                      <Row>
                        <div className="mb-1 col">
                          <h3>Projects</h3>
                        </div>
                      </Row>
                    </Col>
                  </Row>

                  {/* {formData.projects.map((item, index) => ( */}
                  <AddProjects
                    errorsData={errors}
                    // key={index}
                    // formData={item}
                    isEdit={isEdit}
                    saveLoader={saveLoader}
                    projects={formData?.projects}
                    handleOnChange={projectsHandler}
                    // keyIndex={index}
                    deleteProject={deleteProject}
                    addProject={projectsAddHandler}
                    // errors={errors}
                  />
                  {/* ))} */}
                  {errors && errors.projects && (
                    <span style={{ color: "red", fontSize: "80%" }}>
                      {errors && errors.projects}
                    </span>
                  )}
                  {/* <div className="text-right py-3">
                  <Button
                    className="mb-4"
                    color="primary"
                    type="button"
                    onClick={projectsAddHandler}
                  >
                    Add
                  </Button> */}
                  {/* </div> */}
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="primary"
                      type="button"
                      disabled={submitLoader}
                      onClick={saveHandler}
                    >
                      {submitLoader ? "Please wait" : "Submit"}
                    </Button>
                  </div>
                </>
              )}
            </CardBody>
          )}
        </Card>
      </Container>
      {/* ) : (
        <AddResume formData={formData} handleOnChange={handleOnChange} />
      )} */}
    </>
  );
}

export default Container1;
