const ScheduledInterviewReducer = (state = {
  allInterviews: { loader: false, result: [], error: null },
  scheduledInterviews: { loader: false, result: [], error: null },
}, action) => {
    switch (action.type) {
      case "SCHEDULED_INTERVIEW_LIST_BEGINS":
        return {
          ...state,
          scheduledInterviews: {
            loader: true,
            result: state.scheduledInterviews.result,
            error: null,
          },
        };
      case "SCHEDULED_INTERVIEW_LIST_SUCCESS":
        return {
          ...state,
          scheduledInterviews: {
            loader: false,
            result: action?.payload,
            error: null,
          },
        };
      case "SCHEDULED_INTERVIEW_LIST_FAILURE":
        return {
          ...state,
          scheduledInterviews: {
            loader: false,
            result: {},
            error: action.payload,
          },
        };
      case "ALL_INTERVIEW_LIST_BEGINS":
        return {
          ...state,
          allInterviews: {
            loader: true,
            result: state.allInterviews.result,
            error: null,
          },
        };
      case "ALL_INTERVIEW_LIST_SUCCESS":
        return {
          ...state,
          allInterviews: {
            loader: false,
            result: action?.payload,
            error: null,
          },
        };
      case "ALL_INTERVIEW_LIST_FAILURE":
        return {
          ...state,
          allInterviews: {
            loader: false,
            result: {},
            error: action.payload,
          },
        };
      default:
        return state;
    }
  };
  
  export default ScheduledInterviewReducer;