// import StyledDropzone from "components/drag-and-drop/DragDropFile";

import { baseURL } from "api/Request";
import StyledDropzone from "components/drag-and-drop/DragDropFile";
import PhoneNumberComponent from "components/phoneNumberInput/PhoneNumberComponent";
import history from "helpers/History";
import React from "react";
import "../addEmployeeForm/style.css";
// import Header from "components/Headers/Header.js";
import {
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
  FormFeedback,
  Button,
} from "reactstrap";
// import AddResume from "./AddProjects";
// import AddSkills from "./AddSkills";
function AddBasicDetails(props) {
  const {
    // fileName,
    // isImport,
    isFalse,
    isEdit,
    close,
    handleResumeUpload,
    handleOnChange,
    formData,
    errors,
    phoneNumberInputChnage,
    handleMobileValidation,
    // imageSelect,
    submitBulkUpload,
    bulkUpload,
    type,
    saveLoader,
  } = props;

  //for viewing resume file if already added in drag and drop of edit
  // if(typeof formData?.resume == 'string'){
  // const resumeFileName = formData?.resume?.split("/").pop()
  // }else{
  //   resumeFileName
  // }

  // const resumeFileName = formData?.resume

  const goBack = () => {
    history.push("/admin/list-employees");
  };
  return (
    <>
      {!!saveLoader && (
        <div className="table-loader">
          <img
            alt="..."
            src={
              require("../../../../../assets/img/icons/customIcons/loader.gif")
                .default
            }
          />
        </div>
      )}
      {type === "bulk_upload" ? (
        <Row className="justify-content-center mb-3">
          <Col md="12">
            <Form role="form">
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <h3 className="add-titles-box">Import File</h3>
                <Button color="primary" onClick={goBack} size="sm">
                  <i class="fa fa-times"></i>
                </Button>
              </div>
              <Label
                style={{ fontSize: "10px" }}
                for="employeeName"
                className="text-uppercase"
              >
                (
                {
                  <a
                    // eslint-disable-next-line no-useless-concat
                    href={
                      baseURL +
                      "/" +
                      "public/samples/employee_smple_csv - Sheet.csv"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                    className="font-weight-bold"
                  >
                    Download Sample CSV And Edit{" "}
                  </a>
                }
                )
              </Label>
              <StyledDropzone
                isFalse={isFalse}
                // isImport={isImport}
                type="csv"
                onChangeImage={(e) => {
                  bulkUpload(e);
                }}
              />

              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  disabled={saveLoader}
                  onClick={submitBulkUpload}
                >
                  {saveLoader ? "Please wait" : "Submit"}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      ) : (
        <Row className="justify-content-center mb-3">
          <Col md="12">
            <Form role="form">
              <div className="mb-3 d-flex justify-content-between align-items-center">
                <h3 className="add-titles-box">
                  {isEdit ? "Edit Employee" : "Add Employee"}
                </h3>
                <Button color="primary" onClick={goBack} size="sm">
                  <i class="fa fa-times"></i>
                </Button>
                {/* <Button color="primary" onClick={goBack} size="sm">
                <i class="fa fa-times"></i>
              </Button> */}
              </div>
              {/* <Label
                    style={{ fontSize: "10px" }}
                    for="employeeName"
                    className="text-uppercase"
                  >
                    Bulk employee upload
                    ({
                      <a
                // eslint-disable-next-line no-useless-concat
                href={baseURL + "/" + "public/samples/employee_smple_csv - Sheet.csv"}
                target="_blank"
                rel="noopener noreferrer"
                download
                className="font-weight-bold"
              >
                Download Sample CSV
              </a>
                    })
                  </Label>
            <StyledDropzone
              type="csv"
              onChangeImage={(e) => {
                imageSelect(e);
              }}
            /> */}
              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="employeeName"
                      className="text-uppercase"
                    >
                      First Name<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-user" />
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        required
                        placeholder="First Name"
                        type="text"
                        name="firstName"
                        onChange={(e) => handleOnChange(e)}
                        value={formData?.firstName}
                        invalid={!!errors && !!errors?.firstName}
                      />
                    </InputGroup>
                    <FormFeedback
                      className={errors?.firstName && "invalid-field"}
                    >
                      {errors?.firstName}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="employeeName"
                      className="text-uppercase"
                    >
                      Last Name<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-user" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        required
                        placeholder="Last Name "
                        type="text"
                        name="lastName"
                        onChange={(e) => handleOnChange(e)}
                        value={formData?.lastName}
                        invalid={!!errors && !!errors?.lastName}
                      />
                    </InputGroup>
                    <FormFeedback
                      className={errors?.lastName && "invalid-field"}
                    >
                      {errors?.lastName}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="empId"
                      className="text-uppercase"
                    >
                      Employee ID<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        required
                        placeholder="Employee ID"
                        type="text"
                        name="empId"
                        value={formData.empId}
                        onChange={(e) => handleOnChange(e)}
                        invalid={!!errors && !!errors.empId}
                      />
                    </InputGroup>
                    <FormFeedback className={errors?.empId && "invalid-field"}>
                      {errors?.empId}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col sm={6}>
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="employeeName"
                      className="text-uppercase"
                    >
                      Designation<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-briefcase-24" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        required
                        placeholder="Designation"
                        type="text"
                        name="designation"
                        value={formData.designation}
                        onChange={(e) => handleOnChange(e)}
                        invalid={!!errors && !!errors.designation}
                      />
                    </InputGroup>
                    <FormFeedback
                      className={errors?.designation && "invalid-field"}
                    >
                      {errors?.designation}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="employeeName"
                      className="text-uppercase"
                    >
                      Phone
                    </Label>

                    {/* <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon> */}
                    {/* <Input
                      required
                      placeholder="Phone"
                      type="phone"
                      name="mobile"
                      value={formData.mobile}
                      onChange={(e) => handleOnChange(e)}
                      invalid={errors && errors.mobile}
                    /> */}
                    <PhoneNumberComponent
                      name="mobile"
                      id="mobile"
                      country={"in"}
                      value={formData.mobile}
                      handleChange={phoneNumberInputChnage}
                      handleMobileValidation={handleMobileValidation}
                    />

                    <FormFeedback
                      className={
                        (errors?.mobile || errors?.mobileNumberVal) &&
                        "invalid-field"
                      }
                    >
                      {console.log("err..", errors)}
                      {errors?.mobile || errors?.mobileNumberVal}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="employeeName"
                      className="text-uppercase"
                    >
                      Email Address<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        required
                        placeholder="Email"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={(e) => handleOnChange(e)}
                        invalid={!!errors && !!errors.email}
                      />
                    </InputGroup>
                    <FormFeedback className={errors?.email && "invalid-field"}>
                      {errors?.email}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="employeeName"
                      className="text-uppercase"
                    >
                      Currency<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i class="fas fa-money-bill-alt"></i>{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        required
                        placeholder="currency"
                        type="select"
                        name="currencyCode"
                        value={formData.currencyCode}
                        onChange={(e) => handleOnChange(e)}
                        invalid={!!errors && !!errors.currencyCode}
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        <option value="INR">INR</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                      </Input>
                    </InputGroup>
                    <FormFeedback
                      className={errors?.currencyCode && "invalid-field"}
                    >
                      {errors?.currencyCode}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="employeeName"
                      className="text-uppercase"
                    >
                      Payment<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i class="fas fa-money-check-alt"></i>{" "}
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        required
                        placeholder="Hourly Payment"
                        type="number"
                        max={9999}
                        name="hourlyPayment"
                        value={formData.hourlyPayment}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d{0,4}$/.test(value)) {
                            handleOnChange(e);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "." || e.key === "e") {
                            e.preventDefault(); // Prevents typing "." or "e"
                          }
                        }}
                        invalid={!!errors && !!errors.hourlyPayment}
                      />
                    </InputGroup>
                    <FormFeedback
                      className={errors?.hourlyPayment && "invalid-field"}
                    >
                      {errors?.hourlyPayment}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  {/* <Label
                    style={{ fontSize: "10px" }}
                    for="employeeName"
                    className="text-uppercase"
                  >
                    TOTAL EXPERIENCE
                    <span style={{ color: "red" }}>*</span>
                  </Label> */}
                  <Row>
                    <Col sm={6}>
                      <FormGroup>
                        <Label
                          style={{ fontSize: "10px" }}
                          for="fromdate"
                          className="text-uppercase"
                        >
                          Total Experience in years
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <InputGroup className="input-group-alternative shadow-none border">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i class="fas fa-business-time"></i>{" "}
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            required
                            // min={0}
                            placeholder="Experience in years"
                            type="number"
                            min={0}
                            name="experienceNumberOfYears"
                            onKeyPress={(e) => {
                              if (e.key === "e") {
                                e.preventDefault();
                              }
                            }}
                            value={formData?.experienceNumberOfYears}
                            // value={isEdit? formData?.experienceNumberOfYears+ "." +formData?.experienceNumberOfMonths : formData?.experienceNumberOfYears}

                            onChange={(e) => handleOnChange(e)}
                            invalid={
                              !!errors && !!errors.experienceNumberOfYears
                            }
                          />
                        </InputGroup>
                        <FormFeedback
                          className={
                            errors?.experienceNumberOfYears && "invalid-field"
                          }
                        >
                          {errors?.experienceNumberOfYears}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    {/* <Col>
                      <FormGroup>
                        <Label
                          style={{ fontSize: "10px" }}
                          for="employeeName"
                          className="text-uppercase"
                        >
                          months
                          <span style={{ color: "red" }}>*</span>
                        </Label>
                        <InputGroup className="input-group-alternative shadow-none border">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-hat-3" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            min={0}
                            max={12}
                            required
                            placeholder="months"
                            type="number"
                            name="experienceNumberOfMonths"
                            onKeyPress={(e) => {
                              if (e.key === "e") {
                                e.preventDefault();
                              }
                            }}
                            value={formData.experienceNumberOfMonths}
                            onChange={(e) => handleOnChange(e)}
                            invalid={
                              !!errors && !!errors.experienceNumberOfMonths
                            }
                          />
                        </InputGroup>
                        <FormFeedback
                          className={
                            errors?.experienceNumberOfMonths && "invalid-field"
                          }
                        >
                          {errors?.experienceNumberOfMonths}
                        </FormFeedback>
                      </FormGroup>
                    </Col> */}
                  </Row>
                </Col>
                {/* <Col>
                
              </Col> */}
              </Row>
              <Row>
                <Col sm={12}>
                  <FormGroup>
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                      <Label
                        style={{ fontSize: "10px" }}
                        for="employeeName"
                        className="text-uppercase"
                      >
                        Resume{" "}
                        {isEdit
                          ? "(Edit resume (pdf or doc with size < 2 MB))"
                          : "(pdf or doc with size < 2 MB)"}
                        {/* <button  onClick={close} >x</button> */}
                        {/* Resume(Edit resume (pdf or doc)) */}
                      </Label>
                      {/* <Button color="primary" onClick={close} size="sm">
                     <i class="fa fa-times"></i>
                    </Button> */}
                    </div>

                    <StyledDropzone
                      isEdit={isEdit}
                      onClose={close}
                      type="doc"
                      onChangeImage={handleResumeUpload}
                      resumeFileName={formData?.resume}
                    />
                    <FormFeedback
                      className={errors?.resume && "resume-invalid"}
                    >
                      {errors?.resume}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            {/* </CardBody>
          </Card> */}
          </Col>
        </Row>
      )}
    </>
  );
}

export default AddBasicDetails;
