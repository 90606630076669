import React from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
} from "reactstrap";
export default function BookEmployeeModal({ close, open,bookingFormData,handleOnChange,disablePastDate,errors,currentCurrencyCode,onScheduleInterview,onSubmit }) {

  const disablesPastDate = () => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in "YYYY-MM-DD" format
    return today;
  };
  return (
    <div>
      <Modal toggle={close} centered isOpen={open}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              {" "}
              Book employee
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <p className="font-weight-normal">
            Please select start date and end date of your booking
          </p>
          <FormGroup row>
            <Col sm={6}>
              <Label for="durationFromDate">
                Starting Date
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                className="border"
                id="durationFromDate"
                name="durationFromDate"
                type="date"
                value={bookingFormData.durationFromDate}
                onChange={(e) => handleOnChange(e)}
                min={disablesPastDate()}
                invalid={!!errors && !!errors.durationFromDate}
              />
              <FormFeedback
                className={errors?.durationFromDate && "invalid-field"}
              >
                {errors?.durationFromDate}
              </FormFeedback>
            </Col>
            <Col sm={6}>
              <Label for="durationtoDate">
                Closing Date
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                className="border"
                id="durationtoDate"
                name="durationtoDate"
                type="date"
                value={bookingFormData.durationtoDate}
                onChange={(e) => handleOnChange(e)}
                min={disablesPastDate()}
                invalid={!!errors && !!errors.durationtoDate}
              />
              <FormFeedback
                className={errors?.durationtoDate && "invalid-field"}
              >
                {errors?.durationtoDate}
              </FormFeedback>
            </Col>
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Employee ID
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input name="empId" value={bookingFormData?.empId} readOnly />
                </InputGroup>
              </FormGroup>
            </Col>
            {/* <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Company
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="ni ni-building" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="companyName"
                    value={bookingFormData.companyName}
                    readOnly
                  />
                </InputGroup>
              </FormGroup>
            </Col> */}
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Currency
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="fa fa-credit-card" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="currency"
                    type="text"
                    name="currencyCode"
                    value={currentCurrencyCode}
                    onChange={(e) => handleOnChange(e)}
                    invalid={!!errors && !!errors.currencyCode}
                    disabled
                  />

                  {/* <option value={item.currencyCode} disabled selected>
                          {item.currencyCode}
                        </option> */}
                  {/* </Input> */}
                  <FormFeedback
                    className={errors?.currencyCode && "invalid-field"}
                  >
                    {errors?.currencyCode}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Payment
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Hourly Payment"
                    // type="text"
                    name="hourlyPayment"
                    value={bookingFormData.hourlyPayment}
                    readOnly
                    // onChange={(e) => handleOnChange(e)}
                    invalid={!!errors && !!errors.hourlyPayment}
                  />
                </InputGroup>
                <FormFeedback
                  className={errors?.hourlyPayment && "invalid-field"}
                >
                  {errors?.hourlyPayment}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup className="mb-0">
            <Label
              style={{ fontSize: "10px" }}
              for="remarks"
              className="text-uppercase"
            >
              Remarks<span style={{ color: "red" }}>*</span>
            </Label>
            <InputGroup className="input-group-alternative shadow-none border">
              <Input
                placeholder="Remarks"
                type="textarea"
                name="remarks"
                onChange={(e) => handleOnChange(e)}
                value={bookingFormData.remarks}
                invalid={!!errors && !!errors.remarks}
              />
            </InputGroup>
            <FormFeedback className={errors?.remarks && "invalid-field"}>
              {errors?.remarks}
            </FormFeedback>
          </FormGroup>
          <br></br>
          <p className="font-weight-normal" style={{ fontSize: "12px" }}>
            You can click 'Schedule Interview' to set up an interview or
            'Submit' to directly submit a booking request.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={onScheduleInterview} color="primary">
            Schedule Interview
          </Button>
          <Button size="sm" onClick={onSubmit} color="primary">
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
