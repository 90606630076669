import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { axiosAccountInstance } from "api/Request";
import { setCompanyApprovalInfo } from "redux/actions/super-admin/CompanyApprovalAction";
import approveIcn from "../../../../../assets/img/icons/customIcons/approve.svg";
import rejectIcn from "../../../../../assets/img/icons/customIcons/reject.svg";
import toast from "../../../../../components/Toastify";
import Tables from "views/examples/Tables";

function ComapnyApprovalCard({ loader }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const [modal, setModal] = useState(false);
  const [, setData] = useState([]);
  const [rejectRequestReason, setRejectRequestReason] = useState("");
  const [employeeID, setEmployeeID] = useState("");
  const fetchResultApprovalListRequests = useSelector(
    (state) => state.CompanyApprovalReducer.result
  );

  useEffect(() => {
    setData(fetchResultApprovalListRequests?.companies || []);
  }, [fetchResultApprovalListRequests]);

  const handleOnChange = (e) => {
    setRejectRequestReason(e.target.value);

    setErrors({ ...errors, rejectRequestReason: null });
  };

  const rejectHandler = (id) => {
    setEmployeeID(id);
    setModal(!modal);
  };
  const close = () => {
    setRejectRequestReason("");
    setModal(false);
  };
  const validation = (data) => {
    // const [address, setAddress] = useState("");
    // const [country, setCountry] = useState("");
    // const [companyId, setCompanyId] = useState("");
    // const [registerNumber, setRegisterNumber] = useState("");
    // const [companyName, setCompanyName] = useState("");
    // const [gstNumber, setGstNumber] = useState("");

    const errorsFound = {};

    if (!rejectRequestReason || rejectRequestReason === "") {
      errorsFound["rejectRequestReason"] = "Reject Request Reason is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };
  const setCompanyApproval = (isValid = true, data) => {
    if (isValid) {
      axiosAccountInstance
        .post("/superadmin/company/request/approve", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            if (data.status === "approved") {
              toast.success(res.data.body.message);
            } else {
              toast.success(res.data.body.message);
            }

            dispatch(setCompanyApprovalInfo("pending"));
            setModal(false);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setRejectRequestReason("");
    }
  };

  const submitRequest = (data) => {
    if (data.status === "rejected") {
      const isValid = validation(data);
      setCompanyApproval(isValid, data);
    } else {
      setCompanyApproval(true, data);
    }
  };

  const onReject = () => {
    const finalData = {
      _id: employeeID,
      status: "rejected",
      rejectReason: rejectRequestReason,
    };

    submitRequest(finalData);
  };

  const onApprove = (_id) => {
    const finalData = {
      _id,
      status: "approved",
    };

    submitRequest(finalData);
  };
  const colums = [
    // { columnName: "Company", columnTag: "companyName", type: "data" },
    { columnName: "Employee ID", columnTag: "empId", type: "data" },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    {
      columnName: "Contact Number",
      columnTag: "contactNumber",
      type: "data",
    },
    { columnName: "Address", columnTag: "address", type: "data" },
    { columnName: "", columnTag: "", type: "" },
  ];

  const actionButtons = [
    {
      columnName: "",
      type: "actionButton",
      btnTitle: "Approve",
      btnTooltip: "Approve",
      btnType: "primary",
      btn: "CompanyApproval",
      btnIcon: approveIcn,
      actionFunction: onApprove,
    },
    {
      columnName: "",
      btnTitle: "Reject",
      type: "actionButton",
      btn: "CompanyReject",
      btnTooltip: "Reject",
      btnType: "danger",
      btnIcon: rejectIcn,
      actionFunction: rejectHandler,
    },
  ];
  return (
    <div>
      <Tables
        coloums={colums}
        data={fetchResultApprovalListRequests?.companies}
        actionButtons={actionButtons}
        loader={loader}
      />

      {/* <Row className="justify-content-center mt-1">
            <Col md="12">
              <Card className="card-stats  my-1">
                <CardBody>
                  <Row>
                   
                    <div className="col-lg-3 col-sm-4">
                      <div className="card-box">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase font-weight-bolder text-darker mb-1"
                        >
                          Company
                        </CardTitle>
                        <p className="h5 font-weight-normal mb-0">
                          {companyName}
                        </p>
                        <p className="h5 font-weight-normal mb-0">{address}</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4">
                      <div className="card-box">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase font-weight-bolder text-darker mb-1"
                        >
                          Designation
                        </CardTitle>
                        <p className="h5 font-weight-normal mb-0">
                          {item.designation}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-sm-4">
                      <div className="card-box">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase font-weight-bolder text-darker mb-1"
                        >
                          Contact
                        </CardTitle>
                        <p className="h5 font-weight-normal mb-0">
                          {contactNumber}
                        </p>
                        <p className="h5 font-weight-normal mb-0">{email}</p>
                      </div>
                    </div>

                    <div className="col-lg-2 col-sm-12">
                      <div className="card-box button-boxes justify-content-lg-end">
                        <div className="text-center mr-2">
                          <Button
                            onClick={() => onApprove(item._id)}
                            size="sm"
                            color="primary"
                            outline
                          >
                            Approve
                          </Button>
                        </div>

                        <div className="text-center mr-2">
                          <Button
                            size="sm"
                            color="danger"
                            outline
                            onClick={() => rejectHandler(item._id)}
                          >
                            Reject
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
      <Modal toggle={close} size="sm" centered isOpen={modal}>
        <ModalHeader className="text-uppercase font-weight-bolder">
          <span> Reject Company</span>
          <Button onClick={close} close />
        </ModalHeader>
        <ModalBody>
          <label>Do you want to reject company approval?</label>
          <FormGroup className="mb-0">
            <Label for="exampleText">Rejection Remarks</Label>
            <Input
              id="exampleText"
              name="text"
              type="textarea"
              onChange={handleOnChange}
              value={rejectRequestReason}
              invalid={!!errors && !!errors.rejectRequestReason}
            />
            <FormFeedback
              className={errors?.rejectRequestReason && "invalid-field"}
            >
              {errors?.rejectRequestReason}
            </FormFeedback>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={() => onReject()} color="primary">
            Submit
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ComapnyApprovalCard;
