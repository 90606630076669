import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
  Input,
  InputGroup,
  Label,
  InputGroupText,
  InputGroupAddon,
  FormFeedback,
  ModalFooter
} from "reactstrap";
import toast from "../Toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "helpers/storageData";
import { Rating } from "react-simple-star-rating";
import { axiosAccountInstance } from "api/Request";
// import downloadIcn from "../../assets/img/icons/customIcons/download-svg.svg";
import downloadResumeIcon from "../../assets/img/icons/common/download.png";
import shareIcon from "../../assets/img/icons/common/share.png";
import uploadIcon from "../../assets/img/icons/common/upload.png";
import { formatDate,uniqueDate } from "helpers/getDate";
import history from "helpers/History";
import { setEmployeeListInfo } from "redux/actions/super-admin/EmployeeListAction";
import AddNewSlot from "views/examples/users/employee-search-and-sending-booking-requests/Add-Slots";

const EmployeeModal = ({ modal, close, data, currentData, loading,isOutbound ,isInbound,searchFormData,tab,resumeUploadHandler}) => {
  const onClose = () => {
    setClickDisabled(false);
    setBookingModal(false)
    close();
    setBookingFormData({
      employeeId: "",
      empId: "",
      durationFromDate: "",
      durationtoDate: "",
      hourlyPayment: "",
      currencyCode: "",
      companyName: "",
      remarks: "",
    });
    setErrors("")
    setIsClicked(false)
  };
  const [bookingModal, setBookingModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [interviewModal, setInterviewModal] = useState(false);
  const [bookingFormData, setBookingFormData] = useState({
    employeeId: "",
    durationFromDate: "",
    durationtoDate: "",
    hourlyPayment: "",
    currencyCode: "",
    remarks: "",
  });
  const [currentCurrencyCode, setCurrentCurrencyCode] = useState(null);
  const [interviewFormData, setInterviewFormData] = useState({
    isInterview:"",
    slots: [],
    interviewRemarks: "",
  });
  const handleOnChange = (e) => {
    setBookingFormData({ ...bookingFormData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: null });
  };
  
  const disablePastDate = () => {
    const today = new Date();
 
    let dd;
    let mm;
    if (interviewFormData?.isInterview ) {
 
      if(today.getHours() < 12){
      
        return new Date(today.getFullYear(), today.getMonth(), today.getDate())
      }else{
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
      }
      // dd = String(today.getDate()).padStart(2, "0");
      // mm = String(today.getMonth() + 1).padStart(2, "0");
      
    } else {
      dd = String(today.getDate() + 1).padStart(2, "0");
      mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
       
    }
    
  };
  const validation = () => {
    const {
      // employeeId,
      durationFromDate,
      durationtoDate,
      hourlyPayment,
      currencyCode,
      remarks,
    } = bookingFormData;

    const errorsFound = {};
    // if (!employeeId) errorsFound["employeeId"] = "Oops! please add employeeId";
    if(durationFromDate>durationtoDate && durationFromDate && durationtoDate)
    errorsFound["durationFromDate"] = "Starting date must be less than closing date";
    if (!durationFromDate)
      errorsFound["durationFromDate"] = "Starting date is required";
    if (!durationtoDate)
      errorsFound["durationtoDate"] = "Closing date is required";
    if (!hourlyPayment)
      errorsFound["hourlyPayment"] = "Hourly payment is required";
    if (!currencyCode)
      errorsFound["currencyCode"] = "Currency code is required";
    if (!remarks) errorsFound["remarks"] = "Remarks is required";

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };
  const onSubmit = async () => {
    const isValid = await validation();

    if (isValid) {
      const finalFormData = bookingFormData;
      axiosAccountInstance
        .post("/createbooking", finalFormData)
        .then((res) => {
          toast.success(res.data.body.message);
          history.push("/admin/booking-details");
          setBookingFormData({
            employeeId: "",
            empId: "",
            durationFromDate: "",
            durationtoDate: "",
            hourlyPayment: "",
            currencyCode: "",
            companyName: "",
            remarks: "",
          });
          // const finalFormData = {
          //   ...searchFormData,
          //   fromDate: formatDate(),
          //   toDate: formatDate(1),
          //   page: tab,
          // };

          socketInstance.emit("message", {
            type: "CREATE_BOOKING",
            subType: "UPDATE_PENDING_BOOKING_APPROVAL_REQUESTS",
            key: bookingFormData?.employeeId,
            extraInfo: fetchUserData()?.companyName,
          });
          dispatch(setEmployeeListInfo("booking", ""));
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setBookingFormData("");
      setBookingModal(false);
    } else {
      // your code here
    }
  };
  const onSubmitInterview = async (data,remark) => {
    
    // const isValid = await interviewValidation();

  
    if (true) {
      const finalFormData = {...interviewFormData,slots:data,interviewRemarks:remark};
      axiosAccountInstance
        .post("/createbooking", finalFormData)
        .then((res) => {
          toast.success(res.data.body.message);
          history.push("/admin/booking-details");
          setInterviewFormData({
            employeeId: "",
            empId: "",
            durationFromDate: "",
            durationtoDate: "",
            hourlyPayment: "",
            currencyCode: "",
            companyName: "",
            remarks: "",
            date: "",
            duration: "",
            slots: [],
            interviewRemarks: "",
          });
          // const finalFormData = {
          //   ...searchFormData,
          //   fromDate: formatDate(),
          //   toDate: formatDate(1),
          //   page: tab,
          // };

          socketInstance.emit("message", {
            type: "INTERVIEW_REQUESTED",
            subType: "UPDATE_PENDING_INTERVIEW_APPROVAL_REQUESTS",
            key: bookingFormData?.employeeId,
            extraInfo: fetchUserData()?.companyName,
          });
          // dispatch(setEmployeeListInfo("booking", finalFormData));
          dispatch(setEmployeeListInfo("booking", ""));
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setBookingFormData("");
      // setModal(false);
      setInterviewModal(false)
    } else {
      // your code here
    }
  };

  const onScheduleInterview = async () => {
    //interview schedule
    const isValid = await validation();
    console.log("sche...", bookingFormData);
    if (isValid) {
      setInterviewFormData({
        ...bookingFormData,
        ...interviewFormData,
        isInterview: true,
      });
      // console.log("sche...",interviewFormData)
      setBookingModal(false);
      setInterviewModal(!interviewModal);
      return;
    }
  };
  const closeInterview=()=>{
    setInterviewFormData({
     
      date: "",
      duration: "",
      slots: [],
      interviewRemarks: "",
    })
  }
  const bookingHandler = () => {
    console.log("data....................../",data)
    setBookingFormData({
      ...bookingFormData,
      hourlyPayment: data?.hourlyPayment,
      currencyCode: data?.currencyCode,
      employeeId: data?.employeeId,
      companyName: data?.companyName,
      empId: data?.empId,
    });
    setCurrentCurrencyCode(data?.currencyCode);
    setBookingModal(true);
    close();
    // setBookingModal(!modal);
    return;
  };
  const dispatch = useDispatch();
  const [isClickDisabled, setClickDisabled] = useState(false);
  const [isClicked,setIsClicked]=useState(false)
  const socketInstance = useSelector((state) => state.socketReducer.result);
  const onClickHandler = () => {
    console.log("onclick/....");
    if (isClickDisabled) {
      console.log("if...");
      return;
    }
    // Disable multiple resume request click
    setClickDisabled(true);
    setIsClicked(true)
    axiosAccountInstance

      .get(
        `${process.env.REACT_APP_API_URL}request_resume?employeeId=${data?.employeeId}`
      )
      .then((result) => {
        socketInstance.emit("message", {
          type: "RESUME REQUEST",
          key: data?.employeeId,
          user: fetchUserData()?.companyId,
          extraInfo: fetchUserData()?.companyName,
        });
        toast.success(result.data.body.data);
     console.log("result",result.data)
      const finalFormData = {
            ...searchFormData,
            fromDate: formatDate(),
            toDate: formatDate(1),
            page: tab,
          };
     dispatch(setEmployeeListInfo("booking", finalFormData));
        dispatch({
          payload: result.data.body.data,
        });
      });

    //   .catch((err) => {
    //     toast.error(result.data.body.data);
    //   });
  };
  const uploadHandler = (data) => {
    console.log("upload..",data)
    close()
   resumeUploadHandler(data.id,data)
    
     
    
  };
  // function handleViewResume() {
  //   if (!data.resume) {
  //     toast.error("Resume not found");
  //   }
  // }
  const toggle = () => {
    setClickDisabled(false);
    close();
    setIsClicked(false)

  };
  const href = data?.resume
    ? `${process.env.REACT_APP_API_URL}${data?.resume}`
    : "#";

  return (
    <div>
      <Modal
        toggle={toggle}
        scrollable
        isOpen={modal}
        className="rightside-modal"
      >
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder pt-4">
              {" "}
              Employee Details
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase pt-3 pb-2">
                <Button onClick={onClose} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <FormGroup row className="employee-det-row mb-0">
            <Col sm={12}>
              <div className="first-row">
                <div className="labels">
                  <h4>Employee ID </h4>
                </div>
                <div className="values">
                  <p>{data?.empId}</p>
                </div>
              </div>
            </Col>
            {data?.sowStatus === "approved" && (
              <Col sm={12}>
                <div className="first-row">
                  <div className="labels">
                    <h4>Employee Name </h4>
                  </div>
                  <div className="values">
                    <p>{data?.name}</p>
                  </div>
                </div>
              </Col>
            )}
            {/* <Col sm={6}>
              <div className="first-row">
                <div className="labels">
                  <h4>Email </h4>
                </div>
                <div className="values">
                  <p>{data?.email}</p>
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="first-row">
                <div className="labels">
                  <h4>Mobile </h4>
                </div>
                <div className="values">
                  <p>{data?.mobile}</p>
                </div>
              </div>
            </Col> */}

            {/* <Col sm={12}>
              <div className="first-row">
                <div className="labels">
                  <h4>Company Name </h4>
                </div>
                <div className="values text-capitalize">
                  <p>{data?.companyName}</p>
                </div>
              </div>
            </Col> */}
            <Col sm={12}>
              <div className="first-row">
                <div className="labels">
                  <h4>Designation </h4>
                </div>
                <div className="values text-capitalize">
                  <p>{data?.designation}</p>
                </div>
              </div>
            </Col>
            <Col sm={12}>
              <div className="first-row">
                <div className="labels">
                  <h4>Skills </h4>
                </div>
                <div className="values">
                  {data?.skillSets?.map((skill, key) => {
                    return (
                      <div className="skill-values justify-content-between">
                        <div className="d-flex">
                          <span className="skillname">{skill?.domain}</span>
                          <span className="expname">
                            {skill?.numberOfYears + "." + skill?.numberOfMonths}{" "}
                            years
                          </span>
                        </div>

                        <Rating
                          className="rating-area"
                          initialValue={skill?.rating}
                          name="rating"
                          readonly="true"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
            {/* <Col sm={data?.resume ?6 :12}> */}
            <Col sm={6}>
              <div className="first-row">
                <div className="labels">
                  <h4>Hourly Payment </h4>
                </div>
                <div className="values">
                  <p>
                    {data?.currencyCode === "INR" && "₹"}
                    {data?.currencyCode === "EUR" && "€"}
                    {data?.currencyCode === "USD" && "$"}
                    {data?.hourlyPayment}
                  </p>
                </div>
              </div>
            </Col>

            {!isInbound && (
              <Col sm={6}>
                <div className="first-row">
                  <div className="labels">
                    <h4>Resume</h4>
                  </div>

                  {data?.resume ? (
                    <div
                      className="values"
                      style={{ fontSize: "14px", marginTop: "-3px" }}
                    >
                      <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{ color: "#525F7F", fontSize: "14px" }}
                        //  onClick={(event) =>
                        //    !data.resume && event.preventDefault()
                        //  }
                      >
                        <img src={downloadResumeIcon} width={"21"} alt=""></img>
                        Download
                      </a>
                    </div>
                  ) : data?.resumeStatus === "requested" || isClicked ? (
                    <div className="values" style={{ fontSize: "14px" }}>
                      Request Sent
                    </div>
                  ) : (
                    <div className="values" style={{ fontSize: "14px" }}>
                      {/* <img alt="" src={shareIcon} style={{marginTop:-3, marginRight:4, width:16}} />
                     Request */}
                      <span style={{ cursor: "pointer" }} onClick={()=>onClickHandler()}>
                        <img
                          alt=""
                          src={shareIcon}
                          style={{ marginTop: -3, marginRight: 4, width: 16 }}
                        />
                        Request
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            )}
            {/* {isInbound &&
              data?.resume ? ( //inbound resume req
                <Col sm={6}>
                  <div className="first-row">
                    <div className="labels">
                      <h4>Resume</h4>
                    </div>

                    <div
                      className="values"
                      style={{ fontSize: "14px", marginTop: "-3px" }}
                    >
                      <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{ color: "#525F7F", fontSize: "14px" }}
                        //  onClick={(event) =>
                        //    !data.resume && event.preventDefault()
                        //  }
                      >
                        <img src={downloadResumeIcon} width={"21"} alt=""></img>
                        Download
                      </a>
                    </div>
                  </div>
                </Col>
              ):
              (isInbound &&
                !data?.resume &&
                <Col sm={6}>
                <div className="first-row">
                  <div className="labels">
                    <h4>Resume</h4>
                  </div>

                  <div
                    className="values"
                    style={{ fontSize: "14px", marginTop: "-3px" }}
                  >
                      <span style={{ cursor: "pointer" }} onClick={()=>uploadHandler(data)}>
                    <img
                      alt=""
                      src={uploadIcon}
                      style={{ marginTop: -3, marginRight: 4, width: 16 }}
                    />
                    Upload
                  </span>
                   
                  </div>
                </div>
              </Col>
              
              )} */}
{isInbound && (
  <Col sm={6}>
    <div className="first-row">
      <div className="labels">
        <h4>Resume</h4>
      </div>
      <div
        className="values"
        style={{ fontSize: "14px", marginTop: "-3px" }}
      >
        {data?.resume ? (
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            download
            style={{ color: "#525F7F", fontSize: "14px" }}
          >
            <img src={downloadResumeIcon} width={"21"} alt=""></img>
            Download
          </a>
        ) : (
          <span style={{ cursor: "pointer" }} onClick={() => uploadHandler(data)}>
            <img
              alt=""
              src={uploadIcon}
              style={{ marginTop: -3, marginRight: 4, width: 16 }}
            />
            Upload
          </span>
        )}
      </div>
    </div>
  </Col>
)}
           

            <Col sm={12}>
              <div className="first-row">
                <div className="labels">
                  <h4>Current Booking</h4>
                  {currentData.length === 0 && !loading ? (
                    <span style={{ fontSize: "14px" }}>
                      No current bookings
                    </span>
                  ) : (
                    <>
                      <Table>
                        <thead>
                          <tr>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading && currentData?.length > 0
                            ? currentData?.map((data) => (
                                <tr key={data?._id}>
                                  <td>
                                    {uniqueDate(data?.duration?.fromDate)}
                                  </td>
                                  <td>
                                    {uniqueDate(data?.duration?.toDate)}
                                  </td>
                                  <td>
                                    {data?.status.charAt(0).toUpperCase() +
                                      data?.status.slice(1)}
                                  </td>
                                </tr>
                              ))
                            : loading && (
                                <tr>
                                  <td colSpan={3}>
                                    <Spinner size={"sm"} />
                                  </td>
                                </tr>
                              )}
                        </tbody>
                      </Table>
                    </>
                  )}
                </div>
              </div>
            </Col>
            {isOutbound && (
              <div style={{ marginLeft: "8px", marginBottom: "6px" }}>
                <Button
                  size="sm"
                  color="primary"
                  onClick={bookingHandler}
                  className="ml-2"
                >
                  Book Employee
                </Button>
              </div>
            )}
            <div></div>

            {/* <h4>Current Booking</h4>
          <Table>
          <thead>
          <tr>
            <th>From Date</th>
            <th>To Date</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          {currentData?.map(data => (
            <tr key={data?._id}>
              
              <td>{moment(data?.duration?.fromDate).format("MMMM DD, YYYY")}</td>
              <td>{moment(data?.duration?.toDate).format("MMMM DD, YYYY")}</td>
              <td>{data?.status}</td>
            </tr>
          ))}
         </tbody>
          </Table> */}
          </FormGroup>
        </ModalBody>
      </Modal>

      <Modal toggle={onClose} centered isOpen={bookingModal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              {" "}
              Book employee
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={onClose} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <p className="font-weight-normal">
            Please select start date and end date of your booking
          </p>
          <FormGroup row>
            <Col sm={6}>
              <Label for="durationFromDate">
                Starting Date
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                className="border"
                id="durationFromDate"
                name="durationFromDate"
                type="date"
                value={bookingFormData.durationFromDate}
                onChange={(e) => handleOnChange(e)}
                min={disablePastDate()}
                invalid={!!errors && !!errors.durationFromDate}
              />
              <FormFeedback
                className={errors?.durationFromDate && "invalid-field"}
              >
                {errors?.durationFromDate}
              </FormFeedback>
            </Col>
            <Col sm={6}>
              <Label for="durationtoDate">
                Closing Date
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                className="border"
                id="durationtoDate"
                name="durationtoDate"
                type="date"
                value={bookingFormData.durationtoDate}
                onChange={(e) => handleOnChange(e)}
                min={disablePastDate()}
                invalid={!!errors && !!errors.durationtoDate}
              />
              <FormFeedback
                className={errors?.durationtoDate && "invalid-field"}
              >
                {errors?.durationtoDate}
              </FormFeedback>
            </Col>
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Employee ID
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input name="empId" value={bookingFormData?.empId} readOnly />
                </InputGroup>
              </FormGroup>
            </Col>
            {/* <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Company
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="ni ni-building" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="companyName"
                    value={bookingFormData.companyName}
                    readOnly
                  />
                </InputGroup>
              </FormGroup>
            </Col> */}
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Currency
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="fa fa-credit-card" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="currency"
                    type="text"
                    name="currencyCode"
                    value={currentCurrencyCode}
                    // onChange={(e) => handleOnChange(e)}
                    invalid={!!errors && !!errors.currencyCode}
                    disabled
                  />

                  {/* <option value={item.currencyCode} disabled selected>
                          {item.currencyCode}
                        </option> */}
                  {/* </Input> */}
                  <FormFeedback
                    className={errors?.currencyCode && "invalid-field"}
                  >
                    {errors?.currencyCode}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Payment
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Hourly Payment"
                    // type="text"
                    name="hourlyPayment"
                    value={bookingFormData.hourlyPayment}
                    readOnly
                    // onChange={(e) => handleOnChange(e)}
                    invalid={!!errors && !!errors.hourlyPayment}
                  />
                </InputGroup>
                <FormFeedback
                  className={errors?.hourlyPayment && "invalid-field"}
                >
                  {errors?.hourlyPayment}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup className="mb-0">
            <Label
              style={{ fontSize: "10px" }}
              for="remarks"
              className="text-uppercase"
            >
              Remarks<span style={{ color: "red" }}>*</span>
            </Label>
            <InputGroup className="input-group-alternative shadow-none border">
              <Input
                placeholder="Remarks"
                type="textarea"
                name="remarks"
                onChange={(e) => handleOnChange(e)}
                value={bookingFormData.remarks}
                invalid={!!errors && !!errors.remarks}
              />
            </InputGroup>
            <FormFeedback className={errors?.remarks && "invalid-field"}>
              {errors?.remarks}
            </FormFeedback>
          </FormGroup>
          <br></br>
          <p className="font-weight-normal" style={{ fontSize: "12px" }}>
            You can click 'Schedule Interview' to set up an interview or
            'Submit' to directly submit a booking request.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={onScheduleInterview} color="primary">
            Schedule Interview
          </Button>
          <Button size="sm" onClick={onSubmit} color="primary">
            Submit
          </Button>
        </ModalFooter>
      </Modal>

      {interviewModal && (
        <AddNewSlot
          //  tab={tab}
          onSubmitInterview={onSubmitInterview}
          data={interviewFormData}
          interviewModal={interviewModal}
          closeInterview={closeInterview}
          errors={errors}
          disablePastDate={disablePastDate}
        />
      )}
    </div>
  );
};

export default EmployeeModal;
