import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Label,
  Input,
  ModalFooter,
  FormFeedback,
} from "reactstrap";
import history from "helpers/History";
import { Rating } from "react-simple-star-rating";
import moment from "moment";
import { baseURL } from "api/Request";
import downloadIcn from "../../assets/img/icons/customIcons/download-svg.svg";
import { useDispatch, useSelector } from "react-redux";
import { axiosAccountInstance } from "api/Request";
import { toast } from "react-toastify";
import { getAllBookingRequestInfo } from "redux/actions/admin/AllBookingRequestAction";
import { fetchUserData } from "helpers/storageData";
import AddNewSlot from "views/examples/users/employee-search-and-sending-booking-requests/Add-Slots";
import { setEmployeeListInfo } from "redux/actions/super-admin/EmployeeListAction";
import { getAllRequestInfo } from "redux/actions/admin/AllRequestAction";
import downloadResumeIcon from "../../assets/img/icons/common/download.png";
import uploadIcon from "../../assets/img/icons/common/upload.png";
import SOWUploadModal from "./SOWUploadModal";
import { getResumeRequests } from "redux/actions/admin/ResumeRequestsAction";
import { uniqueDate, activityDateFormat } from "helpers/getDate";
import { act } from "react";

const BookingModal = ({
  modal,
  close,
  data,
  isEmployee,
  deleteHandler,
  isInbound,
  onApprove,
  rejectHandler,
}) => {
  console.log("isInbound", isInbound);
  const onClose = () => {
    close();
    setResumeModal(false);
    setCancelModal(false);
    setSelectedSlot(null);
    setChangeSlotModal(false);
    setChangeSlotRequestReason("");
    setRejectModal(false);
    setSlotModal(false);
    setRejectCandidateModal(false);
    setInterviewRejectReason("");
    setConfirmedData("");
    setIsButtonDisabled(false);
    setConfirmSlotError('')
  };
  const dispatch = useDispatch();
  const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(true);
  const [approveFormData, setApproveFormData] = useState({
    employeeId: null,
    resume: null,
  });
  const [resumeModal, setResumeModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [changeSlotModal, setChangeSlotModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [changeSlotRequestReason, setChangeSlotRequestReason] = useState("");
  const [interviewRejectReason, setInterviewRejectReason] = useState("");
  const [rejectSlotModal, setRejectSlotModal] = useState(false);
  const [rejectCandidateModal, setRejectCandidateModal] = useState(false);
  const [slotModal, setSlotModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); //multiple click disabled
  const [confirmedData, setConfirmedData] = useState("");
  const user = localStorage.getItem("user");
  const [userData, setUserData] = useState({});
  const [confirmSlotError, setConfirmSlotError] = useState("");
  useEffect(() => {
    setUserData(JSON.parse(user));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const handleOnChange = (e) => {
    setChangeSlotRequestReason(e.target.value);
    if (e.target.value) {
      setIsButtonDisabled(false);
    }
    setErrors({ ...errors, changeSlotRequestReason: null });
  };
  const handleDelete = (id) => {
    console.log("data", data);
    deleteHandler(id);
    close();
  };

  const handleResume = () => {
    close();
    setIsApproveButtonDisabled(false);
    setResumeModal(true);
    // setResumeData(data)
  };
  const imageSelect = (image) => {
    console.log("img", image);

    const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB maximum size limit
    if (image.size > MAX_IMAGE_SIZE) {
      setErrors({
        ...errors,
        resume: `File size exceeds the maximum limit of ${
          MAX_IMAGE_SIZE / (1024 * 1024)
        }MB`,
      });
      setApproveFormData({
        ...approveFormData,
        resume: null,
      });
      setIsApproveButtonDisabled(true);
    } else {
      console.log("inelse");
      setErrors({ ...errors, resume: null });
      setApproveFormData({
        ...approveFormData,
        resume: image,
      });
      setIsApproveButtonDisabled(false);
    }
  };
  const submitResume = (data) => {
    axiosAccountInstance
      .post("/resume-upload", data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          socketInstance.emit("message", {
            type: "RESUME UPLOADED",
            key: res.data.body.data?.employee?.empId,
            user: fetchUserData()?.companyId,
            extraInfo: fetchUserData()?.companyName,
          });
          toast.success(res.data.body.message);
          dispatch(setEmployeeListInfo("listing"));
          setResumeModal(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.body.message);
      });
  };

  const onResumeUpload = async (e) => {
    setIsApproveButtonDisabled(true);

    const finalData = approveFormData;

    if (!finalData?.resume) {
      setErrors({ ...errors, resume: "Resume is required" });
    } else {
      const newdata = new FormData();
      // console.log("idd/........./",employeeID)
      newdata.append("employeeId", data.employeeId);
      newdata.append("resume", finalData?.resume);
      console.log("my fnl Dataaaaaaaa ", newdata);
      submitResume(newdata);
    }
  };

  const [interviewFormData, setInterviewFormData] = useState({
    isInterview: "",
    slots: [],
    interviewRemarks: "",
    durationFromDate: "",
  });
  useEffect(() => {
    if (data?.duration) {
      setInterviewFormData((prevState) => ({
        ...prevState,
        durationFromDate: data?.duration?.fromDate,
      }));
    }
  }, [data]);
  console.log("dur...........................", data.selectedSlot);
  const onSubmitInterview = async (slotData, remark) => {
    if (true) {
      const finalFormData = {
        _id: data._id,
        bookingId: data.bookingId,
        status: "accepted",
        slot: slotData,
        interviewRemarks: remark,
      };
      axiosAccountInstance
        .post("/slot_action", finalFormData)
        .then((res) => {
          toast.success(res.data.body.message);
          history.push("/admin/booking-details");
          setInterviewFormData({
            date: "",
            duration: "",
            slots: [],
            interviewRemarks: "",
          });

          socketInstance.emit("message", {
            type: "ACCEPT_SLOT_CHANGE_REQUEST",

            key: data?.bookingId,
            extraInfo: fetchUserData()?.companyName,
          });
          // dispatch(setEmployeeListInfo("booking", finalFormData));
          dispatch(getAllRequestInfo("1", "1", null, ""));
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });

      setSlotModal(false);
    }
  };
  const handleOnReject = (e) => {
    setInterviewRejectReason(e.target.value);
    // if (e.target.value) {
    //   setIsButtonDisabled(false);
    // }
    // setErrors({ ...errors, interviewRejectReason: null });
  };

  const handleRadioChange = (date, time) => {
    setConfirmSlotError("");

    setSelectedSlot((prevSelectedSlot) => {
      const newSelectedSlot =
        prevSelectedSlot?.date === date && prevSelectedSlot?.time === time
          ? null
          : { date, time };
      setConfirmedData(newSelectedSlot);
      return newSelectedSlot;
    });
  };
  const handleSlotConfirm = () => {
    setConfirmSlotError("");
    if (confirmedData === "") {
      setConfirmSlotError("Please select a slot");
    } else {
      close();
      const finalData = {
        employeeId: data.employeeId,
        bookingId: data.bookingId,
        slot: confirmedData,
      };
      submitSlot(finalData);
    }
  };

  const href = data?.resume
    ? `${process.env.REACT_APP_API_URL}${data?.resume}`
    : "#";

  const submitSlot = (data) => {
    {
      axiosAccountInstance
        .post("/confirm_slot", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            socketInstance.emit("message", {
              type: "CREATE_CONFIRM_SLOT_REQUEST",
              // subType: "UPDATE_APPROVED_BOOKING_LIST",
              key: data?.bookingId,
              extraInfo: fetchUserData()?.companyName,
            });
            toast.success(res.data.body.message);

            dispatch(getAllBookingRequestInfo("0", "1", null, ""));

            // setChangeSlotModal(false);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
    }
  };
  const formatDateRange = (fromDate, toDate) => {
    const formattedDate = uniqueDate(fromDate);
    const fromTime = moment(fromDate).format("h:mm A");
    const toTime = moment(toDate).format("h:mm A");
    return `${formattedDate}, ${fromTime} - ${toTime}`;
  };

  const handleSlotChange = () => {
    console.log("modal", modal);
    close();
    setChangeSlotModal(true);
  };

  const handleRejectInterview = () => {
    close();
    setRejectModal(true);
  };

  const handleCancelInterview = () => {
    close();
    console.log("modal", modal);
    setCancelModal(true);
  };

  const handleRejectSlotChange = () => {
    close();
    console.log("modal", modal);
    setRejectSlotModal(true);
  };
  const handleAcceptSlotChange = () => {
    close();
    console.log("modal", modal);
    setSlotModal(true);
  };

  const disablePastDate = () => {
    const today = new Date();

    if (today.getHours() < 12) {
      return new Date(today.getFullYear(), today.getMonth(), today.getDate());
    } else {
      return new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
      );
    }
  };
  const acceptCandidate = () => {
    close();
    setIsButtonDisabled(true);
    const finalData = {
      _id: data._id,
      status: "accepted",
      bookingId: data.bookingId,
    };
    setCandidateApproval(finalData);
  };

  const rejectCandidate = () => {
    close();
    setRejectCandidateModal(true);
    // setIsButtonDisabled(true);
    // const finalData = {
    //   _id: data._id,
    //   status: "rejected",
    //   rejectReason:interviewRejectReason

    // };
    // setCandidateRejection(finalData);
  };
  const onSlotChange = () => {
    setIsButtonDisabled(true);
    const finalData = {
      employeeId: data.employeeId,
      // status: "rejected",
      bookingId: data.bookingId,
      changeSlotRemark: changeSlotRequestReason,
    };
    submitRequest(finalData);
  };
  const onReject = () => {
    setIsButtonDisabled(true);
    const finalData = {
      employeeId: data.employeeId,
      // status: "rejected",
      bookingId: data.bookingId,
      interviewRejectReason,
    };
    rejectRequest(finalData);
  };
  const onCancel = () => {
    setIsButtonDisabled(true);
    const finalData = {
      employeeId: data.employeeId,
      // status: "rejected",
      bookingId: data.bookingId,
      reason: interviewRejectReason,
    };
    cancelRequest(finalData);
  };

  const onRejectSlotChange = () => {
    setIsButtonDisabled(true);
    const finalData = {
      employeeId: data.employeeId,
      // status: "rejected",
      _id: data._id,
      bookingId: data.bookingId,
      rejectReason: interviewRejectReason,
      status: "rejected",
    };
    slotChangeRequestAction(finalData);
  };
  const slotValidation = (data) => {
    const errorsFound = {};
    if (!selectedSlot) {
      errorsFound["selectedSlot"] = "Slot is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };
  const validation = (data) => {
    const errorsFound = {};
    // console.log(rejectRequestReason);

    if (!changeSlotRequestReason || changeSlotRequestReason === "") {
      errorsFound["changeSlotRequestReason"] =
        "Slot Change Request Reason is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };
  const rejectValidation = (data) => {
    const errorsFound = {};

    if (!interviewRejectReason || interviewRejectReason === "") {
      errorsFound["interviewRejectReason"] = "Reason is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };
  const rejectRequest = (data) => {
    // const isValid = rejectValidation(data);
    // if(isValid){
    axiosAccountInstance
      .post("/reject_interview", data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          socketInstance.emit("message", {
            type: "UPDATE_REJECT_INTERVIEW",
            // subType: "UPDATE_APPROVED_BOOKING_LIST",
            key: data?.bookingId,
            extraInfo: fetchUserData()?.companyName,
          });
          toast.success(res.data.body.message);

          dispatch(getAllBookingRequestInfo("0", "1", null, ""));

          setRejectModal(false);
          setInterviewRejectReason("");
        }
      })
      .catch((err) => {
        toast.error(err.response.data.body.message);
      });

    // }
  };
  let currentDate = new Date();
  const cancelRequest = (data) => {
    const isValid = rejectValidation(data);
    if (isValid) {
      axiosAccountInstance
        .post("/cancel_interview", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            socketInstance.emit("message", {
              type: "CANCEL_INTERVIEW_REQUEST",

              key: data?.bookingId,
              userId: userData.userId,
              extraInfo: fetchUserData()?.companyName,
            });
            toast.success(res.data.body.message);

            setCancelModal(false);
            setInterviewRejectReason("");

            if (res.data?.body?.data?.isInbound) {
              dispatch(getAllBookingRequestInfo("0", "1", null, ""));
            } else {
              dispatch(getAllRequestInfo("1", "1", null, ""));
            }
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
    }
  };
  const slotChangeRequestAction = (data) => {
    const isValid = rejectValidation(data);
    if (isValid) {
      axiosAccountInstance
        .post("/slot_action", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            socketInstance.emit("message", {
              type: "REJECT_SLOT_CHANGE_REQUEST",
              // subType: "UPDATE_APPROVED_BOOKING_LIST",
              key: data?.bookingId,
              userId: userData.userId,
              extraInfo: fetchUserData()?.companyName,
            });
            toast.success(res.data.body.message);

            dispatch(getAllRequestInfo("1", "1", null, ""));

            setRejectSlotModal(false);
            setInterviewRejectReason("");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
    }
  };

  const submitRequest = (data) => {
    const isValid = validation(data);
    setCompanyApproval(isValid, data);
  };

  const socketInstance = useSelector((state) => state.socketReducer.result);
  const setCompanyApproval = (isValid = true, data) => {
    if (isValid) {
      axiosAccountInstance
        .post("/slot_change", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            socketInstance.emit("message", {
              type: "CREATE_SLOT_CHANGE_REQUEST",

              key: data?.bookingId,
              extraInfo: fetchUserData()?.companyName,
            });
            toast.success(res.data.body.message);

            dispatch(getAllBookingRequestInfo("0", "1", null, ""));

            setChangeSlotModal(false);
            setChangeSlotRequestReason("");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
    }
  };
  const setCandidateApproval = (data) => {
    axiosAccountInstance
      .post("/candidate_status", data)
      .then((res) => {
        if (res.data.statusCode === 200) {
          socketInstance.emit("message", {
            type: "ACCEPT_CANDIDATE",

            key: data?.bookingId,
            extraInfo: fetchUserData()?.companyName,
          });
          toast.success(res.data.body.message);

          dispatch(getAllRequestInfo("1", "1", null, ""));

          // setChangeSlotModal(false);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.body.message);
      });
    // setChangeSlotRequestReason("");
  };
  const setCandidateRejection = () => {
    const isValid = rejectValidation(data);
    if (isValid) {
      data.status = "rejected";
      data.rejectReason = interviewRejectReason;
      axiosAccountInstance
        .post("/candidate_status", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            socketInstance.emit("message", {
              type: "REJECT_CANDIDATE",

              key: data?.bookingId,
              extraInfo: fetchUserData()?.companyName,
            });
            toast.success(res.data.body.message);

            dispatch(getAllRequestInfo("1", "1", null, ""));

            setRejectCandidateModal(false);
            setInterviewRejectReason("");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
    }
  };
  const timeLimit = moment(data?.duration?.fromDate)
    .subtract(1, "day")
    .set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
  const showChangeSlot = moment().isBefore(timeLimit);
  return (
    <div>
      {!isEmployee ? (
        <>
          <Modal
            toggle={onClose}
            scrollable
            isOpen={modal}
            className="bookingmodal rightside-modal"
          >
            <Row>
              <div className="col">
                <ModalHeader className="text-uppercase font-weight-bolder pb-2">
                  <h2>Employee Details</h2>
                </ModalHeader>
              </div>
              <Row>
                <Col>
                  <ModalHeader className="text-uppercase pt-3 pb-2">
                    <Button onClick={onClose} className="mx-1" close />
                  </ModalHeader>
                </Col>
              </Row>
            </Row>
            <ModalBody>
              <FormGroup row className="employee-det-row mb-0 px-2">
                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Employee ID </h4>
                    </div>
                    <div className="values">
                      <p>{data?.empId}</p>
                    </div>
                  </div>
                </Col>
                {(isInbound === true || data?.sowStatus === "approved") && (
                  <Col sm={6} className="mb-2 px-1">
                    <div className="first-row">
                      <div className="labels">
                        <h4>Employee Name </h4>
                      </div>
                      <div className="values text-capitalize">
                        <p>{data?.name}</p>
                      </div>
                    </div>
                  </Col>
                )}

                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Designation </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.designation}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Hourly Payment </h4>
                    </div>
                    <div className="values">
                      <p>
                        {data?.currencyCode === "INR" && "₹"}
                        {data?.currencyCode === "EUR" && "€"}
                        {data?.currencyCode === "USD" && "$"}
                        {data?.hourlyPayment}
                      </p>
                    </div>
                  </div>
                </Col>
                {data?.booking?.resume && (
                  <Col sm={6} className="mb-2 px-1">
                    <div className="first-row">
                      <div className="labels">
                        <h4>Resume </h4>
                      </div>
                      {data?.booking?.resume && (
                        <div className="values text-capitalize">
                          <a
                            href={`${process.env.REACT_APP_API_URL}${data?.booking?.resume}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            style={{ color: "#525F7F", fontSize: "14px" }}
                          >
                            <img
                              src={downloadResumeIcon}
                              width={"21"}
                              alt=""
                            ></img>
                            Download
                          </a>
                        </div>
                      )}
                    </div>
                  </Col>
                )}
                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Skills </h4>
                    </div>
                    <div className="values">
                      {data?.skillSets?.map((skill, key) => {
                        return (
                          // <p>{`${skill?.domain} : ${skill?.numberOfYears} years ${skill.numberOfMonths} months`}</p>
                          <div className="skill-values justify-content-between">
                            <div className="d-flex">
                              <span className="skillname">{skill?.domain}</span>
                              <span className="expname">
                                {skill?.numberOfYears +
                                  "." +
                                  skill?.numberOfMonths}{" "}
                                years
                              </span>
                            </div>
                            {/* <span className="rating-sc">{skill?.rating}</span> */}
                            <Rating
                              className="rating-area"
                              initialValue={skill?.rating}
                              name="rating"
                              readonly="true"
                              // onClick={(e) => handleOnChange(e, keyIndex, "rating")}
                              // onPointerEnter={onPointerEnter}
                              // onPointerLeave={onPointerLeave}
                              // onPointerMove={onPointerMove}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>
                {data.interviewRemarks && (
                  <Col sm={12}>
                    <h2 className="mt-4">Interview Details</h2>
                    <hr className="mt-1 mb-3" />
                  </Col>
                )}
                {/* {data.interviewRemarks && (
                  <Col sm={6} className="mb-2 px-1">
                    <div className="first-row">
                      <div className="labels">
                        <h4>Interview Requested By </h4>
                      </div>
                      <div className="values text-capitalize">
                        <p>{data?.bookedCompany}</p>
                      </div>
                    </div>
                  </Col>
                )}
                {data.interviewRemarks && (
                  <Col sm={6} className="mb-2 px-1">
                    <div className="first-row">
                      <div className="labels">
                        <h4>Interview Requested To </h4>
                      </div>
                      <div className="values text-capitalize">
                        <p>{data?.parentCompany}</p>
                      </div>
                    </div>
                  </Col>
                )} */}

                {!data.selectedSlot &&
                data.interviewRemarks &&
                data?.slots?.length > 0 &&
                userData.companyId == data.empCreatedCompanyId && //inbound
                data?.status == "Interview Requested" ? (
                  <Col sm={12} className="mb-2 px-1">
                    <div className="first-row">
                      <div className="labels">
                        <h4>Suggested Slots </h4>
                      </div>
                      <div className="values">
                        {data?.slots?.map((slot, key) => (
                          <FormGroup key={key} check>
                            <div className="skill-values justify-content-between">
                              <div className="date-container">
                                <span className="date">
                                  {uniqueDate(slot?.date)}
                                </span>
                                <div className="time-options col-md-12">
                                  {slot?.time?.length > 0 &&
                                    slot?.time?.map((item, timeIndex) => {
                                      const slotDate = moment(slot.date).format(
                                        "YYYY-MM-DD"
                                      );
                                      const startTime = item.split(" - ")[0];
                                      const slotDateTime = moment(
                                        `${slotDate} ${startTime}`,
                                        "YYYY-MM-DD hh:mm A"
                                      );

                                      const isExpired = slotDateTime.isBefore(
                                        moment()
                                      );
                                      return (
                                        <Label
                                          key={timeIndex}
                                          className="time-option"
                                        >
                                          <Input
                                            type="radio"
                                            name="slot"
                                            value={item}
                                            checked={
                                              selectedSlot?.date ===
                                                slot.date &&
                                              selectedSlot?.time === item
                                            }
                                            disabled={isExpired} // Disable if the slot is expired
                                            onChange={() =>
                                              handleRadioChange(
                                                slot?.date,
                                                item
                                              )
                                            }
                                          />
                                          <div className="time-details">
                                            <span className="time">{item}</span>
                                          </div>
                                        </Label>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </FormGroup>
                        ))}
                        {confirmSlotError ? (
                          <div className="validation-error">
                            {confirmSlotError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="buttons mt-3">
                        <Button
                          size="sm"
                          color="primary"
                          onClick={handleSlotConfirm} //if all slots expired then disable confirm slot
                          className="ml-2"
                          disabled={
                            !data?.slots?.some((slot) =>
                              slot?.time?.some((item) => {
                                const slotDate = moment(slot.date).format(
                                  "YYYY-MM-DD"
                                );
                                const startTime = item.split(" - ")[0];
                                const slotDateTime = moment(
                                  `${slotDate} ${startTime}`,
                                  "YYYY-MM-DD hh:mm A"
                                );
                                return !slotDateTime.isBefore(moment()); // Check if any slot is not expired
                              })
                            )
                          }
                        >
                          Confirm Slot
                        </Button>

                        <Button
                          size="sm"
                          color="primary"
                          onClick={handleSlotChange}
                          disabled={!showChangeSlot} //change slot allowed only till 12pm of previous day of booking
                        >
                          Change Slot
                        </Button>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={handleRejectInterview}
                          className="ml-2"
                        >
                          Reject Interview
                        </Button>
                      </div>
                    </div>
                  </Col>
                ) : (
                  !data.selectedSlot &&
                  data.interviewRemarks &&
                  data?.slots?.length > 0 && (
                    <Col sm={12} className="mb-2 px-1">
                      <div className="first-row">
                        <div className="labels">
                          <h4>Suggested Slots </h4>
                        </div>

                        <div className="values">
                          {data?.slots?.map((slot, key) => (
                            <FormGroup key={key} check>
                              <div className="skill-values justify-content-between">
                                {/* <div className="d-flex"> */}
                                {/* <span className="skillname"> */}
                                <div className="date-container">
                                  <span className="date">
                                    {uniqueDate(slot?.date)}
                                  </span>
                                  <div className="time-options">
                                    {slot?.time?.length > 0 &&
                                      slot?.time?.map((item, timeIndex) => (
                                        // <Label
                                        //   key={timeIndex}
                                        //   className="time-option"
                                        // >
                                        //   <Input
                                        //     type="radio"
                                        //     name="slot"
                                        //     checked={selectedSlot === item}
                                        //     onChange={() =>
                                        //       handleRadioChange(slot?.date,item)
                                        //     }
                                        //   />
                                        <div className="time-details">
                                          <span className="time">{item}</span>
                                        </div>
                                        // </Label>
                                      ))}
                                  </div>
                                  {/* {<span className="expname">{slot?.time}</span>} */}
                                </div>
                              </div>
                              {/* </Label> */}
                            </FormGroup>
                          ))}
                        </div>
                      </div>
                    </Col>
                  )
                )}
                {data.selectedSlot && (
                  <Col className={"mb-2 px-1"}>
                    <div className="first-row">
                      <div className="labels">
                        <h4>Confirmed Slot</h4>
                      </div>
                      <div className="values text-capitalize">
                        <p>
                          {formatDateRange(
                            data?.selectedSlot?.fromDate,
                            data?.selectedSlot?.toDate
                          )}
                        </p>
                      </div>
                    </div>
                  </Col>
                )}
                {data.interviewRemarks && (
                  <Col
                    sm={data?.sowStatus === "approved" ? 6 : 12}
                    className={"mb-2 px-1"}
                  >
                    <div className="first-row">
                      <div className="labels">
                        <h4>Status</h4>
                      </div>
                      <div className="values text-capitalize">
                        <p>{data?.status}</p>
                      </div>
                    </div>
                  </Col>
                )}
                {data.interviewRemarks && (
                  <Col sm={12} className={"mb-2 px-1"}>
                    <div className="first-row">
                      <div className="labels">
                        <h4>Remarks</h4>
                      </div>
                      <div className="values text-capitalize">
                        <p>{data?.interviewRemarks}</p>
                      </div>
                    </div>
                  </Col>
                )}
                {data?.status === "Interview Scheduled" &&
                  userData.role != "super_admin" && (
                    <div>
                      {new Date(data?.selectedSlot.fromDate) >= currentDate && (
                        <Button
                          size="sm"
                          color="primary"
                          onClick={handleCancelInterview}
                          className="ml-2"
                        >
                          Cancel Interview
                        </Button>
                      )}

                      {new Date(data?.selectedSlot.fromDate) <= currentDate &&
                        userData.companyId != data.empCreatedCompanyId && (
                          <>
                            <Button
                              size="sm"
                              color="primary"
                              onClick={acceptCandidate}
                              className="ml-2"
                            >
                              Accept Candidate
                            </Button>
                            <Button
                              size="sm"
                              color="primary"
                              onClick={rejectCandidate}
                              className="ml-2"
                            >
                              Reject Candidate
                            </Button>
                          </>
                        )}
                    </div>
                  )}
                {userData.role != "super_admin" &&
                  data?.status === "Interview Requested" &&
                  userData.companyId != data.empCreatedCompanyId && (
                    <div>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => handleDelete(data?._id)}
                        className="ml-2"
                      >
                        Delete Interview
                      </Button>
                    </div>
                  )}
                {userData.role != "super_admin" &&
                  data?.status === "Slot Change Requested" &&
                  userData.companyId != data.empCreatedCompanyId && (
                    <div>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={handleAcceptSlotChange}
                        className="ml-2"
                      >
                        Accept Slot Change
                      </Button>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={handleRejectSlotChange}
                        className="ml-2"
                      >
                        Reject Slot Change
                      </Button>
                    </div>
                  )}
                {userData.role == "super_admin" && data?.interviewLink && (
                  <Col sm={12} className={"mb-2 px-1"}>
                    <div className="first-row">
                      <div className="labels">
                        <h4>Interview Link</h4>
                      </div>
                      <div className="values text-capitalize">
                        <a
                          href={data?.interviewLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <p>{data?.interviewLink}</p>
                        </a>
                      </div>
                    </div>
                  </Col>
                )}
                <Col sm={12}>
                  <h2 className="mt-4">Booking Details</h2>
                  <hr className="mt-1 mb-3" />
                </Col>
                {/* <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Booking Requested By </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.bookedCompany}</p>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Booking Requested To </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.parentCompany}</p>
                    </div>
                  </div>
                </Col> */}

                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>From </h4>
                    </div>
                    <div className="values">
                      <p>{uniqueDate(data?.duration?.fromDate)}</p>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>To </h4>
                    </div>
                    <div className="values">
                      <p>{uniqueDate(data?.duration?.toDate)}</p>
                    </div>
                  </div>
                </Col>
                {/* {data?.bookingExtentionsDetails && (
              <Col sm={6}>
                <div className="first-row">
                  <div className="labels">
                    <h4>Extensions requested </h4>
                  </div>
                  <div className="values">
                    {data?.bookingExtentionsDetails?.length > 0 ? (
                      data?.bookingExtentionsDetails?.map((extention, key) => {
                        return (
                          <p key={key}>{`${key + 1} : ${moment(
                            extention?.duration?.fromDate
                          ).format("DD-MM-YYYY")} - ${moment(
                            extention?.duration?.fromDate
                          ).format("DD-MM-YYYY")}`}</p>
                        );
                      })
                    ) : (
                      <p> No extensions requested</p>
                    )}
                  </div>
                </div>
              </Col>
            )} */}

                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Working Days </h4>
                    </div>
                    <div className="values">
                      <p>{data?.workingDays}</p>
                    </div>
                  </div>
                </Col>
                <Col sm={6} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Working Hours Per Day</h4>
                    </div>
                    <div className="values">
                      <p>{data?.workingHoursPerDay}</p>
                    </div>
                  </div>
                </Col>

                {!(
                  data?.status?.includes("Interview") ||
                  data?.status?.includes("Slot")
                ) && (
                  <Col
                    sm={data?.sowStatus === "approved" ? 6 : 12}
                    className={"mb-2 px-1"}
                  >
                    <div className="first-row">
                      <div className="labels">
                        <h4>Status</h4>
                      </div>
                      <div className="values text-capitalize">
                        <p>{data?.status}</p>
                      </div>
                    </div>
                  </Col>
                )}

                {data?.sowStatus === "approved" ? (
                  <Col sm={6} className="mb-2 px-1">
                    <div className="first-row">
                      <div className="labels">
                        <h4>SOW</h4>
                      </div>
                      <div className="values text-capitalize">
                        <a
                          href={baseURL + "/" + data?.sowDocument}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <img src={downloadIcn} width={"21"} alt=""></img>
                        </a>
                      </div>
                    </div>
                  </Col>
                ) : null}

                {/* <Col sm={6} className="mb-2 px-1">
              <div className="first-row">
                <div className="labels">
                  <h4>SOW Status </h4>
                </div>
                <div className="values text-capitalize">
                  <p>
                    {data?.sow_final_status
                      ? data?.sow_final_status
                      : "Pending"}
                    <span>
                      {data?.sowStatus === "approved" && (
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a
                          href={baseURL + "/" + data?.sowDocument}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <img src={downloadIcn} width={"21"} alt=""></img>
                        </a>
                      )}
                    </span>
                  </p>
                </div>
              </div>
            </Col> */}
                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Remarks </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.remarks}</p>
                    </div>
                  </div>
                </Col>

                {data?.status === "pending" && isInbound ? (
                  <Col sm={12} className="mb-1 px-1 mt-3">
                    <Button
                      color="primary"
                      onClick={() => onApprove(data?._id)}
                      size="sm"
                    >
                      Approve
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => rejectHandler(data?._id)}
                      size="sm"
                    >
                      Reject
                    </Button>
                  </Col>
                ) : (
                  ""
                )}
                <Col sm={12} className="pt-2 px-1">
                  <h2 className="mt-4">Activity</h2>
                  <hr className="mt-1 mb-3" />
                </Col>
                {data?.activity && (
                  <Col sm={12} className="mb-2 px-1">
                    <div className="first-row-2">
                      <div className="labels">
                        <h4>Action </h4>
                      </div>
                      <div className="values">
                        <div className="activity-timeline">
                          {data?.activity?.length > 0 ? (
                            data?.activity?.map((act, key) => {
                              return (
                                <>
                                  <div className="activityboxes">
                                    <p className="action-title">
                                      {act?.action == "Interview scheduled"
                                        ? `${
                                            "Interview scheduled for " +
                                            formatDateRange(
                                              data?.selectedSlot?.fromDate,
                                              data?.selectedSlot?.toDate
                                            )
                                          }`
                                        : act?.action}
                                    </p>
                                    <label key={key}>
                                      {" "}
                                      {act?.action == "Interview scheduled"
                                        ? "Interview approved"
                                        : act?.action}
                                      {act?.fromDate && act?.toDate
                                        ? " from " +
                                          uniqueDate(act.fromDate) +
                                          " to " +
                                          uniqueDate(act.toDate)
                                        : ""}
                                      {act?.rejectReason || act?.reason
                                        ? ` with reason "${
                                            act.rejectReason || act?.reason
                                          }"`
                                        : ""}{" "}
                                      on {activityDateFormat(act?.time)}
                                    </label>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <p></p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                )}
              </FormGroup>
            </ModalBody>
          </Modal>
          <Modal toggle={close} centered isOpen={changeSlotModal}>
            <Row>
              <div className="col">
                <ModalHeader className="text-uppercase font-weight-bolder">
                  Change Slot Request
                </ModalHeader>
              </div>
              <Row>
                <Col>
                  <ModalHeader className="text-uppercase pt-3 pb-2">
                    <Button onClick={onClose} className="mx-1" close />
                  </ModalHeader>
                </Col>
              </Row>
            </Row>
            <ModalBody>
              <label className="font-weight-normal">
                Do you want to change the slot?
              </label>
              <FormGroup row>
                <Label for="exampleText" sm={12}>
                  Remarks
                  <span style={{ color: "red" }}>*</span>
                </Label>
                <Col sm={12}>
                  <Input
                    id="exampleText"
                    name="text"
                    type="textarea"
                    onChange={handleOnChange}
                    value={changeSlotRequestReason}
                    invalid={!!errors && !!errors.changeSlotRequestReason}
                  />
                  <FormFeedback
                    className={
                      errors?.changeSlotRequestReason && "invalid-field"
                    }
                  >
                    {errors?.changeSlotRequestReason}
                  </FormFeedback>
                </Col>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                onClick={() => onSlotChange()}
                color="primary"
                disabled={isButtonDisabled}
              >
                Submit
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Modal toggle={close} centered isOpen={rejectModal}>
            <Row>
              <div className="col">
                <ModalHeader className="text-uppercase font-weight-bolder">
                  Reject Interview Request
                </ModalHeader>
              </div>
              <Row>
                <Col>
                  <ModalHeader className="text-uppercase pt-3 pb-2">
                    <Button onClick={onClose} className="mx-1" close />
                  </ModalHeader>
                </Col>
              </Row>
            </Row>
            <ModalBody>
              <label className="font-weight-normal">
                Do you want to reject the interview?
              </label>
              <FormGroup row>
                <Label for="exampleText" sm={12}>
                  Reason
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Label>
                <Col sm={12}>
                  <Input
                    id="exampleText"
                    name="text"
                    type="textarea"
                    onChange={handleOnReject}
                    value={interviewRejectReason}
                    invalid={!!errors && !!errors.interviewRejectReason}
                  />
                  <FormFeedback
                    className={errors?.interviewRejectReason && "invalid-field"}
                  >
                    {errors?.interviewRejectReason}
                  </FormFeedback>
                </Col>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                onClick={() => onReject()}
                color="primary"
                disabled={isButtonDisabled}
              >
                Submit
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Modal toggle={close} centered isOpen={cancelModal}>
            <Row>
              <div className="col">
                <ModalHeader className="text-uppercase font-weight-bolder">
                  Cancel Interview Request
                </ModalHeader>
              </div>
              <Row>
                <Col>
                  <ModalHeader className="text-uppercase pt-3 pb-2">
                    <Button onClick={onClose} className="mx-1" close />
                  </ModalHeader>
                </Col>
              </Row>
            </Row>
            <ModalBody>
              <label className="font-weight-normal">
                Do you want to cancel the interview?
              </label>
              <FormGroup row>
                <Label for="exampleText" sm={12}>
                  Reason
                  <span style={{ color: "red" }}>*</span>
                </Label>
                <Col sm={12}>
                  <Input
                    id="exampleText"
                    name="text"
                    type="textarea"
                    onChange={handleOnReject}
                    value={interviewRejectReason}
                    invalid={!!errors && !!errors.interviewRejectReason}
                  />
                  <FormFeedback
                    className={errors?.interviewRejectReason && "invalid-field"}
                  >
                    {errors?.interviewRejectReason}
                  </FormFeedback>
                </Col>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                onClick={() => onCancel()}
                color="primary"
                disabled={isButtonDisabled}
              >
                Submit
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Modal toggle={close} centered isOpen={rejectSlotModal}>
            <Row>
              <div className="col">
                <ModalHeader className="text-uppercase font-weight-bolder">
                  Reject Slot Change Request
                </ModalHeader>
              </div>
              <Row>
                <Col>
                  <ModalHeader className="text-uppercase pt-3 pb-2">
                    <Button onClick={onClose} className="mx-1" close />
                  </ModalHeader>
                </Col>
              </Row>
            </Row>
            <ModalBody>
              <label className="font-weight-normal">
                Do you want to cancel the interview?
              </label>
              <FormGroup row>
                <Label for="exampleText" sm={12}>
                  Reason
                  <span style={{ color: "red" }}>*</span>
                </Label>
                <Col sm={12}>
                  <Input
                    id="exampleText"
                    name="text"
                    type="textarea"
                    onChange={handleOnReject}
                    value={interviewRejectReason}
                    invalid={!!errors && !!errors.interviewRejectReason}
                  />
                  <FormFeedback
                    className={errors?.interviewRejectReason && "invalid-field"}
                  >
                    {errors?.interviewRejectReason}
                  </FormFeedback>
                </Col>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                onClick={() => onRejectSlotChange()}
                color="primary"
                disabled={isButtonDisabled}
              >
                Submit
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <Modal toggle={close} centered isOpen={rejectCandidateModal}>
            <Row>
              <div className="col">
                <ModalHeader className="text-uppercase font-weight-bolder">
                  Reject Candidate
                </ModalHeader>
              </div>
              <Row>
                <Col>
                  <ModalHeader className="text-uppercase pt-3 pb-2">
                    <Button onClick={onClose} className="mx-1" close />
                  </ModalHeader>
                </Col>
              </Row>
            </Row>
            <ModalBody>
              <label className="font-weight-normal">
                Do you want to reject the candidate?
              </label>
              <FormGroup row>
                <Label for="exampleText" sm={12}>
                  Reason
                  <span style={{ color: "red" }}>*</span>
                </Label>
                <Col sm={12}>
                  <Input
                    id="exampleText"
                    name="text"
                    type="textarea"
                    onChange={handleOnReject}
                    value={interviewRejectReason}
                    invalid={!!errors && !!errors.interviewRejectReason}
                  />
                  <FormFeedback
                    className={errors?.interviewRejectReason && "invalid-field"}
                  >
                    {errors?.interviewRejectReason}
                  </FormFeedback>
                </Col>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                onClick={() => setCandidateRejection()}
                color="primary"
                disabled={isButtonDisabled}
              >
                Submit
              </Button>{" "}
            </ModalFooter>
          </Modal>
          <AddNewSlot
            interviewModal={slotModal}
            data={interviewFormData}
            disablePastDate={disablePastDate}
            closeInterview={onClose}
            errors={errors}
            onSubmitInterview={onSubmitInterview}
          ></AddNewSlot>
        </>
      ) : (
        <>
          <Modal
            toggle={close}
            scrollable
            isOpen={modal}
            className="bookingmodal rightside-modal"
          >
            <Row>
              <div className="col">
                <ModalHeader className="text-uppercase font-weight-bolder pb-2">
                  <h2>Employee Details</h2>
                </ModalHeader>
              </div>
              <Row>
                <Col>
                  <ModalHeader className="text-uppercase pt-3 pb-2">
                    <Button onClick={onClose} className="mx-1" close />
                  </ModalHeader>
                </Col>
              </Row>
            </Row>
            <ModalBody>
              <FormGroup row className="employee-det-row mb-0 px-2">
                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Employee ID </h4>
                    </div>
                    <div className="values">
                      <p>{data?.empId}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Employee Name </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.name}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Designation </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.designation}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Contact Number </h4>
                    </div>
                    <div className="values text-capitalize">
                      <p>{data?.mobile ? data?.mobile : "--"}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Email ID </h4>
                    </div>
                    <div className="values ">
                      <p>{data?.email}</p>
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Hourly Payment </h4>
                    </div>
                    <div className="values">
                      <p>
                        {data?.currencyCode === "INR" && "₹"}
                        {data?.currencyCode === "EUR" && "€"}
                        {data?.currencyCode === "USD" && "$"}
                        {data?.hourlyPayment}
                      </p>
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Skills </h4>
                    </div>
                    <div className="values">
                      {data?.skillSets?.map((skill, key) => {
                        return (
                          // <p>{`${skill?.domain} : ${skill?.numberOfYears} years ${skill.numberOfMonths} months`}</p>
                          <div className="skill-values justify-content-between">
                            <div className="d-flex">
                              <span className="skillname">{skill?.domain}</span>
                              <span className="expname">
                                {skill?.numberOfYears +
                                  "." +
                                  skill?.numberOfMonths}{" "}
                                years
                              </span>
                            </div>
                            {/* <span className="rating-sc">{skill?.rating}</span> */}
                            <Rating
                              className="rating-area"
                              initialValue={skill?.rating}
                              name="rating"
                              readonly="true"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Projects </h4>
                    </div>
                    <div className="values">
                      {data?.projects?.map((project, key) => {
                        return (
                          <div className="skill-values justify-content-between">
                            <div>
                              <span className="skillname">{project?.name}</span>
                              <span className="expname">
                                {" "}
                                <strong>Description:</strong>{" "}
                                {project?.description}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>

                <Col sm={12} className="mb-2 px-1">
                  <div className="first-row">
                    <div className="labels">
                      <h4>Resume </h4>
                    </div>
                    {data?.resume ? (
                      <div className="values text-capitalize">
                        <a
                          href={href}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                          style={{ color: "#525F7F", fontSize: "14px" }}
                        >
                          <img
                            src={downloadResumeIcon}
                            width={"21"}
                            alt=""
                          ></img>
                          Download
                        </a>
                      </div>
                    ) : (
                      <span
                        style={{ cursor: "pointer", fontSize: "14px" }}
                        onClick={() => handleResume(data)}
                      >
                        <img
                          alt=""
                          src={uploadIcon}
                          style={{ marginTop: -3, marginRight: 4, width: 16 }}
                        />
                        Upload
                      </span>
                    )}
                  </div>
                </Col>
              </FormGroup>
            </ModalBody>
          </Modal>
          <SOWUploadModal
            isBooking={true}
            sowModal={resumeModal}
            close={onClose}
            onApproveSOW={onResumeUpload}
            isApproveButtonDisabled={isApproveButtonDisabled}
            errors={errors}
            imageSelect={imageSelect}
            sowData={data}
          />
        </>
      )}
    </div>
  );
};

export default BookingModal;
