import React, { useEffect, useState } from "react";
import { format, isValid } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
  Form,
  Spinner,
  Tooltip,
} from "reactstrap";
import { setSuggestedSlots } from "redux/actions/admin/SuggestedSlotsAction";
import { Chip } from "@mui/material";
import moment from "moment";

function AddNewSlot({
  data,
  interviewModal,
  closeInterview,
  onSubmitInterview,
  disablePastDate,
}) {
  const dispatch = useDispatch();
  const [interviewData, setInterviewData] = useState([
    {
      id: Date.now().toString(),
      date: undefined,
      duration: "",
      slot: [],
      time: [],
    },
  ]);
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (id) => {
    setTooltipOpen((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const [loadingStates, setLoadingStates] = useState({});

  const [remark, setRemark] = useState("");
  const loader = useSelector((state) => state.SuggestedSlotsReducer?.loader);
  const slotsState = useSelector((state) => state.SuggestedSlotsReducer.result);
  const [errors, setErrors] = useState({});
  const [slotId, setSlotId] = useState();
  const [isDeleteButton, setIsDeleteButton] = useState(false);

  const toggleLoading = (id, isLoading) => {
    //toggle

    setLoadingStates((prevState) => ({
      ...prevState,
      [id]: isLoading,
    }));
  };

  useEffect(() => {
    // console.log("slot id :",slotId)
    toggleLoading(slotId, loader);
    // This will run every time the `loader` value changes\
    console.log("Updated loader value:", loader);
    console.log("loadingstate:", loadingStates);
  }, [loader]);

  const getTime = async (e, id, name) => {
    let filterData = [];
    let date;
    let duration;
    if (name === "date") {
      filterData = interviewData.filter(
        (item) => item?.id == id && item?.duration !== ""
      );
      date = e;
      duration = filterData[0]?.duration;
    } else if (name === "duration") {
      filterData = interviewData.filter(
        (item) => item?.id == id && item?.date !== ""
      );
      duration = e.target.value;
      date = filterData[0]?.date;
    }
    if (filterData?.length > 0) {
      // toggleLoading(id, true);
      const slotData = { date, duration, employeeId: data?.employeeId };
      try {
        await dispatch(setSuggestedSlots(slotData));
      } catch (error) {
        console.error("Error fetching slots:", error);
      }
    }
  };

  const handleOnChange = (e, id, name) => {
    if (name === "remark") {
      setRemark(e.target.value);
      validateField("remark_1", name, e.target.value);
    } else {
      let value;
      if (name === "date") {
        value = e;
      } else {
        value = e.target.value;
      }
      // console.log("eee",e)
      // const { value } = e.target;
      const updatedInterviewData = interviewData.map((item) =>
        item.id === id ? { ...item, [name]: value } : item
      );
      // Check for duplicate dates
      if (name === "date") {
        const selectedDate = new Date(value);
        const dayOfWeek = selectedDate.getDay();

        // if (dayOfWeek === 0) {
        //   // 0 represents Sunday in JavaScript's Date object
        //   setErrors((prevErrors) => ({
        //     ...prevErrors,
        //     [id]: {
        //       ...prevErrors[id],
        //       [name]: "Sunday is not allowed",
        //     },
        //   }));
        //   return;
        // }

        const isDuplicate = updatedInterviewData.some(
          (item) => item.date === value && item.id !== id
        );
        if (isDuplicate) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: {
              ...prevErrors[id],
              [name]: "Already used date",
            },
          }));
          return;
        }
      }

      setSlotId(id);
      getTime(e, id, name);
      setInterviewData(updatedInterviewData);
      validateField(id, name, value);
    }
  };
  const { durationFromDate } = data;

  const validateField = (id, name, value) => {
    const errorMessages = {
      date: "Date is required",
      duration: "Duration is required",
      time: "At least one slot is required",
      remark: "Remark is required",
      dateRange: "Select date before booking",
      dateUnique: "Already selected date",
      // weekend:"Sunday is not allowed"
    };

    let dateError = "";
    if (name === "date" && value) {
      const selectedDate = new Date(value);
      const bookingEndDate = new Date(durationFromDate);
      selectedDate.setHours(0, 0, 0, 0);
      bookingEndDate.setHours(0, 0, 0, 0);

      if (selectedDate >= bookingEndDate) {
        console.log("check");
        dateError = errorMessages.dateRange;
      } else {
        const isDuplicate = interviewData.some(
          (item) => item.date === value && item.id !== id
        );
        dateError = isDuplicate ? errorMessages.dateUnique : "";
      }
    } else {
      dateError = value ? "" : errorMessages[name];
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: {
        ...prevErrors[id],
        [name]: dateError,
      },
      remark: name === "remark" && value ? "" : prevErrors.remark, // Clear remark error if valid
    }));
  };

  const checkError = (id, name) => {
    if (id == "remark_1") {
      return errors[name] ? true : false;
    }

    return errors[id] && errors[id][name] ? true : false;
  };

  const validateForm = () => {
    const newErrors = {};
    const dateSet = new Set();
    interviewData.forEach((item) => {
      const itemErrors = {};

      // Check date uniqueness
      if (dateSet.has(item.date)) {
        itemErrors.date = "Already selected date";
      } else {
        dateSet.add(item.date);
      }

      if (!item.date) {
        itemErrors.date = "Date is required";
      } else {
        const selectedDate = new Date(item.date);
        const to = new Date(durationFromDate);
        const dayOfWeek = selectedDate.getDate();

        if (selectedDate >= to) {
          itemErrors.date = "Select date before booking";
        }
        // if(dayOfWeek===0){
        //   itemErrors.date="Sunday is not allowed"
        // }
      }
      if (!item.duration) itemErrors.duration = "Duration is required";
      if (item.time.length === 0)
        itemErrors.time = "At least one slot is required";
      if (Object.keys(itemErrors).length > 0) {
        newErrors[item.id] = itemErrors;
      }
    });

    if (!remark) {
      newErrors.remark_1 = { remark: "Remark is required" };
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmitInterview(interviewData, remark);
      console.log("Form submitted", interviewData);
      closeInterview();
    } else {
      console.log("Form has errors");
    }
  };

  const addSlot = () => {
    setIsDeleteButton(true);
    setInterviewData([
      ...interviewData,
      {
        id: Date.now().toString(),
        date: undefined,
        duration: "",
        slot: [],
        time: [],
      },
    ]);
  };

  const deleteSlot = (id) => {
    setInterviewData(interviewData.filter((item) => item.id !== id));
  };
  const handleChipClick = (formData, item) => {
    // const updatedTime = [...(formData.time || []), item];
    const updatedTime = formData.time.includes(item)
      ? formData.time.filter((time) => time !== item) // Deselect the slot if it's already selected
      : [...formData.time, item];
    const updatedData = { ...formData, time: updatedTime };

    const updatedInterviewData = interviewData.map((item) => {
      if (item.id === formData.id) {
        return updatedData;
      } else {
        return item;
      }
    });
    setInterviewData(updatedInterviewData);
    console.log("up..", updatedInterviewData);
    validateField(formData.id, "time", updatedTime);
  };
  const shouldDisableDate = (date) => {
    // Ensure the date is valid before further processing
    // if (!isValid(date)) {
    //   console.error("Invalid date detected:", date);
    //   return true; // Disable invalid dates
    // }

    // Disable Sundays
    if (date.getDay() === 0) {
      return true;
    }

    // Format the date to ensure consistent comparison
    const formattedDate = format(date, "yyyy-MM-dd");

    // Check if the date is already used in other interview data
    const isDateUsed =
      interviewData.some(
        (item) =>
          item.date &&
          format(new Date(item.date), "yyyy-MM-dd") === formattedDate
      ) || false;

    // Disable if date is already selected
    return isDateUsed;
  };

  useEffect(() => {
    // setLoading(slotsState.loader);
    const updateInterviewData = interviewData.map((item) => {
      if (item.id === slotId) {
        return { ...item, slot: slotsState?.availableSlots };
      } else {
        return item;
      }
    });
    // setLoading(false);
    setInterviewData(updateInterviewData);
  }, [slotsState]);

  return (
    <Modal style={{ maxWidth: "900px" }} centered isOpen={interviewModal}>
      <ModalHeader
        toggle={closeInterview}
        className="text-uppercase font-weight-bolder"
      >
        Schedule Interview
      </ModalHeader>
      <ModalBody>
        <p className="font-weight-normal">Please select slots</p>
        <Form onSubmit={handleSubmit}>
          {interviewData.map((formData, index) => (
            <Row key={formData.id} className="justify-content-center">
              <Col md="12">
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label
                        style={{ fontSize: "10px", marginBottom: "4px" }}
                        className="text-uppercase"
                      >
                        Date<span style={{ color: "red" }}>*</span>
                      </Label>
                      {/* <InputGroup className="input-group-alternative shadow-none border"> */}
                      {/* <Input
                          placeholder="Date"
                          type="date"
                          name="date"
                          value={formData.date}
                          min={disablePastDate()}
                          onChange={(e) =>
                            handleOnChange(e, formData.id, "date")
                          }
                          invalid={checkError(formData.id, "date")}
                        /> */}

                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          dateForm="MM/DD/YYYY"
                          inputFormat="dd/MM/yyyy"
                          placeholder="MM/DD/YYYY"
                          value={formData.date || null}
                          closeOnSelect={true}
                          sx={{ padding: "0px", textTransform: "uppercase" }}
                          shouldDisableDate={(date) => {
                            if (date.getDay() === 0) {
                              return true;
                            }
                            // console.log(
                            //   moment(interviewData[0]?.date).format(
                            //     "DD/MM/YYYY"
                            //   ) === moment(date).format("DD/MM/YYYY")
                            // );
                            return interviewData.some(
                              (item) =>
                                moment(item.date).format("DD/MM/YYYY") ===
                                  moment(date).format("DD/MM/YYYY") &&
                                formData.id !== item.id
                            );
                          }}
                          slotProps={{
                            textField: {
                              size: "small",
                              sx: {
                                "& .MuiInputBase-root": {
                                  height: "32px",
                                  fontSize: "0.8rem",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "4px 1px",
                                  fontSize: "0.8rem",
                                },
                              },
                              inputProps: {
                                placeholder: "MM/DD/YY", // ✅ Proper way to set placeholder
                              },
                            },
                          }}
                          minDate={disablePastDate()}
                          onChange={(e) =>
                            handleOnChange(e, formData.id, "date")
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={false}
                              helperText=""
                              fullWidth
                              placeholder="MM/DD/YYYY"
                              InputProps={{
                                ...params.InputProps,
                                style: { textTransform: "uppercase" },
                              }}
                              sx={{
                                padding: "5px",
                                "& .MuiOutlinedInput-input": {
                                  padding: "9px 7px", // Adjust inner padding
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>

                      {/* </InputGroup> */}
                      <FormFeedback
                        className={
                          errors &&
                          !!errors[formData.id]?.date &&
                          "invalid-field"
                        }
                      >
                        {errors[formData.id]?.date}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label
                        style={{ fontSize: "10px" }}
                        className="text-uppercase"
                      >
                        Duration<span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        placeholder="Duration"
                        type="select"
                        name="duration"
                        value={formData.duration}
                        onChange={(e) =>
                          handleOnChange(e, formData.id, "duration")
                        }
                        invalid={checkError(formData.id, "duration")}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="30">30 Minutes</option>
                        <option value="60">1 Hour</option>
                      </Input>
                      <FormFeedback
                        className={
                          errors &&
                          !!errors[formData.id]?.duration &&
                          "invalid-field"
                        }
                      >
                        {errors[formData.id]?.duration}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col
                    className="col-auto "
                    style={{ paddingBottom: "10px" }}
                  >
                    <label> &nbsp; </label>
                    <div className="button-area project">
                      <div className="text-center">
                        {index !== 0 && (
                          <>
                            <Button
                              size="sm"
                              color="danger"
                              outline
                              onClick={() => deleteSlot(formData.id)}
                              id={`Tooltip_${formData?.id}_delete`}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </Button>
                            <Tooltip
                              placement="top"
                              isOpen={tooltipOpen[formData?.id]} // Boolean to control visibility
                              target={`Tooltip_${formData?.id}_delete`} // Matches the button's ID
                              toggle={() => toggleTooltip(formData?.id)} // Toggle function
                            >
                              Delete
                            </Tooltip>
                          </>
                        )}
                        {index === 0 && interviewData.length === 1 && (
                          <>
                            <Button
                              size="sm"
                              color="primary"
                              id={`Tooltip_${formData?.id}_add`}
                              outline
                              onClick={addSlot}
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </Button>
                            {/* <Tooltip
                              placement="top"
                              isOpen={tooltipOpen[formData?.id]} // Boolean to control visibility
                              target={`Tooltip_${formData?.id}_add`} // Matches the button's ID
                              toggle={() => toggleTooltip(formData?.id)} // Toggle function
                            >
                              Add
                            </Tooltip> */}
                          </>
                        )}
                        {index === interviewData.length - 1 &&
                          interviewData.length > 1 && (
                            <>
                              <Button
                                size="sm"
                                color="primary"
                                id={`Tooltip_${formData?.id}_add`}
                                outline
                                onClick={addSlot}
                              >
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                              </Button>
                              {/* <Tooltip
                              placement="top"
                              isOpen={tooltipOpen[formData?.id]} // Boolean to control visibility
                              target={`Tooltip_${formData?.id}_add`} // Matches the button's ID
                              toggle={() => toggleTooltip(formData?.id)} // Toggle function
                            >
                              Add
                            </Tooltip> */}
                            </>
                          )}
                      </div>
                    </div>
                  </Col>
                  {console.log("loadingsta....", loadingStates[formData.id])}
                  {formData.date && formData.duration && (
                    <Col sm="12">
                      <FormGroup>
                        <Label
                          style={{ fontSize: "10px" }}
                          for="fromdate"
                          className="text-uppercase"
                        >
                          Slots<span style={{ color: "red" }}>*</span>
                        </Label>

                        <div
                          style={{ position: "relative" }}
                          className="chips-container"
                        >
                          {loadingStates[formData.id] ? (
                            <div style={{ position: "absolute", left: "50%" }}>
                              <Spinner size={"large"} />
                            </div>
                          ) : (
                            formData?.slot?.map((item, i) => (
                              <Chip
                                style={{ width: "130px", fontSize: "11px" }}
                                key={i}
                                label={item}
                                clickable
                                color={
                                  formData?.time?.includes(item)
                                    ? "primary"
                                    : "default"
                                }
                                onClick={() => handleChipClick(formData, item)}
                                className="slot-chip"
                              />
                            ))
                          )}
                        </div>
                        {errors && !!errors[formData.id]?.time && (
                          <FormFeedback className="invalid-field">
                            {errors[formData.id]?.time}
                          </FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          ))}
          <Row>
            <Col md="4">
              <FormGroup>
                <Label style={{ fontSize: "10px" }} className="text-uppercase">
                  Remark<span style={{ color: "red" }}>*</span>
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <Input
                    placeholder="Remark"
                    type="text"
                    name="remark"
                    value={remark}
                    onChange={(e) => handleOnChange(e, "remark_1", "remark")}
                    invalid={checkError("remark_1", "remark")}
                  />
                </InputGroup>
                <FormFeedback
                  className={
                    errors && !!errors.remark_1?.remark && "invalid-field"
                  }
                >
                  {errors.remark_1?.remark}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <Button type="submit" color="primary">
            Submit
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default AddNewSlot;
