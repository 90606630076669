import BookingModal from "components/Modals/BookingModal";
import React, { useEffect, useState } from "react";
import viewIcn from "../../../../../assets/img/icons/customIcons/view.svg";

import { useSelector } from "react-redux";

import Tables from "views/examples/Tables";

function RejectedListCard({ loader }) {
  const [, setData] = useState([]);
  const [bookingData, setBookingData] = useState({});
  const [bookingModal, setBookingModal] = useState(false);
  const fetchResultRejectedListRequests = useSelector(
    (state) => state.RejectedRecentBookingReducer.result
  );

  useEffect(() => {
    setData(fetchResultRejectedListRequests?.booking || []);
  }, [fetchResultRejectedListRequests]);

  const viewHandler = (dataObj) => {
    setBookingData(dataObj);
    setBookingModal(true);
  };
  const close = () => {
    setBookingModal(false);
  };

  // const rejectedDate = moment(item.rejectedDate).format("DD-MM-YYYY");
  const colums = [
    // { columnName: "Company", columnTag: "bookedCompany", type: "data" },
    { columnName: "Employee ID", columnTag: "empId", type: "data" },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Date Range", columnTag: "duration", type: "objectData" },
    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },
    // { columnName: "Currency", columnTag: "currencyCode", type: "data" },
    { columnName: "Working Days", columnTag: "workingDays", type: "data" },
    { columnName: "Status", columnTag: "status", type: "data" },
    { columnName: "Remark", columnTag: "rejectReason", type: "data" },
    { columnName: "", columnTag: "", type: "" },
  ];

  const actionbuttons = [
    {
      columnName: "",
      btnTitle: "View",
      btnTooltip: "View",
      type: "actionButton",
      btn: "ViewBooking",
      btnType: "primary",
      actionFunction: viewHandler,
      btnIcon: viewIcn,
    },
  ];
  return (
    <div>
      <Tables
        coloums={colums}
        data={fetchResultRejectedListRequests?.booking}
        actionButtons={actionbuttons}
        loader={loader}
      />

      <BookingModal modal={bookingModal} close={close} data={bookingData} />
    </div>
  );
}

export default RejectedListCard;
