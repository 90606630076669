/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import EmployeeList from "views/examples/admin/employees/EmployeeList";
import BookingHistoryList from "views/examples/admin/employees/BookingHistoryList";
import AddContainer from "views/examples/admin/employees/addEmployeeForm/AddContainer";
import AddContainerManager from "views/examples/admin/managers/addManagerForm/AddContainer";
import MainContainer from "views/examples/admin/company/MainContainer";

import ResumeList from "views/examples/ResumeList";
import AdminTabMainCotainer from "views/examples/users/AdminTabMainCotainer";
import EmployeeSearchList from "views/examples/users/employee-search-and-sending-booking-requests/EmployeeSearchList";
import ForgotPassword from "views/examples/ForgotPassword";
import UpdatePassword from "views/examples/UpdatePassword";
import CompanyCointainer from "views/examples/admin/company/CompanyCointainer";
import Profile from "views/examples/Profile";
import LoginUpdatePassword from "views/examples/LoginUpdatePassword";
import CompanyMainContainer from "views/examples/admin/company/CompanyMainContainer";
import ManagerList from "views/examples/admin/managers/ManagerList";
import SuperAdminIndex from "views/SuperAdminIndex";
import Verify from "views/examples/Verify";
import InterviewContainer from "views/examples/admin/interview/InterviewContainer";
import LandingSearch from "views/examples/LandingSearch";

export const managerRoutes = [
  {
    isValid: true,
    mainPath: "/search",
    path: "/search",
    name: "Search",
    icon: "fa fa-search text-primary",
    component: LandingSearch,
    layout: "/admin",
  },
  {
    isValid: true,
    mainPath: "/index",
    path: "/index",
    name: "Dashboard",
    icon: "fa fa-desktop text-primary",
    component: Index,
    layout: "/admin",
  },

  {
    isValid: true,
    mainPath: "/list-employees",
    path: "/list-employees",
    name: "Employees",
    icon: "fa fa-users text-purple",
    component: EmployeeList,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/add-employees",
    path: "/add-employees",
    name: "Add Employees",
    icon: "fa fa-plus-square-o text-primary",
    component: AddContainer,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/edit-employees",
    path: "/edit-employees/:employeeId",
    name: "Edit Employees",
    icon: "ni ni-circle-08 text-pink",
    component: AddContainer,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/booking-history",
    path: "/booking-history/:employeeId",
    name: "Booking History",
    icon: "ni ni-circle-08 text-pink",
    component: BookingHistoryList,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: true,
    mainPath: "/booking-employee",
    path: "/booking-employee",
    name: "Book Employees",
    icon: "fa fa-address-card text-yellow",
    component: EmployeeSearchList,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: true,
    mainPath: "/booking-requests",
    path: "/booking-requests",
    name: "Inbound Bookings",
    icon: "fa fa-list text-info",
    component: MainContainer,
    exact: true,
    layout: "/admin",
  },

  {
    isValid: true,
    mainPath: "/booking-details",
    path: "/booking-details",
    name: "Outbound Bookings",
    icon: "fa fa-info text-red",
    component: AdminTabMainCotainer,
    exact: true,
    layout: "/admin",
  },

  {
    isValid: false,
    mainPath: "/user-profile",
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-circle-08 text-pink",
    component: Profile,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/login-update-password",
    path: "/login-update-password",
    name: "Update Password",
    icon: "ni ni-circle-08 text-pink",
    component: LoginUpdatePassword,
    exact: true,
    layout: "/admin",
  },
];
export const adminRoutes = [
  {
    isValid: true,
    mainPath: "/search",
    path: "/search",
    name: "Search",
    icon: "fa fa-search text-primary",
    component: LandingSearch,
    layout: "/admin",
  },
  {
    isValid: true,
    mainPath: "/index",
    path: "/index",
    name: "Dashboard",
    icon: "fa fa-desktop text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    isValid: true,
    mainPath: "/list-managers",
    path: "/list-managers",
    name: "Managers",
    icon: "fa fa-users text-orange",
    component: ManagerList,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/add-manager",
    path: "/add-manager",
    name: "Add Manager",
    icon: "fa fa-plus-square-o text-blue",
    component: AddContainerManager,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/edit-manager",
    path: "/edit-manager/:employeeId",
    name: "Edit Manager",
    icon: "ni ni-circle-08 text-pink",
    component: AddContainerManager,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: true,
    mainPath: "/list-employees",
    path: "/list-employees",
    name: "Employees",
    icon: "fa fa-users text-purple",
    component: EmployeeList,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/add-employees",
    path: "/add-employees",
    name: "Add Employees",
    icon: "fa fa-plus-square-o text-yellow",
    component: AddContainer,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/edit-employees",
    path: "/edit-employees/:employeeId",
    name: "Edit Employees",
    icon: "ni ni-circle-08 text-pink",
    component: AddContainer,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/booking-history",
    path: "/booking-history/:employeeId",
    name: "Booking History",
    icon: "ni ni-circle-08 text-pink",
    component: BookingHistoryList,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: true,
    mainPath: "/booking-employee",
    path: "/booking-employee",
    name: "Book Employees",
    icon: "fa fa-address-card text-yellow",
    component: EmployeeSearchList,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: true,
    mainPath: "/booking-requests",
    path: "/booking-requests",
    // name: "Booking Requests",
    name: "Inbound Bookings",
    icon: "fa fa-list text-info",
    component: MainContainer,
    exact: true,
    layout: "/admin",
  },

  {
    isValid: true,
    mainPath: "/booking-details",
    path: "/booking-details",
    // name: "My Bookings",
    name: "Outbound Bookings",
    icon: "fa fa-info text-red",
    component: AdminTabMainCotainer,
    exact: true,
    layout: "/admin",
  },

  {
    isValid: false,
    mainPath: "/user-profile",
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-circle-08 text-pink",
    component: Profile,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/login-update-password",
    path: "/login-update-password",
    name: "Update Password",
    icon: "ni ni-circle-08 text-pink",
    component: LoginUpdatePassword,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/resumes",
    path: "/resumes",
    name: "Resume Requests",
    icon: "ni ni-circle-08 text-pink",
    component: ResumeList,
    exact: true,
    layout: "/admin",
  },
];
export const superAdminRoutes = [
  {
    isValid: true,
    mainPath: "/index",
    path: "/index",
    name: "Dashboard",
    icon: "fa fa-desktop text-primary",
    component: SuperAdminIndex,
    layout: "/admin",
  },

  {
    isValid: true,
    mainPath: "/companies",
    path: "/companies",
    name: "Companies",
    icon: "fa fa-building text-orange",
    component: CompanyCointainer,
    exact: true,
    layout: "/admin",
  },
  // {
  //   isValid: true,
  //   mainPath: "/list-employees",
  //   path: "/list-employees",
  //   name: "Employees",
  //   icon: "fa fa-users",
  //   component: EmployeeList,
  //   exact: true,
  //   layout: "/admin",
  // },
  {
    isValid: false,
    mainPath: "/add-employees",
    path: "/add-employees",
    name: "Add Employees",
    icon: "ni ni-circle-08 text-pink",
    component: AddContainer,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/edit-employees",
    path: "/edit-employees/:employeeId",
    name: "Edit Employees",
    icon: "ni ni-circle-08 text-pink",
    component: AddContainer,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/booking-history",
    path: "/booking-history/:employeeId",
    name: "Booking History",
    icon: "ni ni-circle-08 text-pink",
    component: BookingHistoryList,
    exact: true,
    layout: "/admin",
  },
  // {
  //   isValid: true,
  //   mainPath: "/booking-employee",
  //   path: "/booking-employee",
  //   name: "Book employees",
  //   icon: "fa fa-address-card",
  //   component: EmployeeSearchList,
  //   exact: true,
  //   layout: "/admin",
  // },

  // {
  //   isValid: true,
  //   mainPath: "/booking-requests",
  //   path: "/booking-requests",
  //   name: "Booking requests",
  //   icon: "fa fa-list",
  //   component: MainContainer,
  //   exact: true,
  //   layout: "/admin",
  // },

  // {
  //   isValid: true,
  //   mainPath: "/booking-details",
  //   path: "/booking-details",
  //   name: "Booking details",
  //   icon: "fa fa-info",
  //   component: AdminTabMainCotainer,
  //   exact: true,
  //   layout: "/admin",
  // },
  {
    isValid: false,
    mainPath: "/user-profile",
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-circle-08 text-pink",
    component: Profile,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/login-update-password",
    path: "/login-update-password",
    name: "Update Password",
    icon: "ni ni-circle-08 text-pink",
    component: LoginUpdatePassword,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: false,
    mainPath: "/company",
    path: "/company/:companyId",
    name: "Company History",
    icon: "ni ni-circle-08 text-pink",
    component: CompanyMainContainer,
    exact: true,
    layout: "/admin",
  },
  {
    isValid: true,
    mainPath: "/interviews",
    path: "/interviews",
    name: "Interviews",
    icon: "fa fa-users text-pink",
    component: InterviewContainer,
    exact: true,
    layout: "/admin",
  },
];

export const authRoutes = [
  {
    isValid: false,
    mainPath: "/login",
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    exact: true,
    layout: "/auth",
  },
  {
    isValid: false,
    msinPath: "/register",
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    exact: true,
    layout: "/auth",
  },
  {
    isValid: false,
    mainPath: "/forgot-password",
    path: "/forgot-password",
    name: "Forgot Password",
    icon: "ni ni-circle-08 text-pink",
    component: ForgotPassword,
    exact: true,
    layout: "/auth",
  },
  {
    isValid: false,
    mainPath: "/resetpassword/:token",
    path: "/resetpassword/:token",
    name: "Update Password",
    icon: "ni ni-circle-08 text-pink",
    component: UpdatePassword,
    exact: true,
    layout: "/auth",
  },
  {
    isValid: false,
    mainPath: "/verify/:token",
    path: "/verify/:token",
    name: "Verify email",
    icon: "ni ni-circle-08 text-pink",
    component: Verify,
    exact: true,
    layout: "/auth",
  },
];
