import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import viewIcn from "../../../../../assets/img/icons/customIcons/view.svg";
import deleteIcn from "../../../../../assets/img/icons/customIcons/delete.svg";

import { setPendingRequestDeleteInfo } from "redux/actions/admin/PendingRequestAction";
import Tables from "views/examples/Tables";
import BookingModal from "components/Modals/BookingModal";
function PendingListCard({ loader, fromSuperAdmin }) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [, setData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [bookingData, setBookingData] = useState({});
  const [bookingModal, setBookingModal] = useState(false);

  const fetchResultPendingListRequests = useSelector(
    (state) => state.PendingRecentBookingReducer.result
  );

  useEffect(() => {
    setData(fetchResultPendingListRequests?.booking || []);
  }, [fetchResultPendingListRequests]);

  const deleteHandler = (id) => {
    setDeleteId(id);
    setModal(!modal);
  };
  const close = () => {
    setModal(false);
    setBookingModal(false);
  };
  const viewHandler = (dataObj) => {
    setBookingData(dataObj);
    setBookingModal(true);
  };
  const submit = () => {
    dispatch(setPendingRequestDeleteInfo(deleteId));
    setModal(false);
  };
  const colums = [
    // { columnName: "Company", columnTag: "bookedCompany", type: "data" },
    { columnName: "Employee ID", columnTag: "empId", type: "data" },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Date Range", columnTag: "duration", type: "objectData" },
    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },
    // { columnName: "Currency", columnTag: "currencyCode", type: "data" },
    { columnName: "Working Days", columnTag: "workingDays", type: "data" },
    { columnName: "Status", columnTag: "status", type: "data" },
    { columnName: "", columnTag: "", type: " " },
  ];
  const actionbuttons = !fromSuperAdmin
    ? [
        {
          columnName: "",
          btnTitle: "View",
          btnTooltip: "View",
          type: "actionButton",
          btn: "ViewBooking",
          btnType: "primary",
          btnIcon: viewIcn,
          actionFunction: viewHandler,
        },
        {
          columnName: "",
          btnTitle: "Delete",
          btnTooltip: "Delete",
          type: "actionButton",
          btn: "PendingBookingDelete",
          btnIcon: deleteIcn,
          tooltip: "Delete",
          btnType: "danger",
          actionFunction: deleteHandler,
        },
      ]
    : [
        {
          columnName: "",
          btnTitle: "View",
          btnTooltip: "View",
          type: "actionButton",
          btn: "ViewBooking",
          btnType: "primary",
          btnIcon: viewIcn,
          actionFunction: viewHandler,
        },
      ];
  return (
    <div>
      <Tables
        coloums={colums}
        data={fetchResultPendingListRequests?.booking}
        actionButtons={actionbuttons}
        loader={loader}
      />

      <Modal toggle={close} size="sm" centered isOpen={modal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              {" "}
              Delete Request
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <label className="font-weight-normal">
            Do you want to delete booking?
          </label>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={submit} color="danger">
            Delete
          </Button>{" "}
          {/* <Button size="sm" onClick={close}>
                  Cancel
                </Button> */}
        </ModalFooter>
      </Modal>
      <BookingModal modal={bookingModal} close={close} data={bookingData} />
    </div>
  );
}

export default PendingListCard;
