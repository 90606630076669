const PendingCountRequestReducer = (
  state = { loader: false, result: [], error: null },
  action
) => {
  switch (action.type) {
    case "PENDING_COUNT_REQUEST_LIST_BEGINS":
      return {
        loader: true,
        result: {},
        error: null,
      };
    case "PENDING_COUNT_REQUEST_LIST_SUCCESS":
      return {
        loader: false,
        result: action.payload,
        error: null,
      };
    case "PENDING_COUNT_REQUEST_LIST_FAILURE":
      return {
        loader: false,
        result: {},
        error: action.payload,
      };
    case "PENDING_COUNT_REQUEST_LIST_RESET":
      return {
        loader: false,
        result: {},
        error: null,
      };
    case "OUTBOUND_PENDING_LIST_COUNT_UPDATE":
      return {
        ...state,
        result: {
          ...state.result,
          outbound_bookings: state.result.outbound_bookings.map((booking, index) => 
            index === 0 ? { ...booking, pending_requests: action.payload } : booking
          ),
        },
      };
   
    case "OUTBOUND_PENDING_EXTENSION_LIST_COUNT_UPDATE":
      // state.result.outbound_bookings[2].pending_extension_request =
      //   action.payload;
      // console.log(state);
      // return {
      //   ...state,
      // };
      return {
        ...state,
        result: {
          ...state.result,
          outbound_bookings: state.result.outbound_bookings.map((booking, index) => 
            index === 2 ? { ...booking, pending_extension_request: action.payload } : booking
          ),
        },
      };

    case "OUTBOUND_PENDING_PRECLOSURE_LIST_COUNT_UPDATE":
      return {
        ...state,
        result: {
          ...state.result,
          outbound_bookings: state.result.outbound_bookings.map((booking, index) => 
            index === 3 ? { ...booking, pending_pre_closure_request: action.payload } : booking
          ),
        },
      };
      case "INBOUND_PENDING_LIST_COUNT_UPDATE":
        return {
          ...state,
          result: {
            ...state.result,
            inbound_bookings: state.result.inbound_bookings.map((booking, index) => 
              index === 0 ? { ...booking, pending_requests: action.payload } : booking
            ),
          },
        };
  
      case "INBOUND_PENDING_EXTENSION_LIST_COUNT_UPDATE":
        
        return {
          ...state,
          result: {
            ...state.result,
            inbound_bookings: state.result.inbound_bookings.map((booking, index) => 
              index === 2 ? { ...booking, pending_extension_request: action.payload } : booking
            ),
          },
        };
  
        case "INBOUND_PENDING_PRECLOSURE_LIST_COUNT_UPDATE":
          return {
            ...state,
            result: {
              ...state.result,
              inbound_bookings: state.result.inbound_bookings.map((booking, index) => 
                index === 3 ? { ...booking, pending_pre_closure_request: action.payload } : booking
              ),
            },
          };
   
    default:
      return state;
  }
};

export default PendingCountRequestReducer;
