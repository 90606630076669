import React from "react";
import {
  Button,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input,
  Row,
  Col,
  Label,
  FormFeedback,
  // Tooltip
} from "reactstrap";

function AddProjects(props) {
  const { handleOnChange, deleteProject, errorsData, projects, addProject } =
    props;
  // const [isHover, setIsHover] = useState(false);
  // console.log(isHover, "asifhnaaa");
  // const toggle = () => {
  //   setIsHover(!isHover);
  // };

  const errors = errorsData?.projectError;

  return (
    <>
      {projects &&
        projects.map((formData, keyIndex) => (
          <Row className="justify-content-center" key={keyIndex}>
            <Col md="12">
              <Row>
                <Col md="4">
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="employeeName"
                      className="text-uppercase"
                    >
                      Project Name<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i class="fas fa-tasks"></i>
                        </InputGroupText>
                      </InputGroupAddon>

                      <Input
                        placeholder="Name of the project"
                        type="text"
                        name="name"
                        onChange={(e) =>
                          handleOnChange(e, keyIndex, "projectName")
                        }
                        value={formData.name}
                        invalid={
                          errors &&
                          !!errors[keyIndex] &&
                          !!errors[keyIndex].projectName
                        }
                      />
                    </InputGroup>
                    <FormFeedback
                      className={
                        errors &&
                        !!errors[keyIndex]?.projectName &&
                        "invalid-field"
                      }
                    >
                      {errors && errors[keyIndex]?.projectName}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="fromdate"
                      className="text-uppercase"
                    >
                      role<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i class="fas fa-user-tag"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Role in the project"
                        type="text"
                        name="role"
                        value={formData.role}
                        onChange={(e) =>
                          handleOnChange(e, keyIndex, "proectRole")
                        }
                        invalid={
                          errors &&
                          !!errors[keyIndex] &&
                          !!errors[keyIndex].proectRole
                        }
                      />
                    </InputGroup>
                    <FormFeedback
                      className={
                        errors &&
                        !!errors[keyIndex]?.proectRole &&
                        "invalid-field"
                      }
                    >
                      {errors && errors[keyIndex]?.proectRole}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="fromdate"
                      className="text-uppercase"
                    >
                      Duration in months<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i class="fas fa-hourglass-half"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        min={1}
                        placeholder="Duration of the project"
                        type="number"
                        name="duration"
                        onKeyPress={(e) => {
                          if (e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        value={formData.duration}
                        onChange={(e) =>
                          handleOnChange(e, keyIndex, "projectDuration")
                        }
                        invalid={
                          errors &&
                          !!errors[keyIndex] &&
                          !!errors[keyIndex].projectDuration
                        }
                      />
                    </InputGroup>
                    <FormFeedback
                      className={
                        errors &&
                        !!errors[keyIndex]?.projectDuration &&
                        "invalid-field"
                      }
                    >
                      {errors && errors[keyIndex]?.projectDuration}
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col className="w-100 col">
                  <FormGroup>
                    <Label
                      style={{ fontSize: "10px" }}
                      for="fromdate"
                      className="text-uppercase"
                    >
                      Description<span style={{ color: "red" }}>*</span>
                    </Label>
                    <InputGroup className="input-group-alternative shadow-none border">
                      <Input
                        placeholder="Description of the project"
                        type="textarea"
                        name="description"
                        value={formData.description}
                        onChange={(e) =>
                          handleOnChange(e, keyIndex, "projectDescription")
                        }
                        invalid={
                          errors &&
                          !!errors[keyIndex] &&
                          !!errors[keyIndex].projectDescription
                        }
                      />
                    </InputGroup>
                    <FormFeedback
                      className={
                        errors &&
                        !!errors[keyIndex]?.projectDescription &&
                        "invalid-field"
                      }
                    >
                      {errors && errors[keyIndex]?.projectDescription}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col className="col-auto col d-flex align-items-end">
                  <label> &nbsp; </label>
                  <div className="button-area project">
                    <div className="text-center">
                      {keyIndex !== 0 && (
                        <Button
                          onClick={(e) => deleteProject(e, keyIndex)}
                          size="sm"
                          id={`Tooltip_${keyIndex}_delete`}
                          color="danger"
                          outline
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                          {/* <Tooltip
        placement="top"
        autohide={true}
        isOpen={isHover}
        target={`Tooltip_${keyIndex}_delete`}
        toggle={(e) => toggle("delete", e)}
      >
        Delete
      </Tooltip> */}
                        </Button>
                      )}
                      {keyIndex === 0 && projects.length === 1 && (
                        <Button
                          onClick={(e) => addProject(e, keyIndex)}
                          size="sm"
                          id={`Tooltip_${keyIndex}_add`}
                          color="primary"
                          outline
                        >
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          {/* <Tooltip
        placement="top"
        autohide={true}
        isOpen={isHover}
        target={`Tooltip_${keyIndex}_add`}
        toggle={(e) => toggle("add", e)}
      >
        Add
      </Tooltip> */}
                        </Button>
                      )}
                      {keyIndex === projects.length - 1 &&
                        projects.length > 1 && (
                          <Button
                            onClick={(e) => addProject(e, keyIndex)}
                            size="sm"
                            id={`Tooltip_${keyIndex}_add`}
                            color="primary"
                            outline
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>
                            {/* <Tooltip
        placement="top"
        autohide={true}
        isOpen={isHover}
        target={`Tooltip_${keyIndex}_add`}
        toggle={(e) => toggle("add", e)}
      >
        Add
      </Tooltip> */}
                          </Button>
                        )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
    </>
  );
}

export default AddProjects;
