import { axiosAccountInstance } from "api/Request";

import { formatDate } from "helpers/getDate";
import history from "helpers/History";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "../../../../components/Toastify";
// import { ReactSearchAutocomplete } from "react-search-autocomplete";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormFeedback,
} from "reactstrap";
import { setEmployeeListInfo } from "redux/actions/super-admin/EmployeeListAction";
import { authRequest } from "api/Request";
import Tables from "views/examples/Tables";
import EmployeeModal from "components/Modals/EmployeeModal";
import { fetchUserData } from "helpers/storageData";
import viewIcn from "../../../../assets/img/icons/customIcons/view.svg";
import { setSuggestedSlots } from "../../../../redux/actions/admin/SuggestedSlotsAction";
import AddSlots from "./AddSlots";
import AddNewSlot from "./Add-Slots";

function EmployeeSearchCard({
  searchFormData,
  tab,
  loader,
  isEmployeeUnavailable,
}) {
  const dispatch = useDispatch();
  const companyListing = () => {
    const response = authRequest({ url: "/companylist" });
    response.then(
      function (result) {
        // setCompanyListData(result.body.data);
      },
      (err) => {
        // toast.error("Error Occureed"); // Error: "Promise rejected"
        console.log(err);
      }
    );
  };
  useEffect(() => {
    companyListing();
  }, []);
  // const [companyName, setCompanyName] = useState("");
  // const [companyListData, setCompanyListData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [interviewModal, setInterviewModal] = useState(false);
  const [empModal, setEmpModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [currentCurrencyCode, setCurrentCurrencyCode] = useState(null);
  const [employeeData, setEmployeeData] = useState({});
  const [currentBooking, setCurrentBooking] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [bookingFormData, setBookingFormData] = useState({
    employeeId: "",
    durationFromDate: "",
    durationtoDate: "",
    hourlyPayment: "",
    currencyCode: "",
    remarks: "",
  });
  const [interviewFormData, setInterviewFormData] = useState({
    // ...bookingFormData,
    // employeeId: "",
    isInterview: "",
    slots: [],
    interviewRemarks: "",
  });

  const [loading, setLoading] = useState(false);

  const slotsAddHandler = () => {
    setInterviewFormData({
      ...interviewFormData,
      slots: [
        ...interviewFormData.slots,
        {
          id: "",
          date: "",
          duration: "",
          time: [],
        },
      ],
    });
    if (!errors.slotsError) {
      setErrors({
        ...errors,
        slots: null,
        slotsError: [
          {
            date: "",
            duration: "",
            time: "",
          },
        ],
      });
    } else {
      setErrors({
        ...errors,
        slots: null,
        slotsError: [
          ...errors.slotsError,
          {
            date: "",
            duration: "",
            time: "",
          },
        ],
      });
    }
  };
  const handleInterviewFormChange = (updatedSlots) => {
    setInterviewFormData((prevState) => ({
      ...prevState,
      slots: updatedSlots,
    }));
    console.log("modal...", interviewFormData);
  };
  useEffect(() => {
    if (interviewModal) {
      // setModal(false)
      setInterviewFormData({
        ...interviewFormData,
        slots: [{ id: "", date: "", duration: "", time: [] }],
      });
      setErrors({
        ...errors,
        slotsError: [{ date: "", duration: "", time: [] }],
      });
    }
  }, [interviewModal]);
  const validatingSlots = () => {
    const slotErr = [];

    if (interviewFormData?.slots?.length > 0) {
      interviewFormData?.slots?.forEach((item, index) => {
        let err = {};
        if (!item?.date) err["date"] = "Date is required";
        if (!item?.duration) err["duration"] = "Duration is required";
        if (!item?.time) err["time"] = "Slot is required";

        if (Object.keys(err).length !== 0) {
          slotErr[index] = err;
        }
      });

      return slotErr;
    }
  };

  const socketInstance = useSelector((state) => state.socketReducer.result);

  const fetchBookingEmployeeListResult = useSelector(
    (state) => state.EmployeeListReducer.bookingList.result
  );

  useEffect(() => {
    if (fetchBookingEmployeeListResult) {
      setData(fetchBookingEmployeeListResult.employees);
    }
    return () => {
      setData([]);
    };
  }, [fetchBookingEmployeeListResult]);

  const handleOnChange = (e) => {
    setBookingFormData({ ...bookingFormData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: null });
  };

  const handleInterviewChange = (e) => {
    setInterviewFormData({
      ...interviewFormData,
      [e.target.name]: e.target.value,
    });
    setErrors({ ...errors, [e.target.name]: null });
  };

  const bookingHandler = (item) => {
    setBookingFormData({
      ...bookingFormData,
      hourlyPayment: item?.hourlyPayment,
      currencyCode: item?.currencyCode,
      employeeId: item?.employeeId,
      companyName: item?.companyName,
      empId: item?.empId,
    });
    setCurrentCurrencyCode(item?.currencyCode);

    setModal(!modal);
    return;
  };
  const onScheduleInterview = async () => {
    //interview schedule
    const isValid = await validation();
    console.log("sche...", bookingFormData);
    if (isValid) {
      setInterviewFormData({
        ...bookingFormData,
        ...interviewFormData,
        isInterview: true,
      });
      // console.log("sche...",interviewFormData)
      setModal(false);
      setInterviewModal(!interviewModal);
      return;
    }
    // setBookingFormData({
    //   ...bookingFormData,
    //   hourlyPayment: item?.hourlyPayment,
    //   currencyCode: item?.currencyCode,
    //   employeeId: item?.employeeId,
    //   companyName: item?.companyName,
    //   empId: item?.empId,
    // });
  };
  const close = () => {
    setBookingFormData({
      employeeId: "",
      empId: "",
      durationFromDate: "",
      durationtoDate: "",
      hourlyPayment: "",
      currencyCode: "",
      companyName: "",
      remarks: "",
    });

    setCurrentBooking([]);
    setEmployeeData({});
    setEmpModal(false);
    setModal(false);

    setErrors("");
  };
  const closeInterview = () => {
    setInterviewFormData({
      // employeeId: "",
      // empId: "",
      // durationFromDate: "",
      // durationtoDate: "",
      // hourlyPayment: "",
      // currencyCode: "",
      // companyName: "",
      // remarks: "",
      date: "",
      duration: "",
      slots: [],
      interviewRemarks: "",
    });
    setInterviewModal(false);
  };

  const disablesPastDate = () => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in "YYYY-MM-DD" format
    return today;
  };

  const disablePastDate = () => {
    const today = new Date();

    let dd;
    let mm;
    if (interviewFormData?.isInterview) {
      if (today.getHours() < 12) {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate());
      } else {
        return new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 1
        );
      }
      // dd = String(today.getDate()).padStart(2, "0");
      // mm = String(today.getMonth() + 1).padStart(2, "0");
    } else {
      dd = String(today.getDate() + 1).padStart(2, "0");
      mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    }
  };

  const validation = () => {
    const {
      // employeeId,
      durationFromDate,
      durationtoDate,
      hourlyPayment,
      currencyCode,
      remarks,
    } = bookingFormData;

    const errorsFound = {};
    // if (!employeeId) errorsFound["employeeId"] = "Oops! please add employeeId";
    if (durationFromDate > durationtoDate && durationFromDate && durationtoDate)
      errorsFound["durationFromDate"] =
        "Starting date must be less than closing date";
    if (!durationFromDate)
      errorsFound["durationFromDate"] = "Starting date is required";
    if (!durationtoDate)
      errorsFound["durationtoDate"] = "Closing date is required";
    if (!hourlyPayment)
      errorsFound["hourlyPayment"] = "Hourly payment is required";
    if (!currencyCode)
      errorsFound["currencyCode"] = "Currency code is required";
    if (!remarks) errorsFound["remarks"] = "Remarks is required";

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };

  const interviewValidation = async () => {
    const {
      // date,
      // duration,
      slots,
      // interviewRemarks,
    } = interviewFormData;

    const errorsFound = {};
    if (slots.length === 0) errorsFound["slots"] = "Slots are required";
    // if (!interviewRemarks) errorsFound["interviewRemarks"] = "Remarks is required";
    const slotsErrors = await validatingSlots();
    if (slotsErrors && slotsErrors.length > 0)
      errorsFound["slotsError"] = slotsErrors;
    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };

  const onSubmit = async () => {
    const isValid = await validation();

    if (isValid) {
      const finalFormData = bookingFormData;
      axiosAccountInstance
        .post("/createbooking", finalFormData)
        .then((res) => {
          toast.success(res.data.body.message);
          history.push("/admin/booking-details");
          setBookingFormData({
            employeeId: "",
            empId: "",
            durationFromDate: "",
            durationtoDate: "",
            hourlyPayment: "",
            currencyCode: "",
            companyName: "",
            remarks: "",
          });
          const finalFormData = {
            ...searchFormData,
            fromDate: formatDate(),
            toDate: formatDate(1),
            page: tab,
          };

          socketInstance.emit("message", {
            type: "CREATE_BOOKING",
            subType: "UPDATE_PENDING_BOOKING_APPROVAL_REQUESTS",
            key: bookingFormData?.employeeId,
            extraInfo: fetchUserData()?.companyName,
          });
          dispatch(setEmployeeListInfo("booking", finalFormData));
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setBookingFormData("");
      setModal(false);
    } else {
      // your code here
    }
  };

  const onSubmitInterview = async (data, remark) => {
    // const isValid = await interviewValidation();

    setIsSubmit(true);
    if (true) {
      const finalFormData = {
        ...interviewFormData,
        slots: data,
        interviewRemarks: remark,
      };
      axiosAccountInstance
        .post("/createbooking", finalFormData)
        .then((res) => {
          toast.success(res.data.body.message);
          history.push("/admin/booking-details");
          setInterviewFormData({
            employeeId: "",
            empId: "",
            durationFromDate: "",
            durationtoDate: "",
            hourlyPayment: "",
            currencyCode: "",
            companyName: "",
            remarks: "",
            date: "",
            duration: "",
            slots: [],
            interviewRemarks: "",
          });
          const finalFormData = {
            ...searchFormData,
            fromDate: formatDate(),
            toDate: formatDate(1),
            page: tab,
          };

          socketInstance.emit("message", {
            type: "INTERVIEW_REQUESTED",
            subType: "UPDATE_PENDING_INTERVIEW_APPROVAL_REQUESTS",
            key: bookingFormData?.employeeId,
            extraInfo: fetchUserData()?.companyName,
          });
          dispatch(setEmployeeListInfo("booking", finalFormData));
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setBookingFormData("");
      // setModal(false);
      setInterviewModal(false);
    } else {
      // your code here
    }
  };

  const viewHandler = async (dataObj) => {
    setLoading(true);
    setEmployeeData(dataObj);
    setEmpModal(true);

    await axiosAccountInstance
      .get(`/employee_current_bookings?employeeId=${dataObj?.employeeId}`)
      .then((res) => {
        const currentData = res.data.body.data.bookingHistory;
        setCurrentBooking(currentData);
      });

    setLoading(false);
  };

  const colums = [
    { columnName: "Employee ID", columnTag: "empId", type: "data" },

    { columnName: "Designation", columnTag: "designation", type: "data" },
    {
      columnName: "Experience",
      columnTag: "experience",
      type: "text",
    },
    { columnName: "Skills", columnTag: "skillSets", type: "mapping" },

    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },
    // { columnName: "Currency", columnTag: "currencyCode", type: "data" },
    {
      columnName: "Book Employee",
      columnTag: "BookEmployee",
      type: "",
      actionBookEmployee: bookingHandler,
    },

    // { columnName: "", columnTag: "", type: "" },
  ];

  const actionButtons = [
    {
      columnName: "",
      btnTitle: "View",
      btnIcon: viewIcn,
      btnTooltip: "View",
      type: "actionButton",
      btn: "ViewEmployee",
      btnType: "primary",
      actionFunction: viewHandler,
    },
    // {
    //   columnName: "",
    //   btnTitle: "Book Employee",
    //   btnIcon: bookempIcn,
    //   btnTooltip: "Book Employee",
    //   type: "actionButton",
    //   btn: "BookEmployee",
    //   btnType: "primary",
    //   actionFunction: bookingHandler,
    // },
  ];
  console.log("int", interviewFormData);
  useEffect(() => {
    setInterviewFormData({
      ...interviewFormData,
      slots: [{ id: "", date: "", duration: "", time: [] }],
    });

    setErrors({
      ...errors,
      slotsError: [
        {
          date: "",
          duration: "",
          time: "",
        },
      ],
    });
    // eslint-disable-next-line
  }, []);
  const deleteSlot = (e, i) => {
    e.preventDefault();
    let slotsData = [...interviewFormData.slots];
    slotsData.splice(i, 1);

    setInterviewFormData({
      ...interviewFormData,
      slots: slotsData,
    });
  };
  return (
    <div>
      <Tables
        coloums={colums}
        data={fetchBookingEmployeeListResult?.employees}
        actionButtons={actionButtons}
        loader={loader}
        isBookEmployee={true}
        isEmployeeUnavailable={isEmployeeUnavailable}
      />

      <Modal toggle={close} centered isOpen={modal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              {" "}
              Book employee
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <p className="font-weight-normal">
            Please select start date and end date of your booking
          </p>
          <FormGroup row>
            <Col sm={6}>
              <Label for="durationFromDate">
                Starting Date
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                className="border"
                id="durationFromDate"
                name="durationFromDate"
                type="date"
                value={bookingFormData.durationFromDate}
                onChange={(e) => handleOnChange(e)}
                min={disablesPastDate()}
                invalid={!!errors && !!errors.durationFromDate}
              />
              <FormFeedback
                className={errors?.durationFromDate && "invalid-field"}
              >
                {errors?.durationFromDate}
              </FormFeedback>
            </Col>
            <Col sm={6}>
              <Label for="durationtoDate">
                Closing Date
                <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                className="border"
                id="durationtoDate"
                name="durationtoDate"
                type="date"
                value={bookingFormData.durationtoDate}
                onChange={(e) => handleOnChange(e)}
                min={disablesPastDate()}
                invalid={!!errors && !!errors.durationtoDate}
              />
              <FormFeedback
                className={errors?.durationtoDate && "invalid-field"}
              >
                {errors?.durationtoDate}
              </FormFeedback>
            </Col>
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Employee ID
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input name="empId" value={bookingFormData?.empId} readOnly />
                </InputGroup>
              </FormGroup>
            </Col>
            {/* <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Company
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="ni ni-building" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="companyName"
                    value={bookingFormData.companyName}
                    readOnly
                  />
                </InputGroup>
              </FormGroup>
            </Col> */}
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Currency
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="fa fa-credit-card" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="currency"
                    type="text"
                    name="currencyCode"
                    value={currentCurrencyCode}
                    onChange={(e) => handleOnChange(e)}
                    invalid={!!errors && !!errors.currencyCode}
                    disabled
                  />

                  {/* <option value={item.currencyCode} disabled selected>
                          {item.currencyCode}
                        </option> */}
                  {/* </Input> */}
                  <FormFeedback
                    className={errors?.currencyCode && "invalid-field"}
                  >
                    {errors?.currencyCode}
                  </FormFeedback>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  Payment
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Label>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ backgroundColor: "#e9ecef" }}>
                      <i className="ni ni-hat-3" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    required
                    placeholder="Hourly Payment"
                    // type="text"
                    name="hourlyPayment"
                    value={bookingFormData.hourlyPayment}
                    readOnly
                    // onChange={(e) => handleOnChange(e)}
                    invalid={!!errors && !!errors.hourlyPayment}
                  />
                </InputGroup>
                <FormFeedback
                  className={errors?.hourlyPayment && "invalid-field"}
                >
                  {errors?.hourlyPayment}
                </FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup className="mb-0">
            <Label
              style={{ fontSize: "10px" }}
              for="remarks"
              className="text-uppercase"
            >
              Remarks<span style={{ color: "red" }}>*</span>
            </Label>
            <InputGroup className="input-group-alternative shadow-none border">
              <Input
                placeholder="Remarks"
                type="textarea"
                name="remarks"
                onChange={(e) => handleOnChange(e)}
                value={bookingFormData.remarks}
                invalid={!!errors && !!errors.remarks}
              />
            </InputGroup>
            <FormFeedback className={errors?.remarks && "invalid-field"}>
              {errors?.remarks}
            </FormFeedback>
          </FormGroup>
          <br></br>
          <p className="font-weight-normal" style={{ fontSize: "12px" }}>
            You can click 'Schedule Interview' to set up an interview or
            'Submit' to directly submit a booking request.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={onScheduleInterview} color="primary">
            Schedule Interview
          </Button>
          <Button size="sm" onClick={onSubmit} color="primary">
            Submit
          </Button>
        </ModalFooter>
      </Modal>

      {interviewModal && (
        <AddNewSlot
          tab={tab}
          onSubmitInterview={onSubmitInterview}
          data={interviewFormData}
          interviewModal={interviewModal}
          closeInterview={closeInterview}
          errors={errors}
          disablePastDate={disablePastDate}
        />
      )}
      <EmployeeModal
        searchFormData={searchFormData}
        tab={tab}
        modal={empModal}
        close={close}
        data={employeeData}
        currentData={currentBooking}
        loading={loading}
      />
    </div>
  );
}

export default EmployeeSearchCard;
