import { axiosAccountInstance } from "api/Request";

import { formatDate } from "helpers/getDate";
import history from "helpers/History";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import viewIcn from "../../../../assets/img/icons/customIcons/view.svg";
// import toast from "../../../../components/Toastify";
// import { ReactSearchAutocomplete } from "react-search-autocomplete";

import Tables from "views/examples/Tables";
import EmployeeModal from "components/Modals/EmployeeModal";
import { getResumeRequests } from "redux/actions/admin/ResumeRequestsAction";


function OutboundResumeRequestCard({
  searchFormData,
  tab,
  loader,
  // isEmployeeUnavailable,
}) {
  const dispatch = useDispatch();
 
 
//   useEffect(() => {
//     dispatch(getResumeRequests("1","1",""));
//   }, [dispatch]);
  const close = () => {
    setEmpModal(false);
  };
  const [data, setData] = useState([]);

  const [empModal, setEmpModal] = useState(false);

  const [employeeData, setEmployeeData] = useState({});
  const [currentBooking, setCurrentBooking] = useState([]);

  const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   dispatch(getResumeRequests("0","",""));
  // }, [dispatch]);
  const socketInstance = useSelector((state) => state.socketReducer.result);
  const fetchBookingEmployeeListResult = useSelector(
    (state) => state.ResumeRequestReducer.result
  );

  useEffect(() => {
    if (fetchBookingEmployeeListResult) {
      console.log(
        "fetchBookingEmployeeListResult",
        fetchBookingEmployeeListResult?.requests
      );
      setData(fetchBookingEmployeeListResult.requests);
    }
    return () => {
      setData([]);
    };
  }, [fetchBookingEmployeeListResult]);



  const viewHandler = async (dataObj) => {
    setLoading(true);
    setEmployeeData(dataObj);
    setEmpModal(true);
    console.log("emp/...........................", dataObj);
    await axiosAccountInstance
      .get(`/employee_current_bookings?employeeId=${dataObj?.employeeId}`)
      .then((res) => {
        const currentData = res.data.body.data.bookingHistory;
        setCurrentBooking(currentData);
      });

    setLoading(false);
  };

  const colums = [
    { columnName: "Employee ID", columnTag: "empId", type: "data" },

    // { columnName: "Company", columnTag: "requiredCompany", type: "data" },
   
    { columnName: " Date", columnTag: "createdAt", type: "data" },
    {
      columnName: "Experience",
      columnTag: "experience",
      type: "text",
    },
    
    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Skills", columnTag: "skillSets", type: "mapping" },
    // { columnName: "Status", columnTag: "resumeStatus", type: "data" },


    {
      columnName: "Resume",
      columnTag: "resumeStatus",
      type: "data",
      // actionBookEmployee: bookingHandler,
    },

    { columnName: "", columnTag: "", type: "" },
  ];

  const actionButtons = [
    {
      columnName: "",
      btnTitle: "View",
      btnIcon: viewIcn,
      btnTooltip: "View",
      type: "actionButton",
      btn: "ViewEmployee",
      btnType: "primary",
      actionFunction: viewHandler,
    },
   
  ];

  return (
    <div>
      <Tables
        coloums={colums}
        data={fetchBookingEmployeeListResult?.requests}
        actionButtons={actionButtons}
        loader={loader}
        // isBookEmployee={true}
        // isEmployeeUnavailable={isEmployeeUnavailable}
      />

    

    
      <EmployeeModal
      isOutbound={true}
        modal={empModal}
        close={close}
        data={employeeData}
        currentData={currentBooking}
        loading={loading}
      />
    </div>
  );
}

export default OutboundResumeRequestCard;
