import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Input,
  Button,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import searchBanner from "../../assets/img/new/search-banner-latest.png";
import SearchIcon from "../../assets/img/icons/customIcons/searchicondark.svg";
import calendarIcon from "../../assets/img/icons/customIcons/calendar.svg";
import IdCard from "../../assets/img/icons/customIcons/id_card.svg";
import newReleases from "../../assets/img/icons/customIcons/new_releases.svg";
import { Autocomplete, InputAdornment, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FaSearch, FaCalendarAlt } from "react-icons/fa";
import { experienceArray } from "helpers/utils";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setEmployeeListInfo } from "redux/actions/super-admin/EmployeeListAction";
import { axiosAccountInstance } from "api/Request";
import BookEmployeeModal from "components/Modals/BookEmployeeModal";
import toast from "../../components/Toastify";
import history from "helpers/History";
import { formatDate } from "helpers/getDate";
import { fetchUserData } from "helpers/storageData";
import AddNewSlot from "./users/employee-search-and-sending-booking-requests/Add-Slots";
import EmployeeModal from "components/Modals/EmployeeModal";
import { DateRange } from "react-date-range";
import PaginationComponent from "components/pagination/Pagination";
import { resetEmployeeSearch } from "redux/actions/super-admin/EmployeeListAction";
import { addDays } from "date-fns";

const LandingSearch = (state) => {
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    autocomplete: {
      backgroundColor: "white",
      borderRadius: "100px",
      "& .MuiInputBase-root.MuiInput-root:after": {
        borderBottom: "0px",
      },
      "& .MuiInputBase-root.MuiInput-root:before": {
        borderBottom: "0px",
      },
      "& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before": {
        borderBottom: "0px",
      },
      "& .MuiAutocomplete-popupIndicator": {
        display: "none",
      },
    },
    searchInput: {
      paddingLeft: "10px",
      marginRight: "10px",
      paddingTop: "2px",
    },
  }));
  const classes = useStyles();

  const [searchFormData, setSearchFormData] = useState({
    experience: null,
    skillSet: null,
    fromDate: formatDate(),
    toDate: formatDate(1),
    search: state.location.state?.empId ? state.location.state?.empId : null,
    page: null,
    limit: null,
    companyName: null,
  });
  const [bookingFormData, setBookingFormData] = useState({
    employeeId: "",
    durationFromDate: "",
    durationtoDate: "",
    hourlyPayment: "",
    currencyCode: "",
    remarks: "",
  });
  const [interviewFormData, setInterviewFormData] = useState({
    // ...bookingFormData,
    // employeeId: "",
    isInterview: "",
    slots: [],
    interviewRemarks: "",
  });
  const [skillValue, setSkillValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isOpenBookEmplModal, setIsOpenBookEmplModal] = useState(false);
  const [interviewModal, setInterviewModal] = useState(false);
  const [errors, setErrors] = useState(null);
  const [searchErrors, setSearchErrors] = useState(null);
  const [currentCurrencyCode, setCurrentCurrencyCode] = useState(null);
  const [employeeData, setEmployeeData] = useState({});
  const [currentBooking, setCurrentBooking] = useState([]);
  const [empModal, setEmpModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(1);

  const fetchBookingEmployeeListResult = useSelector(
    (state) => state.EmployeeListReducer.bookingList.result
  );
  const fetchBookingEmployeeListLoader = useSelector(
    (state) => state.EmployeeListReducer.bookingList.loader
  );
  const socketInstance = useSelector((state) => state.socketReducer.result);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
      key: "selection",
    },
  ]);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    fetchOptions('');
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(resetEmployeeSearch());
  }, []);

  const handleChange = (event) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    // handleEmployeeSearchOnChange(event);
    // Delayed API call after 5000ms
    setTimeout(() => {
      fetchOptions(newSearchText);
    }, 500);
  };

  const fetchOptions = async (searchData) => {
    try {
      axiosAccountInstance
        .get(`list_skills?filter=${searchData}`)

        .then((result) => {
          setOptions(result?.data?.body?.data);
        });
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const onCloseBookEmployeeModal = () => {
    setBookingFormData({
      employeeId: "",
      empId: "",
      durationFromDate: "",
      durationtoDate: "",
      hourlyPayment: "",
      currencyCode: "",
      companyName: "",
      remarks: "",
    });

    // setCurrentBooking([]);
    // setEmployeeData({});
    // setEmpModal(false);
    setIsOpenBookEmplModal(false);

    setErrors("");
  };

  const handleDateChange = (newValue) => {
    setDateRange(newValue);
    setSearchFormData((prev) => ({
      ...prev,
      fromDate: newValue[0] ? moment(newValue[0]).format("YYYY-MM-DD") : null,
      toDate: newValue[1] ? moment(newValue[1]).format("YYYY-MM-DD") : null,
    }));
  };

  const disablePastDate = () => {
    const today = new Date();

    let dd;
    let mm;
    if (interviewFormData?.isInterview) {
      if (today.getHours() < 12) {
        return new Date(today.getFullYear(), today.getMonth(), today.getDate());
      } else {
        return new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 1
        );
      }
      // dd = String(today.getDate()).padStart(2, "0");
      // mm = String(today.getMonth() + 1).padStart(2, "0");
    } else {
      dd = String(today.getDate() + 1).padStart(2, "0");
      mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + "-" + mm + "-" + dd;
    }
  };
  const onTextFieldsChange = (value, name) => {
    let data =
      name === "experience"
        ? { ...searchFormData, experience: value }
        : name === "date"
        ? {
            ...searchFormData,
            fromDate: moment(value.startDate).format("YYYY-MM-DD"),
            toDate: moment(value.endDate).format("YYYY-MM-DD"),
          }
        : name === "companyName"
        ? { ...searchFormData, companyName: value }
        : { ...searchFormData, search: value };
    // let data = { ...searchFormData, search: value };
    setSearchFormData(data);
  };

  const handleEmployeeSearchOnChange = (e, v) => {
    setSearchFormData({ ...searchFormData, [e]: v });
    setSearchErrors({ ...errors, [e]: null }); // Clear error when input is provided
  };

  const employeeListing = (type, searchFormData) => {
    dispatch(setEmployeeListInfo(type, searchFormData));
  };

  const bookingHandler = (item) => {
    setBookingFormData({
      ...bookingFormData,
      hourlyPayment: item?.hourlyPayment,
      currencyCode: item?.currencyCode,
      employeeId: item?.employeeId,
      companyName: item?.companyName,
      empId: item?.empId,
    });
    setCurrentCurrencyCode(item?.currencyCode);

    setIsOpenBookEmplModal(!isOpenBookEmplModal);
    return;
  };

  const validateFields = () => {
    const errorsFound = {};

    if (!searchFormData.search || searchFormData.search.trim() === "") {
      errorsFound["search"] = "Please enter a skill to start searching.";
    }

    if (!searchFormData.fromDate || !searchFormData.toDate) {
      errorsFound["dateRange"] = "Please enter a date range.";
    }

    setSearchErrors(errorsFound);

    return Object.keys(errorsFound).length === 0;
  };

  const handleSearchSubmit = () => {
    if (validateFields()) {
      employeeListing("booking", searchFormData);
    }
  };
  const handlePagination = (tab) => {
    setTab(tab);
    // setSearchFormData({
    //   ...searchFormData,
    //   page: tab,
    // });
    const finalData = {
      ...searchFormData,
      page: tab,
      // fromDate: initFromDate,
      // toDate: initToDate,
    };

    employeeListing("booking", finalData);
  };
  const handleOnChange = (e) => {
    setBookingFormData({ ...bookingFormData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: null });
  };

  const validation = () => {
    const {
      // employeeId,
      durationFromDate,
      durationtoDate,
      hourlyPayment,
      currencyCode,
      remarks,
    } = bookingFormData;

    const errorsFound = {};
    // if (!employeeId) errorsFound["employeeId"] = "Oops! please add employeeId";
    if (durationFromDate > durationtoDate && durationFromDate && durationtoDate)
      errorsFound["durationFromDate"] =
        "Starting date must be less than closing date";
    if (!durationFromDate)
      errorsFound["durationFromDate"] = "Starting date is required";
    if (!durationtoDate)
      errorsFound["durationtoDate"] = "Closing date is required";
    if (!hourlyPayment)
      errorsFound["hourlyPayment"] = "Hourly payment is required";
    if (!currencyCode)
      errorsFound["currencyCode"] = "Currency code is required";
    if (!remarks) errorsFound["remarks"] = "Remarks is required";

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };

  const closeEmployeeDetails = () => {
    setBookingFormData({
      employeeId: "",
      empId: "",
      durationFromDate: "",
      durationtoDate: "",
      hourlyPayment: "",
      currencyCode: "",
      companyName: "",
      remarks: "",
    });

    setCurrentBooking([]);
    setEmployeeData({});
    setEmpModal(false);
    setIsOpenBookEmplModal(false);

    setErrors("");
  };
  const closeInterview = () => {
    setInterviewFormData({
      // employeeId: "",
      // empId: "",
      // durationFromDate: "",
      // durationtoDate: "",
      // hourlyPayment: "",
      // currencyCode: "",
      // companyName: "",
      // remarks: "",
      date: "",
      duration: "",
      slots: [],
      interviewRemarks: "",
    });
    setInterviewModal(false);
  };

  const onSubmitInterview = async (data, remark) => {
    // const isValid = await interviewValidation();

    // setIsSubmit(true)
    if (true) {
      const finalFormData = {
        ...interviewFormData,
        slots: data,
        interviewRemarks: remark,
      };
      axiosAccountInstance
        .post("/createbooking", finalFormData)
        .then((res) => {
          toast.success(res.data.body.message);
          history.push("/admin/booking-details");
          setInterviewFormData({
            employeeId: "",
            empId: "",
            durationFromDate: "",
            durationtoDate: "",
            hourlyPayment: "",
            currencyCode: "",
            companyName: "",
            remarks: "",
            date: "",
            duration: "",
            slots: [],
            interviewRemarks: "",
          });
          const finalFormData = {
            ...searchFormData,
            fromDate: formatDate(),
            toDate: formatDate(1),
            page: 1,
          };

          socketInstance.emit("message", {
            type: "INTERVIEW_REQUESTED",
            subType: "UPDATE_PENDING_INTERVIEW_APPROVAL_REQUESTS",
            key: bookingFormData?.employeeId,
            extraInfo: fetchUserData()?.companyName,
          });
          dispatch(setEmployeeListInfo("booking", finalFormData));
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setBookingFormData("");
      // setModal(false);
      setInterviewModal(false);
    } else {
      // your code here
    }
  };

  const onClickEmployee = async (dataObj) => {
    setLoading(true);
    setEmployeeData(dataObj);
    setEmpModal(true);

    await axiosAccountInstance
      .get(`/employee_current_bookings?employeeId=${dataObj?.employeeId}`)
      .then((res) => {
        const currentData = res.data.body.data.bookingHistory;
        setCurrentBooking(currentData);
      });

    setLoading(false);
  };

  const onScheduleInterview = async () => {
    //interview schedule
    const isValid = await validation();
    console.log("sche...", bookingFormData);
    if (isValid) {
      setInterviewFormData({
        ...bookingFormData,
        ...interviewFormData,
        isInterview: true,
      });
      // console.log("sche...",interviewFormData)
      setIsOpenBookEmplModal(false);
      setInterviewModal(!interviewModal);
      return;
    }
    // setBookingFormData({
    //   ...bookingFormData,
    //   hourlyPayment: item?.hourlyPayment,
    //   currencyCode: item?.currencyCode,
    //   employeeId: item?.employeeId,
    //   companyName: item?.companyName,
    //   empId: item?.empId,
    // });
  };

  const onSubmitBookEmployee = async () => {
    const isValid = await validation();

    if (isValid) {
      const finalFormData = bookingFormData;
      axiosAccountInstance
        .post("/createbooking", finalFormData)
        .then((res) => {
          toast.success(res.data.body.message);
          history.push("/admin/booking-details");
          setBookingFormData({
            employeeId: "",
            empId: "",
            durationFromDate: "",
            durationtoDate: "",
            hourlyPayment: "",
            currencyCode: "",
            companyName: "",
            remarks: "",
          });
          const finalFormData = {
            ...searchFormData,
            fromDate: formatDate(),
            toDate: formatDate(1),
            // page: tab, to change in future
            page: 1,
          };

          socketInstance.emit("message", {
            type: "CREATE_BOOKING",
            subType: "UPDATE_PENDING_BOOKING_APPROVAL_REQUESTS",
            key: bookingFormData?.employeeId,
            extraInfo: fetchUserData()?.companyName,
          });
          dispatch(setEmployeeListInfo("booking", finalFormData));
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setBookingFormData("");
      setIsOpenBookEmplModal(false);
    } else {
      // your code here
    }
  };

  return (
    <div>
      {/* Banner Section */}
      <div
        className="landing-banner"
        style={{
          backgroundImage: `url(${searchBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          // filter:'brightness(0.4)'
        }}
      >
        <h1 className="content">
          Connect. Collaborate.
          <span className="text-primary font-weight-bolder"> ScaleUp!</span>
        </h1>
      </div>

      {/* Search Bar */}
      <div className="search-section">
        <div className="search-container search-filter-boxes">
          <div className="skill-textbox customsearch text-dark">
            <span className="new-icon">
              <img alt="" src={newReleases} width={"22px"} />
            </span>
            <span className="searchicon">
              <img alt="" src={!skillValue ? SearchIcon : ""} width={"16px"} />
            </span>
            <Autocomplete
              className={`text-dark ${classes.autocomplete} form-control`}
              name="search"
              value={skillValue}
              onClear={() => {}}
              width={50}
              sx={{
                "& .MuiAutocomplete-endAdornment": {
                  marginRight: "-24px",
                },
              }}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  setSkillValue(null);
                  fetchOptions('')
                  setSearchFormData({ ...searchFormData, search: "" });
                  return;
                }
                if (typeof newValue === "string") {
                  setSkillValue({
                    domain: newValue.domain,
                  });
                  handleEmployeeSearchOnChange("search", newValue?.domain);
                } else if (newValue && newValue.domain) {
                  setSkillValue({
                    domain: newValue.domain,
                  });
                  handleEmployeeSearchOnChange("search", newValue?.domain);
                } else {
                  setSkillValue(newValue.domain);
                  handleEmployeeSearchOnChange("search", newValue?.domain);
                }
              }}
              selectOnFocus
              handleHomeEndKeys
              options={options.length > 0 ? options : []}
              getOptionLabel={(option) => {
                if (typeof option?.domain === "string") {
                  return option?.domain;
                }
                return option.domain;
              }}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ color: "#000000", fontSize: "0.875rem" }}
                >
                  {option.domain}
                </li>
              )}
              renderInput={(params) => (
                <div className="d-flex">
                  <TextField
                    placeholder="Search Skill"
                    {...params}
                    name="search"
                    onChange={handleChange}
                    variant="standard"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearchSubmit();
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <SearchIcon style={{ color: "#000000" }} />
                      //   </InputAdornment>
                      // ),
                      style: {
                        color: "#000000",
                        fontSize: "0.875rem",
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "#000000",
                        fontSize: "0.875rem",
                      },
                      color: "#00000",
                      "& .MuiInputBase-input::placeholder": {
                        color: "#000000", // Placeholder color black
                        opacity: 1, // Ensures placeholder is fully visible
                      },
                    }}
                    // error={!!searchErrors?.search}
                    // helperText={searchErrors?.search}
                    // sx={{
                    //   "& .MuiFormHelperText-root": {
                    //     fontSize: "14px",
                    //     marginTop: "-4px",
                    //     width: "300px",
                    //   },
                    // }}
                  />
                </div>
              )}
            />
          </div>

          <div className="skill-textbox text-dark position-relative">
            <Input
              type="select"
              onChange={(e) => onTextFieldsChange(e.target.value, "experience")}
            >
              <option value={""}>Select Experience</option>
              {experienceArray.map((itm) => (
                <option key={itm?.label}>{itm?.label}</option>
              ))}
            </Input>
            <div className="new-form-icon">
              <img alt="" src={IdCard} width={"22px"} />
            </div>
          </div>

          <div className="skill-textbox position-relative" ref={ref}>
            <Input
              type="text"
              className="search-input border w-64 cursor-pointer text-dark"
              value={`${moment(searchFormData.fromDate).format(
                "YYYY-MM-DD"
              )} - ${moment(searchFormData.toDate).format("YYYY-MM-DD")}`}
              onClick={() => setIsOpen(!isOpen)}
            />
            <div className="new-form-icon">
              <img alt="" src={calendarIcon} width={"22px"} />
            </div>
            {isOpen && (
              <div
                className="position-absolute top-12 left-0 z-10 shadow-lg bg-white p-2"
                style={{ zIndex: 2, width: "100%" }}
              >
                <DateRange
                  editableDateInputs={true}
                  onChange={(item) => {
                    onTextFieldsChange(item.selection, "date");
                    setDateRange([item.selection]);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={dateRange}
                  maxDate={addDays(new Date(), 1400)}
                  minDate={new Date()}
                />
              </div>
            )}
          </div>
          <Button className="search-button" onClick={handleSearchSubmit}>
            <FaSearch /> Search
          </Button>
        </div>
        <div className="search-error">{searchErrors?.search}</div>
      </div>

      {/* Job Listings */}
      {fetchBookingEmployeeListLoader && (
        <div className="table-loader">
          <img
            alt="..."
            src={
              require("../../assets/img/icons/customIcons/loader.gif").default
            }
          />
        </div>
      )}
      <Container className="mt-2 main-contentarea" fluid>
        {fetchBookingEmployeeListResult?.employees?.length &&
        !fetchBookingEmployeeListLoader ? (
          <>
            <div className="job-listing">
              {fetchBookingEmployeeListResult?.employees.map(
                (employee, index) => (
                  <div key={index} className="job-card">
                    <div
                      className="job-info"
                      onClick={() => onClickEmployee(employee)}
                    >
                      <h5 className="job-title">{employee?.empId}</h5>
                      <p className="job-desc">
                        {employee?.designation} -{" "}
                        {employee?.experience?.numberOfYears +
                          "." +
                          employee?.experience?.numberOfMonths}{" "}
                        Years
                      </p>

                      <div className="tags">
                        {employee?.skillSets?.map((skill) => (
                          <span className="tag">{skill?.domain}</span>
                        ))}
                      </div>
                    </div>
                    <div className="job-action">
                      <h4 className="rate">
                        {employee?.hourlyPayment} {employee?.currencyCode}
                      </h4>
                      <p className="job-desc">Per Hour</p>
                      <Button
                        className="book-button"
                        onClick={() => bookingHandler(employee)}
                      >
                        Book Now
                      </Button>
                    </div>
                  </div>
                )
              )}
            </div>
            <div
              style={{
                padding: "10px",
                // display: fetchBookingEmployeeListLoader ? "none" : "contents",
              }}
            >
              <PaginationComponent
                totalCount={fetchBookingEmployeeListResult?.itemCount}
                handlePagination={handlePagination}
                activePage={fetchBookingEmployeeListResult?.activePage}
              />
            </div>
          </>
        ) : !fetchBookingEmployeeListLoader &&
          fetchBookingEmployeeListResult?.employees?.length === 0 ? (
          <div className="nodata-table">
            <img
              alt="..."
              src={
                require("../../assets/img/icons/customIcons/nodata.gif").default
              }
            />
          </div>
        ) : (
          ""
        )}
      </Container>
      {interviewModal && (
        <AddNewSlot
          tab={tab}
          onSubmitInterview={onSubmitInterview}
          data={interviewFormData}
          interviewModal={interviewModal}
          closeInterview={closeInterview}
          errors={errors}
          disablePastDate={disablePastDate}
        />
      )}
      <EmployeeModal
        searchFormData={searchFormData}
        tab={tab}
        modal={empModal}
        close={closeEmployeeDetails}
        data={employeeData}
        currentData={currentBooking}
        loading={loading}
      />
      <BookEmployeeModal
        close={onCloseBookEmployeeModal}
        open={isOpenBookEmplModal}
        bookingFormData={bookingFormData}
        handleOnChange={handleOnChange}
        errors={errors}
        currentCurrencyCode={currentCurrencyCode}
        onScheduleInterview={onScheduleInterview}
        onSubmit={onSubmitBookEmployee}
        disablePastDate={disablePastDate}
      />
    </div>
  );
};

export default LandingSearch;
