import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";
import moment from "moment";
import { uniqueDate } from "helpers/getDate";

import { useDispatch, useSelector } from "react-redux";

import { axiosAccountInstance } from "api/Request";
import { getApprovedRequestsInfo } from "redux/actions/admin/ApprovedRequestAction";
import toast from "../../../../components/Toastify";
import "react-toastify/dist/ReactToastify.css";
import Tables from "views/examples/Tables";
import BookingModal from "components/Modals/BookingModal";
import { fetchUserData } from "helpers/storageData";
import { baseURL } from "api/Request";
import viewIcn from "../../../../assets/img/icons/customIcons/view.svg";
import extendbookingIcn from "../../../../assets/img/icons/customIcons/extend-booking.svg";
import closebookingIcn from "../../../../assets/img/icons/customIcons/close-booking.svg";
import SOWModal from "components/Modals/SOWModal";

function ApprovedListCard({ loader }) {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(null);
  const [sowErrors, setSOWErrors] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalExtention, setModalExtention] = useState(false);
  const [data, setData] = useState([]);
  const [closeDate, setCloseDate] = useState("");
  const [bookingIdObj, setBookingIdObj] = useState("");
  const [bookingData, setBookingData] = useState({});
  const [bookingModal, setBookingModal] = useState(false);
  const [extentionObj, setExtentionObj] = useState({});
  const [sowModal, setSOWModal] = useState(false);
  const [employeeID, setEmployeeID] = useState("");
  const [rejectRequestReason, setRejectRequestReason] = useState("");
  const [sowData, setSOWData] = useState({});
  const [sowHistory, setSOWHistory] = useState([]);
  const [signedSOWdoc, setSignedSOWdoc] = useState();
  const fetchResultApprovedListRequests = useSelector(
    (state) => state.ApprovedRequestReducer.result
  );

  useEffect(() => {
    setData(fetchResultApprovedListRequests?.booking || []);
  }, [fetchResultApprovedListRequests]);

  const handleOnSOWRejectChange = (e) => {
    setRejectRequestReason(e.target.value);
    setSOWErrors({ ...errors, rejectRequestReason: null });
  };

  const handleOnChange = (e) => {
    setCloseDate(e.target.value);
    setErrors({ ...errors, closeDate: null });
  };
  const deleteHandler = (data) => {
    setBookingIdObj(data);
    setModal(!modal);
  };
  const bookingExtentionHandler = (data) => {
    setExtentionObj(data);
    setModalExtention(!modalExtention);
  };
  const close = () => {
    setCloseDate("");
    setModal(false);
    setBookingModal(false);
    setModalExtention(false);
    setSOWModal(false);
    setSOWData({});
  };

  const validation = () => {
    const errorsFound = {};

    if (!closeDate || closeDate === "") {
      errorsFound["closeDate"] = "Closing Date is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };
  const validationExtention = () => {
    const errorsFound = {};

    if (!closeDate || closeDate === "") {
      errorsFound["closeDate"] = "Closing Date is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);
      return false;
    }
    return true;
  };
  const validationSOW = () => {
    const errorsFound = {};

    if (!rejectRequestReason || rejectRequestReason === "") {
      errorsFound["rejectRequestReason"] = "Reject Request Reason is required";
    }

    if (Object.keys(errorsFound).length !== 0) {
      setSOWErrors(errorsFound);
      return false;
    }
    return true;
  };

  const socketInstance = useSelector((state) => state.socketReducer.result);
  const submit = () => {
    const isValid = validation();
    if (isValid) {
      const data = {
        bookingId: bookingIdObj.bookingId,
        closingDate: closeDate,
      };
      axiosAccountInstance
        .post("/booking/closure_request", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            socketInstance.emit("message", {
              type: "CREATE_BOOKING_CLOSURE_REQUEST",
              subType: "CREATE_BOOKING_CLOSURE_REQUEST",
              key: data?.bookingId,
              extraInfo: fetchUserData()?.companyName,
            });
            toast.success(res.data.body.message);
            dispatch(getApprovedRequestsInfo("1", "1", "approved", null, ""));
            // dispatch(getApprovedRequestsInfo("1", "1", "approved"));
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setCloseDate("");
      setModal(false);
    }
  };

  const submitExtention = () => {
    const isValid = validationExtention();
    if (isValid) {
      const data = { ...extentionObj, durationtoDate: closeDate };
      axiosAccountInstance
        .post("/booking/extention_create", data)
        .then((res) => {
          if (res.data.statusCode === 201) {
            toast.success(res.data.body.message);
            socketInstance.emit("message", {
              type: "CREATE_BOOKING_EXTENTION",
              subType: "UPDATE_PENDING_BOOKING_EXTENTION_APPROVAL_LIST",
              key: data?.employeeId,
              extraInfo: fetchUserData()?.companyName,
            });

            dispatch(getApprovedRequestsInfo("1", "1", "approved", null, ""));
            setModalExtention(false);
          }
        
        })
        .catch((err) => {
          toast.error(err.response.data.body.data);
        });
      setCloseDate("");
      setModal(false);
    } else {
      console.log("INVALID EXTENTION");
    }
  };

  const viewHandler = (dataObj) => {
    setBookingData(dataObj);
    setBookingModal(true);
  };
  const setCompanyApproval = (isValid = true, data, requesttype) => {
    if (isValid) {
      console.log(sowData);
      axiosAccountInstance
        .post("/booking/final/sow_request/approve", data)
        .then((res) => {
          if (res.data.statusCode === 200) {
            if (requesttype === "approved") {
              socketInstance.emit("message", {
                type: "UPDATE_FINAL_SOW_REQUEST",
                subType: "UPDATE_FINAL_SOW_APPROVED_REQUEST_LIST",
                key: sowData?.bookingId,
                extraInfo: fetchUserData()?.companyName,
              });
              toast.success(res.data.body.message);
            } else {
              socketInstance.emit("message", {
                type: "UPDATE_FINAL_SOW_REQUEST",
                subType: "UPDATE_FINAL_SOW_REJECTED_REQUEST_LIST",
                key: sowData?.bookingId,
                extraInfo: fetchUserData()?.companyName,
              });
              toast.success(res.data.body.message);
            }

            dispatch(getApprovedRequestsInfo("1", "1", "approved"));
            setSOWModal(false);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.body.message);
        });
      setRejectRequestReason("");
    }
  };

  const submitRequest = (data, type) => {
    if (data.sow_status === "rejected") {
      const isValid = validationSOW(data);
      setCompanyApproval(isValid, data, type);
    } else {
      setCompanyApproval(true, data, type);
    }
  };

  // const [isRejectButtonDisabled, setIsRejectButtonDisabled] = useState(false);
  const onReject = () => {
    // setIsRejectButtonDisabled(true);
    const finalData = {
      _id: employeeID,
      sow_status: "rejected",
      rejectReason: rejectRequestReason,
    };
    submitRequest(finalData, "rejected");
  };

  const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(true); //multiple click disabled
  const imageSelect = (image) => {
    // if (image) {
    //   setIsRejectButtonDisabled(false);
    // }

    const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB maximum size limit
    if (image.size > MAX_IMAGE_SIZE) {
      setErrors({
        ...errors,
        sowDocument: `File size exceeds the maximum limit of ${
          MAX_IMAGE_SIZE / (1024 * 1024)
        }MB`,
      });
      setSignedSOWdoc(null);
      setIsApproveButtonDisabled(true);
    } else {
      setErrors({ ...errors, sowDocument: null });

      setSignedSOWdoc(image);
      setIsApproveButtonDisabled(false);
    }
    // setErrors({ ...errors, sowDocument: null });
    // setApproveFormData({
    //   ...approveFormData,
    //   sowDocument: image,
    // });
    // setIsApproveButtonDisabled(false);
  };
  const onApproveSOW = async () => {
    setIsApproveButtonDisabled(true);

    const data = new FormData();
    data.append("_id", employeeID);
    data.append("sow_status", "approved");
    data.append("sowSignedDocument", signedSOWdoc);

    submitRequest(data, "approved");
  };

  const sowRequestHandler = (id, data) => {
    setSOWModal(!modal);
    setSOWData(data);
    setEmployeeID(id);
    setSOWHistory(data?.activity?.filter((obj) => obj.action.includes("SOW")));
  };

  // console.log(item?.duration);
  const colums = [
    { columnName: "Employee ID", columnTag: "empId", type: "data" },

    // { columnName: "Company", columnTag: "parentCompany", type: "data" },
    { columnName: "Designation", columnTag: "designation", type: "data" },
    { columnName: "Date Range", columnTag: "duration", type: "objectData" },
    {
      columnName: "Hourly Rate",
      columnTag: "hourlyPayment",
      type: "paymentMapping",
    },
    // { columnName: "Currency", columnTag: "currencyCode", type: "data" },
    {
      columnName: "SOW",
      columnTag: "sow_final_status",
      type: "data",
      actionSOWApprove: sowRequestHandler,
    },
    {
      columnName: "Status",
      columnTag: "status",
      type: "data",
    },
    { columnName: "", columnTag: "", type: " " },
  ];
  const actionButtons = [
    {
      columnName: "",
      btnTitle: "View",
      type: "actionButton",
      btnTooltip: "View",
      btnIcon: viewIcn,
      btn: "ViewBooking",
      btnType: "primary",
      actionFunction: viewHandler,
    },

    {
      columnName: "",
      btnTitle: "Extend Booking",
      type: "actionButton",
      btnIcon: extendbookingIcn,
      btnTooltip: "Extend Booking",
      btn: "ExtendBooking",
      btnType: "primary",
      actionFunction: bookingExtentionHandler,
    },

    {
      columnName: "",
      btnTitle: "Close Booking",
      type: "actionButton",
      btnIcon: closebookingIcn,
      btnTooltip: "Close Booking",
      btn: "CloseBooking",
      btnType: "danger",
      actionFunction: deleteHandler,
    },
  ];

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <div>
      {data && (
        <Tables
          coloums={colums}
          data={fetchResultApprovedListRequests?.booking}
          actionButtons={actionButtons}
          loader={loader}
        />
      )}
      {/* CLOSE BOOKING */}
      <Modal toggle={close} centered isOpen={modal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              Close Booking
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <label className="font-weight-normal">
            Do you want to close booking?
          </label>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  {`Starting Date : ${uniqueDate(
                    bookingIdObj?.duration?.fromDate
                  )}`}
                </Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  {`To Date : ${uniqueDate(bookingIdObj?.duration?.toDate)}`}
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup row>
            <Label for="exampleText" sm={12}>
              Closing Date
            </Label>
            <Col sm={12}>
              <Input
                id="exampleText"
                name="date"
                type="date"
                value={closeDate}
                onChange={(e) => handleOnChange(e)}
                min={disablePastDate()}
                invalid={!!errors && !!errors.closeDate}
              />
              <FormFeedback className={errors?.closeDate && "invalid-field"}>
                {errors?.closeDate}
              </FormFeedback>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={submit} color="primary">
            Yes
          </Button>{" "}
          <Button size="sm" onClick={close} color="primary">
            No
          </Button>{" "}
          {/* <Button size="sm" onClick={close}>
                  Cancel
                </Button> */}
        </ModalFooter>
      </Modal>

      {/* EXTEND BOOKING */}
      <Modal toggle={close} centered isOpen={modalExtention}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              {" "}
              Extend Booking
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <label className="font-weight-normal">
            Do you want to extend booking?
          </label>
          <Row>
            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  {`Starting Date : ${uniqueDate(
                    extentionObj?.duration?.fromDate
                  )}`}
                </Label>
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <Label
                  style={{ fontSize: "10px" }}
                  for="employeeName"
                  className="text-uppercase"
                >
                  {`To Date : ${uniqueDate(extentionObj?.duration?.toDate)}`}
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup row>
            <Label for="exampleText" sm={12}>
              Extend Upto
            </Label>
            <Col sm={12}>
              <Input
                id="exampleText"
                name="date"
                type="date"
                value={closeDate}
                onChange={handleOnChange}
                invalid={!!errors && !!errors.closeDate}
              />
              <FormFeedback className={errors?.closeDate && "invalid-field"}>
                {errors?.closeDate}
              </FormFeedback>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button size="sm" onClick={submitExtention} color="primary">
            Yes
          </Button>{" "}
          <Button size="sm" onClick={close} color="primary">
            No
          </Button>{" "}
          {/* <Button size="sm" onClick={close}>
                  Cancel
                </Button> */}
        </ModalFooter>
      </Modal>

      {/* SOW REQUEST */}
      <Modal toggle={close} centered isOpen={sowModal}>
        <Row>
          <div className="col">
            <ModalHeader className="text-uppercase font-weight-bolder">
              Approve SOW request
            </ModalHeader>
          </div>
          <Row>
            <Col>
              <ModalHeader className="text-uppercase">
                <Button onClick={close} className="mx-1" close />
              </ModalHeader>
            </Col>
          </Row>
        </Row>
        <ModalBody>
          <FormGroup row>
            <Label for="exampleText" sm={12}>
              Please view SOW document (pdf or doc)
            </Label>
            <Col sm={12}>
              <a
                href={baseURL + "/" + sowData?.sowDocument}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                Download SOW Document
              </a>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="exampleText" sm={12}>
              Rejection Remarks (required only if rejecting)
            </Label>
            <Col sm={12}>
              <Input
                id="exampleText"
                name="text"
                type="textarea"
                onChange={handleOnSOWRejectChange}
                value={rejectRequestReason}
                invalid={!!errors && !!errors.rejectRequestReason}
              />
              <FormFeedback
                className={sowErrors?.rejectRequestReason && "invalid-field"}
              >
                {sowErrors?.rejectRequestReason}
              </FormFeedback>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            onClick={() => onApproveSOW()}
            color="primary"
            disabled={isApproveButtonDisabled}
          >
            Approve
          </Button>{" "}
          <Button
            size="sm"
            onClick={() => onReject()}
            color="primary"
            // disabled={isRejectButtonDisabled}
          >
            Reject
          </Button>
          {/* <Button size="sm" onClick={close}>
                  Cancel
                </Button> */}
        </ModalFooter>
      </Modal>
      <SOWModal
        isOpen={sowModal}
        closeModal={close}
        sowData={sowData}
        onApproveSOW={onApproveSOW}
        onReject={onReject}
        sowHistory={sowHistory}
        handleOnSOWRejectChange={handleOnSOWRejectChange}
        rejectRequestReason={rejectRequestReason}
        isApproveButtonDisabled={isApproveButtonDisabled}
        errors={errors}
        sowErrors={sowErrors}
        imageSelect={imageSelect}
      />
      <BookingModal modal={bookingModal} close={close} data={bookingData} />
    </div>
  );
}

export default ApprovedListCard;
