import { axiosAccountInstance } from "api/Request";


export const setSuggestedSlots = (data) => (dispatch) => {
   
    dispatch({
      type: "SLOT_LIST_BEGINS",
    });
    
    axiosAccountInstance
      .post(`/slot_selection`,data)
      .then(
        (result) => {
          dispatch({
            type: "SLOT_LIST_SUCCESS",
            payload: result.data.body.data,
          });
        }
       
      )
      .catch((err) => {
        dispatch({
          type: "SLOT_LIST_FAILURE",
          payload: "FALIURE",
        });
      });
  };