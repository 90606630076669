import history from "helpers/History";
import { useDispatch, useSelector } from "react-redux";
// import bellIcon from "../../assets/img/icons/customIcons/bell_icon.png";
/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Col,
  Row,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { fetchUserData } from "helpers/storageData";
import { axiosAccountInstance } from "api/Request";
import { setNotificationList } from "redux/actions/admin/NotificationListRequestAction";
import toast from "../Toastify";
import TimeAgo from "javascript-time-ago";

// English.
import en from "javascript-time-ago/locale/en";

TimeAgo.addLocale(en);

const AdminNavbar = (props) => {
  const userDataDetails = fetchUserData();
  const timeAgo = new TimeAgo("en-US");

  const fetchNotifications = useSelector(
    (state) => state.NotificationListReducer.result
  );
const userDetails = useSelector((state)=> state.ProfileUpdateReducer.user)
  const user = localStorage.getItem("user");
  const [userData, setUserData] = useState({});

  useEffect(() => {
    console.log("userDetails----------------",userDetails)
    setUserData(JSON.parse(user));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);
  const { name } = userData;
  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr?.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i]?.charAt(0).toUpperCase() + splitStr[i]?.substring(1);
    }
    // Directly return the joined string
    return splitStr?.join(" ");
  }

  const dispatch = useDispatch();

  const logout = async () => {
    localStorage.clear();
    dispatch({
      type: "STORE_IS_LOGOUT",
      payload: false,
    });

    dispatch({
      type: "STORE_AUTH_RESET",
      payload: "RESET",
    });

    history.push("/auth/login");
  };

  const updateNtfStatus = (id, type, ntf) => {
    console.log("my notifctn  ", ntf);
    const data = {
      status: "read",
      _id: id,
    };

    axiosAccountInstance
      .post("/notification/update", data)
      .then((res) => {
        // toast.success("Notification marked as seen");
        dispatch(setNotificationList(fetchUserData()?.userId));
        ntfNavigate(type, ntf);
      })
      //UPDATE_SOW_APPROVED_REQUEST_LIST

      .catch((err) => {
        toast.error(err?.response?.data?.body?.message);
      });
  };

  const ntfNavigate = (type, ntf) => {
    console.log("navNoti ", ntf);
    // BOOK EMPLOYEE
    if (type === "RESUME REQUEST") {
      // history.push("/admin/edit-employees/" + ntf?.employeeId);
      history.push({
        pathname: `/admin/edit-employees/${ntf?.employeeId}`,
        state: {
          type: "single_upload",
        },
      });
    }
       // RESUME UPLOADED
    if (
      window.location.pathname !== "/admin/booking-employee" &&
      type === "RESUME UPLOADED"
    ) {
      history.push({
        pathname: "/admin/booking-employee",
        state: { empId: ntf?.empId },
      });
    } else {
      dispatch({
        type: "NOTIFICATION_UPDATE",
        payload: {
          empId: ntf?.empId,
          currrentPath: window.location.pathname,
        },
      });
    }
    //INBOUND
    if (window.location.pathname !== "/admin/booking-requests") {
      if (type === "UPDATE_FINAL_SOW_APPROVED_REQUEST_LIST") {
        dispatch({
          type: "ALL_BOOKING_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-requests",
          state: { empId: ntf?.empId },
        });
      }
      if (type === "UPDATE_PENDING_BOOKING_APPROVAL_REQUESTS") {
        dispatch({
          type: "ALL_BOOKING_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-requests",
          state: { empId: ntf?.empId },
        });
      } if (type === "UPDATE_PENDING_INTERVIEW_APPROVAL_REQUESTS") {
        dispatch({
          type: "ALL_BOOKING_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-requests",
          state: { empId: ntf?.empId },
        });
      }
      if (type === "CREATE_BOOKING_CLOSURE_REQUEST") {
        dispatch({
          type: "ALL_BOOKING_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-requests",
          state: { empId: ntf?.empId },
        });
      }
      if (
        type === "UPDATE_SOW_REJECTED_REQUEST_LIST" ||
        type === "UPDATE_FINAL_SOW_REJECTED_REQUEST_LIST"
      ) {
        dispatch({
          type: "ALL_BOOKING_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-requests",
          state: { empId: ntf?.empId },
        });
      }
      if (type === "UPDATE_PENDING_BOOKING_EXTENTION_APPROVAL_LIST") {
        dispatch({
          type: "ALL_BOOKING_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-requests",
          state: { empId: ntf?.empId },
        });
      }
      if (type === "CREATE_CONFIRM_SLOT_REQUEST") {
          dispatch({
            type: "ALL_BOOKING_LIST_RESET",
          });
          history.push({
            pathname: "/admin/booking-details",
            state: { empId: ntf?.empId },
          });
        }
        if (type === "ACCEPT_SLOT_CHANGE_REQUEST") {
          dispatch({
            type: "ALL_BOOKING_LIST_RESET",
          });
          history.push({
            pathname: "/admin/booking-requests",
            state: { empId: ntf?.empId },
          });
        }
        if (type === "REJECT_SLOT_CHANGE_REQUEST") {
          dispatch({
            type: "ALL_BOOKING_LIST_RESET",
          });
          history.push({
            pathname: "/admin/booking-requests",
            state: { empId: ntf?.empId },
          });
        }
        if (type === "ACCEPT_CANDIDATE") {
          dispatch({
            type: "ALL_BOOKING_LIST_RESET",
          });
          history.push({
            pathname: "/admin/booking-requests",
            state: { empId: ntf?.empId },
          });
        }
        if (type === "REJECT_CANDIDATE") {
          dispatch({
            type: "ALL_BOOKING_LIST_RESET",
          });
          history.push({
            pathname: "/admin/booking-requests",
            state: { empId: ntf?.empId },
          });
        }
        if ((ntf?.parentCompany === fetchUserData()?.companyName) &&(type === "CANCEL_INTERVIEW_REQUEST")) {  //cancelled by booked company
          dispatch({
            type: "ALL_BOOKING_LIST_RESET",
          });
          history.push({
            pathname: "/admin/booking-requests",
            state: { empId: ntf?.empId },
          });
        }
        
    } else {
      dispatch({
        type: "NOTIFICATION_UPDATE",
        payload: {
          empId: ntf?.empId,
          currrentPath: window.location.pathname,
        },
      });
    }

    //OUTBOUND ALL_REQUEST_LIST_RESET   ALL_BOOKING_EXTENTION_LIST_RESET

    if (window.location.pathname !== "/admin/booking-details") {
      if (type === "UPDATE_SOW_APPROVED_REQUEST_LIST") {
        dispatch({
          type: "ALL_REQUEST_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-details",
          state: { tile: "1", empId: ntf?.empId },
        });
      }
      if (type === "UPDATE_APPROVED_BOOKING_LIST") {
        dispatch({
          type: "ALL_REQUEST_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-details",
          state: { tile: "1", empId: ntf?.empId },
        });
      }
      if (type === "UPDATE_REJECTED_BOOKING_LIST") {
        dispatch({
          type: "ALL_REQUEST_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-details",
          state: { tile: "1", empId: ntf?.empId },
        });
      }
      if (type === "UPDATE_CLOSURE_APPROVED_REQUEST_LIST") {
        dispatch({
          type: "PRE_CLOSURE_REQUEST_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-details",
          state: { tile: "3", empId: ntf?.empId },
        });
      }
      if (type === "UPDATE_CLOSURE_REJECTED_REQUEST_LIST") {
        dispatch({
          type: "PRE_CLOSURE_REQUEST_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-details",
          state: { tile: "3", empId: ntf?.empId },
        });
      }

      //
      if (type === "UPDATE_APPROVED_BOOKING_EXTENTION_LIST") {
        dispatch({
          type: "ALL_BOOKING_EXTENTION_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-details",
          state: { tile: "2", empId: ntf?.empId },
        });
      }
      if (type === "UPDATE_REJECTED_BOOKING_EXTENTION_LIST") {
        dispatch({
          type: "ALL_BOOKING_EXTENTION_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-details",
          state: { tile: "2", empId: ntf?.empId },
        });
      }
      if (type === "CREATE_CONFIRM_SLOT_REQUEST") {
        dispatch({
          type: "ALL_REQUEST_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-details",
          state: { tile: "1", empId: ntf?.empId },
        });
      }
      if (type === "CREATE_SLOT_CHANGE_REQUEST") {
        dispatch({
          type: "ALL_REQUEST_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-details",
          state: { tile: "1", empId: ntf?.empId },
        });
      }
      if (type === "UPDATE_REJECT_INTERVIEW") {
        dispatch({
          type: "ALL_REQUEST_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-details",
          state: { tile: "1", empId: ntf?.empId },
        });
      }
     

      if ( (ntf?.parentCompany != fetchUserData()?.companyName) && (type === "CANCEL_INTERVIEW_REQUEST")) {     //cancelled by the parentCompany itself
        
        dispatch({
          type: "ALL_REQUEST_LIST_RESET",
        });
        history.push({
          pathname: "/admin/booking-details",
          state: { tile: "1", empId: ntf?.empId },
        });
      }
      
    } else if (
      (type === "UPDATE_CLOSURE_REJECTED_REQUEST_LIST" ||
        type === "UPDATE_CLOSURE_APPROVED_REQUEST_LIST") &&
      window.location.pathname === "/admin/booking-details"
    ) {
      dispatch({
        type: "NOTIFICATION_UPDATE",
        payload: {
          empId: ntf?.empId,
          currrentPath: window.location.pathname,
          tile: "3",
        },
      });
    } else if (
      (type === "UPDATE_REJECTED_BOOKING_EXTENTION_LIST" ||
        type === "UPDATE_APPROVED_BOOKING_EXTENTION_LIST") &&
      window.location.pathname === "/admin/booking-details"
    ) {
      dispatch({
        type: "NOTIFICATION_UPDATE",
        payload: {
          empId: ntf?.empId,
          currrentPath: window.location.pathname,
          tile: "2",
        },
      });
    } else {
      dispatch({
        type: "NOTIFICATION_UPDATE",
        payload: {
          empId: ntf?.empId,
          currrentPath: window.location.pathname,
        },
      });
    }
  };

  const getUrl = () => {
    console.log("clickkk", userDataDetails);
    // history.push('/your-route-url', { stateValue: userDataDetails });
    history.push({
      pathname: `/admin/user-profile`,
      state: { ...userDataDetails },
    });
    // history.push(``,{stateValue:userDataDetails})
  };
  console.log("prorps", props);
  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <div className="h4 mb-0 text-uppercase d-none d-lg-inline-block text-white">
            {props.location.pathname !== "/admin/user-profile" &&
            props?.companyName &&
            props?.brandText !== "Booking History"
              ? props?.brandText + " - " + props?.companyName
              : props?.brandText}
          </div>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle nav className="nav-link-icon notification">
                <i className="ni ni-bell-55" />
                {fetchNotifications?.unreadCount > 0 && (
                  <span className="badge">
                    {fetchNotifications?.unreadCount}
                  </span>
                )}
              </DropdownToggle>
              <DropdownMenu
                aria-labelledby="navbar-default_dropdown_1"
                className="dropdown-menu-arrow notification-custom"
                right
              >
                <DropdownItem className="noti-title" header tag="div">
                  <h6 style={{ fontSize: "18px" }} className="m-0">
                    Notifications{" "}
                  </h6>
                </DropdownItem>
                <DropdownItem divider />
                <div className="noti-values">
                  {fetchNotifications?.notifications?.length > 0 ? (
                    <>
                      {fetchNotifications?.notifications?.map((ntf, key) => (
                        <DropdownItem
                          onClick={
                            ntf?.status === "unread"
                              ? () => updateNtfStatus(ntf?._id, ntf?.type, ntf)
                              : () => ntfNavigate(ntf?.type, ntf)
                          }
                          className={`${
                            ntf?.status === "unread" && "ntf-color"
                          }  my-1`}
                          key={key}
                        >
                          <span className="d-flex align-items-center justify-content-between">
                            {/* <span
                              className="noti-from"
                              style={{ fontWeight: "bold" }}
                            >
                              {ntf?.parentCompany}
                            </span> */}

                            <span className="noti-text">
                              {ntf?.message}
                              {/* {" "}
                            {ntf?.message.startsWith(ntf?.parentCompany)
                              ? ntf?.message?.replace(ntf?.parentCompany, "")
                              : ntf?.message} */}
                            </span>
                          </span>
                          <span className="noti-date pl-4">
                            {timeAgo.format(new Date(ntf?.createdAt))}
                          </span>
                          {/* ntf?.message.replace(ntf?.parentCompany[0],ntf?.parentCompany[0].toUpperCase()) }</span> */}
                        </DropdownItem>
                      ))}
                    </>
                  ) : (
                    <DropdownItem>You're all up-to-date!!</DropdownItem>
                  )}
                </div>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    {name?.charAt(0).toUpperCase()}
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {titleCase(name)}
                      {/* <img
                        width={"12"}
                        className="ml-2"
                        alt="..."
                        src={
                          require("../../assets/img/icons/customIcons/arrow-dark-down.svg")
                            .default
                        }
                      /> */}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu
                className="dropdown-menu-arrow pb-0 pt-2 profile-dropdown"
                right
              >
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="m-0">Welcome {titleCase(name)} </h6>
                </DropdownItem>
                <DropdownItem divider />
                {/* {console.log("user",userDataDetails)} */}
                <DropdownItem
                  onClick={getUrl}

                  // tag={Link}
                >
                  <img
                    width={"15"}
                    className="mr-2"
                    alt="..."
                    src={
                      require("../../assets/img/icons/customIcons/user.svg")
                        .default
                    }
                  />
                  <span>My Profile</span>
                </DropdownItem>

                <DropdownItem
                  className="dropdown-footer"
                  href=""
                  onClick={(e) => logout()}
                >
                  <Row className="justify-content-between">
                    <Col>
                      <img
                        width={"18"}
                        alt="..."
                        src={
                          require("../../assets/img/icons/customIcons/logout.svg")
                            .default
                        }
                      />
                      <span className="text-white">Logout</span>
                    </Col>
                  </Row>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
