import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import loginimg from "../../assets/img/new/login.jpg";
import logobox from "../../assets/img/icons/customIcons/logo.svg";

// Third Party Imports
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  FormFeedback,
} from "reactstrap";
import { setAuthInfo } from "redux/actions/auth/authAction";
import toast from "../../components/Toastify";
import "react-toastify/dist/ReactToastify.css";

import history from "helpers/History";
import { authRequest } from "api/Request";

import { site_name } from "helpers/utils";
import { MARKETING_WEBSITE } from "helpers/utils";
// Component Imports

const Login = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(null);
  const [allowVerificationResend, setAllowVerificationResend] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  // const [passwordvalidate, setpasswordvalidateValidate] = useState();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  // fetching reducers
  const fetchLoaderAuth = useSelector((state) => state.authReducer.loader);
  const fetchResultAuth = useSelector((state) => state.authReducer.result);

  useEffect(() => {
    if (location?.state?.detail === "some_value") {
      toast.warn("Company already existed.Please login with the credentials");
    }
  }, [location]);

  useEffect(() => {
    if (!fetchLoaderAuth && fetchResultAuth === "SUCCESS") {
      history.push("/admin/search");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchResultAuth]);

  const validateEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!mail || regex.test(mail) === false) {
      setErrors({ ...errors, email: "Please enter a valid email address" });
      return false;
    } else {
      setErrors({ ...errors, email: "" });
      return true;
    }
  };

  const validation = () => {
    const errorsFound = {};
    // if (!employeeId) errorsFound["employeeId"] = "Oops! please add employeeId";
    if (validateEmail(email) === false) {
      errorsFound["email"] = "Please enter a valid email addres";
    }
    if (!email || email === "")
      errorsFound["email"] = "Email address is required";
    if (!password || password === "")
      errorsFound["password"] = "Password is required";

    if (Object.keys(errorsFound).length !== 0) {
      setErrors(errorsFound);

      return false;
    }
    return true;
  };

  const submitHandle = async (e) => {
    e.preventDefault();
    const isValid = validation();
    // const validEmail = validateEmail(email)
    if (isValid) {
      const data = {
        email: email,
        password: password,
      };
      e.preventDefault();
      const res = await dispatch(setAuthInfo(data));
      if (res?.data?.statusCode === 422) {
        setAllowVerificationResend(true);
      }
    }
  };

  const handleResendVerificationMail = async (e, email) => {
    e.preventDefault();
    setIsEmailSending(true);
    authRequest({
      method: "POST",
      url: "/resend-verification-code",
      data: { email },
    })
      .then((res) => {
        toast.success(res.body.message);
        setAllowVerificationResend(false);
        setIsEmailSending(false);
      })
      .catch((err) => {
        toast.error(err.data.body.message);
      });
  };
  const navigateToExternalLink = () => {
    window.location.href = MARKETING_WEBSITE;
  };

  return (
    <>
      {/* <ToastContainer autoClose={5000} /> */}
      <Col md="5" className="bg-leftside">
        <div className="login-left">
 
          <img alt="" src={loginimg} />
        </div>
      </Col>
      <Col md="7" className="bg-rightside">
        <div className="account-switch d-md-block d-none">
          <Link to="/auth/register">
            {/* <small>New company Registration</small> */}
            <Button className="switchbtns ml-3" color="outline-primary">
              Sign Up
            </Button>
          </Link>
        </div>

        <Card className="border-0 formboxes">
          <CardHeader className="bg-transparent border-0">
            <div className="text-muted  mt-2">
              <p className="title-signup mb-0">Welcome to {site_name}</p>
              <span className="titile-subtag">Login your account</span>
            </div>
          </CardHeader>
          <CardBody>
            <Form role="form">
              <FormGroup>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setErrors({ ...errors, email: null });
                      validateEmail(e.target.value);
                    }}
                    invalid={!!errors && !!errors.email}
                  />
                </InputGroup>
                <FormFeedback className={errors?.email && "invalid-field"}>
                  {errors?.email}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative shadow-none border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type={isPasswordVisible ? "text" : "password"}
                    autoComplete="new-password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setErrors({ ...errors, password: null });
                    }}
                    invalid={!!errors && !!errors.password}
                  />
                  {password.length > 0 && (
                    <InputGroupAddon>
                      <InputGroupText
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      >
                        <i
                          className="fa fa-eye"
                          style={{ paddingTop: "4px", cursor: "pointer" }}
                          aria-hidden="true"
                        ></i>
                      </InputGroupText>
                    </InputGroupAddon>
                  )}
                </InputGroup>
                <FormFeedback className={errors?.password && "invalid-field"}>
                  {errors?.password}
                </FormFeedback>
              </FormGroup>
              <div className="d-flex align-items-center justify-content-between">
                <Button
                  onClick={submitHandle}
                  className="my-2 border-radius-100"
                  color="primary"
                  type="submit"
                  disabled={fetchLoaderAuth}
                >
                  {fetchLoaderAuth ? "Please wait" : "Sign In"}
                </Button>
                <div className="text-center">
                  <Link to="/auth/forgot-password">
                    <small className="">Forgot password ?</small>
                  </Link>
                </div>
              </div>
            </Form>
            <p className="d-md-none d-block already-account">
              Don't have an account?{" "}
              <Link className="" to="/auth/register">
                Signup
              </Link>
            </p>
            {/* <Link className="d-md-none d-block" to="/auth/register">
              Dont you have an account?
            </Link> */}

            {allowVerificationResend && (
              <div className="text-center" style={{ "font-size": "0.7rem" }}>
                <Link
                  to=""
                  onClick={(e) => handleResendVerificationMail(e, email)}
                >
                  {isEmailSending
                    ? "Please wait..."
                    : "Resend verification email"}
                </Link>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
