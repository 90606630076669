/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import history from "helpers/History";
import { useDispatch, useSelector } from "react-redux";
import { axiosAccountInstance } from "api/Request";
import TimeAgo from "javascript-time-ago";
// English.
import en from "javascript-time-ago/locale/en";

import toast from "../Toastify";
import { fetchUserData } from "helpers/storageData";
import { setNotificationList } from "redux/actions/admin/NotificationListRequestAction";

TimeAgo.addLocale(en);

var ps;

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const timeAgo = new TimeAgo("en-US");
  const [collapseOpen, setCollapseOpen] = useState();
  const userDataDetails = fetchUserData();

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const logout = () => {
    localStorage.clear();
    history.push("/auth/login");
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <div key={key}>
          {prop.isValid ? (
            <NavItem>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
            </NavItem>
          ) : (
            ""
          )}
        </div>
      );
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  const user = localStorage.getItem("user");
  const [userData, setUserData] = useState({});

  useEffect(() => {
    setUserData(JSON.parse(user));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { name } = userData;

  const fetchNotifications = useSelector(
    (state) => state.NotificationListReducer.result
  );
  const getUrl = () => {
    console.log("clickkk", userDataDetails);
    // history.push('/your-route-url', { stateValue: userDataDetails });
    history.push({
      pathname: `/admin/user-profile`,
      state: { ...userDataDetails },
    });
    // history.push(``,{stateValue:userDataDetails})
  };

  function titleCase(str) {
    var splitStr = str?.toLowerCase().split(" ");
    for (var i = 0; i < splitStr?.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] =
        splitStr[i]?.charAt(0).toUpperCase() + splitStr[i]?.substring(1);
    }
    // Directly return the joined string
    return splitStr?.join(" ");
  }
  const updateNtfStatus = (id) => {
    const data = {
      status: "read",
      _id: id,
    };

    axiosAccountInstance
      .post("/notification/update", data)
      .then((res) => {
        dispatch(setNotificationList(fetchUserData()?.userId));
        toast.success("Notification marked as seen");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.body?.message);
      });
  };
  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0 text-left" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav className="nav-link-icon notification">
              <i className="ni ni-bell-55" />
              {fetchNotifications?.unreadCount > 0 && (
                <span className="badge">{fetchNotifications?.unreadCount}</span>
              )}
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              <DropdownItem className="noti-title" header tag="div">
                <h6 style={{ fontSize: "18px" }} className="m-0">
                  Notifications{" "}
                </h6>
              </DropdownItem>
              <DropdownItem divider />
              {fetchNotifications?.notifications?.length > 0 ? (
                <>
                  {fetchNotifications?.notifications?.map((ntf, key) => (
                    <DropdownItem
                      onClick={() => updateNtfStatus(ntf?._id)}
                      className={`${
                        ntf?.status === "unread" && "ntf-color rounded"
                      }  my-2`}
                      key={key}
                    >
                      <span style={{ fontWeight: "bold" }}>
                        {ntf?.parentCompany}
                      </span>
                      <span>
                        {" "}
                        {ntf?.message?.replace(ntf?.parentCompany, "")}
                      </span>
                      <span style={{ fontSize: "10px" }} className="fs-6 pl-2">
                        {timeAgo.format(new Date(ntf?.createdAt))}
                      </span>
                    </DropdownItem>
                  ))}
                </>
              ) : (
                <DropdownItem>You're all up-to-date!!</DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  {/* <img
                    alt="..."
                    src={
                      require("../../assets/img/theme/team-1-800x800.jpg")
                        .default
                    }
                  /> */}
                  {name?.charAt(0).toUpperCase()}
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold">
                    {titleCase(name)}
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow profile-dropdown" right style={{paddingBottom:'0px'}}>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="m-0">Welcome {titleCase(name)} </h6>
              </DropdownItem>
              <DropdownItem divider />
              {/* {console.log("user",userDataDetails)} */}
              <DropdownItem
                onClick={getUrl}

                // tag={Link}
              >
                <img
                  width={"15"}
                  className="mr-2"
                  alt="..."
                  src={
                    require("../../assets/img/icons/customIcons/user.svg")
                      .default
                  }
                />
                <span>My Profile</span>
              </DropdownItem>
              <DropdownItem
                className="dropdown-footer"
                href=""
                onClick={(e) => logout()}
              >
                <Row className="justify-content-between">
                  <Col>
                    <img
                      width={"18"}
                      alt="..."
                      src={
                        require("../../assets/img/icons/customIcons/logout.svg")
                          .default
                      }
                    />
                    <span className="text-white">Logout</span>
                  </Col>
                </Row>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}

          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
          {/* Divider */}
        </Collapse>
        <div className="copyright text-left text-xl-left text-muted d-md-block d-none">
          © {new Date().getFullYear()}{" "}
          <a
            className="font-weight-bold ml-1"
            href="#"
            rel="noopener noreferrer"
            target="_blank"
          >
            ScaleUpHQ
            {/* {companyName} */}
          </a>
        </div>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
